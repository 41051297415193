import { Alert, AlertTitle, Box, Breadcrumbs, Button, Card, CardContent, Container, IconButton, Link, Stack, Skeleton, Typography, styled, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Iconify from "../components/iconify/Iconify";
import { TITLE } from "../constant";
import { useState } from "react";
import useSWR from "swr";
import { fetcher, instance } from "../api";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

// ---

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

// ---

function AddInstrumentDialog({ open, handleClose }: { open: boolean, handleClose: () => void }) {
	const [loading, setLoading] = useState(false);

	const addInstrument = () => {
		setLoading(true);
		const name = (document.getElementById('name') as HTMLInputElement).value;
		const slug = (document.getElementById('slug') as HTMLInputElement).value;
		const url = (document.getElementById('url') as HTMLInputElement).value;

		instance.post('/instruments', {
			name: name,
			slug: slug,
			scores_link: url,
		}).then(() => {
			enqueueSnackbar('L\'instrument a bien été ajouté.', { variant: 'success' });
			handleClose();
			setLoading(false);
		}).catch(() => {
			enqueueSnackbar('Une erreur est survenue lors de l\'ajout de l\'instrument.', { variant: 'error' });
			setLoading(false);
		});
	}

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
			<DialogTitle>Ajouter un instrument</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Stack direction="column" gap={2} mt={1}>
						<TextField
							label="Nom"
							id="name"
							variant="outlined"
							fullWidth
						/>
						<TextField
							label="Slug (nom court)"
							id="slug"
							variant="outlined"
							fullWidth
						/>
						<TextField
							label="Partitions (URL)"
							id="url"
							variant="outlined"
							fullWidth
						/>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleClose}>Annuler</Button>
				<LoadingButton loading={loading} variant="contained" onClick={addInstrument}>Ajouter</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}

function DeleteInstrumentDialog({ open, handleClose, instrument }: { open: boolean, handleClose: () => void, instrument: any }) {
	const [loading, setLoading] = useState(false);

	const deleteInstrument = () => {
		setLoading(true);
		instance.delete(`/instruments/${instrument.id}`).then(() => {
			enqueueSnackbar('L\'instrument a bien été supprimé.', { variant: 'success' });
			handleClose();
			setLoading(false);
		}).catch(() => {
			enqueueSnackbar('Une erreur est survenue lors de la suppression de l\'instrument.', { variant: 'error' });	
			setLoading(false);
		});
	}

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
			<DialogTitle>Supprimer un instrument</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Êtes-vous sûr de vouloir supprimer l'instrument <strong>{instrument.name}</strong> ?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleClose}>Annuler</Button>
				<LoadingButton loading={loading} variant="contained" onClick={deleteInstrument} color="error">Supprimer</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}

function EditInstrumentDialog({ open, handleClose, instrument }: { open: boolean, handleClose: () => void, instrument: any }) {
	const [loading, setLoading] = useState(false);

	const editInstrument = () => {
		setLoading(true);
		const name = (document.getElementById('name') as HTMLInputElement).value;
		const slug = (document.getElementById('slug') as HTMLInputElement).value;
		const url = (document.getElementById('url') as HTMLInputElement).value;

		instance.put(`/instruments/${instrument.id}`, {
			name: name,
			slug: slug,
			scores_link: url,
		}).then(() => {
			enqueueSnackbar('L\'instrument a bien été modifié.', { variant: 'success' });
			handleClose();
			setLoading(false);
		}).catch(() => {
			enqueueSnackbar('Une erreur est survenue lors de la modification de l\'instrument.', { variant: 'error' });
			setLoading(false);
		});
	}

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
			<DialogTitle>Modifier un instrument</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Stack direction="column" gap={2} mt={1}>
						<TextField
							label="Nom"
							id="name"
							variant="outlined"
							fullWidth
							defaultValue={instrument.name}
						/>
						<TextField
							label="Slug (nom court)"
							id="slug"
							variant="outlined"
							fullWidth
							defaultValue={instrument.slug}
						/>
						<TextField
							label="Partitions (URL)"
							id="url"
							variant="outlined"
							fullWidth
							defaultValue={instrument.scores_link}
						/>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleClose}>Annuler</Button>
				<LoadingButton loading={loading} variant="contained" onClick={editInstrument}>Modifier</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}

// ---

function Instruments() {
	const { data, error, isLoading } = useSWR('/instruments', fetcher)
	const loadingSkeleton = Array(5).fill(0);

	const [addInstrumentDialogOpen, setAddInstrumentDialogOpen] = useState(false);

	const [deleteInstrumentDialogOpen, setDeleteInstrumentDialogOpen] = useState(false);
	const [instrumentToDelete, setInstrumentToDelete] = useState({});

	const [editInstrumentDialogOpen, setEditInstrumentDialogOpen] = useState(false);
	const [instrumentToEdit, setInstrumentToEdit] = useState({});

	return (
		<>
			<Helmet>
				<title>Instruments { TITLE }</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Box>
							<Typography variant="h4" gutterBottom>
								Instruments
							</Typography>
							<Breadcrumbs separator="›" aria-label="breadcrumb">
								<Link underline="hover" color="inherit" href="/">
									Accueil
								</Link>
								<Typography color="text.primary">Instruments</Typography>
							</Breadcrumbs>
						</Box>
						<Box>
							<Button
								variant="contained"
								onClick={() => setAddInstrumentDialogOpen(true)}
							>
								<Iconify icon="mingcute:add-line" mr={1} /> Ajouter un instrument
							</Button>
						</Box>
					</Stack>
				</HeaderTitle>

				{error && (
					<Alert severity="error" sx={{ my: 3 }}>
						<AlertTitle>Erreur d'actualisation</AlertTitle>
						Les données les plus récentes n'ont pas pu être récupérées. Les données affichées peuvent être obsolètes.
					</Alert>
				)}

				{isLoading && (
					<Stack gap={'26px'}>
					{loadingSkeleton.map((_, index) => {
						return (
							<Card key={index}>
								<CardContent
									sx={{
										width: '100%',
										flexGrow: 1,
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
									}}
								>	
									<Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
										<Skeleton variant="rounded" width={260} height={30} />
										<Skeleton variant="rounded" width={320} height={20} />
										<Stack direction="row" gap={1}>
											<Skeleton variant="circular" width={30} height={30} />
											<Skeleton variant="circular" width={30} height={30} />
										</Stack>
									</Stack>
								</CardContent>
							</Card>
						);
					})}					
					</Stack>
				)}

				{!isLoading && data?.length === 0 && (
					<Alert severity="info" sx={{ my: 3 }}>
						<AlertTitle>Aucun instrument</AlertTitle>
						Aucun instrument n'a été créé pour le moment.
					</Alert>
				)}

				{!isLoading && data?.length > 0 && (
					<Grid container spacing={2}>
					{data.sort((a: any, b: any) => {
						if (a.name < b.name) return -1;
						if (a.name > b.name) return 1;
						return 0;
					}).map((instru: any, index: number) => {
						return (
							<Grid
								item
								xs={12}
								sm={6}
							>
								<Card key={index}>
									<CardContent
										sx={{
											width: '100%',
											flexGrow: 1,
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
										}}
									>	
										<Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
											<Stack>
												<Typography variant="h6" component="h2">
													{instru.name}
												</Typography>
												<Typography variant="body2" component="p">
													{instru.slug}
												</Typography>
											</Stack>
											<Stack direction="row" gap={0}>
												<IconButton
													onClick={() => {
														setInstrumentToEdit(instru);
														setEditInstrumentDialogOpen(true);
													}}
												>
													<Iconify icon="solar:pen-bold" />
												</IconButton>
												<IconButton
													color="error"
													onClick={() => {
														setInstrumentToDelete(instru);
														setDeleteInstrumentDialogOpen(true);
													}}
												>
													<Iconify icon="solar:trash-bin-2-bold" />
												</IconButton>
											</Stack>
										</Stack>
									</CardContent>
								</Card>
							</Grid>
						);
					})}
					</Grid>
				)}

				<AddInstrumentDialog 
					open={addInstrumentDialogOpen}
					handleClose={() => setAddInstrumentDialogOpen(false)}
				/>

				<DeleteInstrumentDialog
					open={deleteInstrumentDialogOpen}
					handleClose={() => setDeleteInstrumentDialogOpen(false)}
					instrument={instrumentToDelete}
				/>

				<EditInstrumentDialog
					open={editInstrumentDialogOpen}
					handleClose={() => setEditInstrumentDialogOpen(false)}
					instrument={instrumentToEdit}
				/>

			</Container>
		</>
	)
}

export default Instruments;