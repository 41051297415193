import useSWR from 'swr';
import { fetcher } from '../../api';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Card, CircularProgress, Container, IconButton, Link, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from '@mui/material';
import Iconify from '../../components/iconify';
import { RESPONSE_VALUES, TITLE } from '../../constant';
import SimpleBar from 'simplebar-react';

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

function TableFullView() {
	const { data, error, isLoading } = useSWR('/attendance/full', fetcher);

	return (
		<>
			<Helmet>
				<title>Présences { TITLE }</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Box>
							<Typography variant="h4">
								Présences
							</Typography>
							<Breadcrumbs separator="›" aria-label="breadcrumb">
								<Link underline="hover" color="inherit" href="/">
									Accueil
								</Link>
								<Link underline="hover" color="inherit" href="/attendance">
									Présences
								</Link>
								<Typography color="text.primary">Vue Tabulaire</Typography>
							</Breadcrumbs>
						</Box>
					</Stack>
				</HeaderTitle>

				{error && (
					<Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: '100%', marginTop: '10%' }} gap={2}>
						<img src="/assets/characters/no_data.svg" alt="Aucune présence" width="250px" />
						<Typography variant="h6" component="div" gutterBottom>
							Une erreur est survenue lors de la récupération des données.
							La page ne peut pas être affichée.
						</Typography>
					</Stack>
				)}

				{isLoading && (
					<Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: '100%', marginTop: '10%' }} gap={2}>
						<CircularProgress />
					</Stack>
				)}

				{!isLoading && !error && data && (
					<Card>
						<SimpleBar>
							<Table stickyHeader sx={{ overflow: 'scroll', width: '100%', borderRadius: 2 }}>
								<TableHead>
									<TableRow>
										<TableCell>
										</TableCell>
										{data.attendances.map((row: any) => (
											<TableCell key={row.uuid}>
												{row.name}<br/>
												{new Date(row.date).toLocaleDateString()}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{data.members.sort((a: any, b: any) => {
										return a.name.join(' ').localeCompare(b.name.join(' '));
									}).map((member: any) => (
										<TableRow key={member.uuid}>
											<TableCell>
												{member.name.join(' ')}
											</TableCell>
											{data.attendances.map((attendance: any) => (
												<TableCell key={attendance.uuid} sx={{
													color: RESPONSE_VALUES[data.responses[member.uuid][attendance.uuid]][1],
													textAlign: 'center'
												}}>
													<Iconify icon={RESPONSE_VALUES[data.responses[member.uuid][attendance.uuid]][2]} />
												</TableCell>
											))}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</SimpleBar>
					</Card>
				)}
			</Container>
		</>
	)
}

export default TableFullView;