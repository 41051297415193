import { instance } from "."

// ---

type SelfCreationFormObject = {
	token: string,
	email: string,
	firstName: string,
	lastName: string,
	phone: string,
	address: string,
	addressZipCode: string,
	addressCity: string,
	emergencyContact: string,
	birthDate: string,
	otherEmails: string,
	instrumentId: string,
	instrumentReference: string,
	shirtSize: string,
	gender: number,
	memberSince: string,
}

async function createMemberBySelfCreationForm(data: SelfCreationFormObject) {
	const response = await instance.post(`/members/create-by-email/${data.token}`, {
		"email": data.email,
		"first_name": data.firstName,
		"last_name": data.lastName,
		"phone": data.phone,
		"address": data.address,
		"zip": data.addressZipCode,
		"city": data.addressCity,
		"emergency_contact": data.emergencyContact,
		"birth_date": data.birthDate,
		"other_emails": data.otherEmails,
		"instrument_id": data.instrumentId,
		"instrument_reference": data.instrumentReference,
		"shirt_size": data.shirtSize,
		"gender": data.gender,
	}).then(response => response.data)
	
	return response;
}

// ---

async function createMemberByAdmin(data: SelfCreationFormObject) {
	const response = await instance.post(`/members`, {
		"email": data.email,
		"gender": data.gender,
		"first_name": data.firstName,
		"last_name": data.lastName,
		"phone": data.phone,
		"address": data.address,
		"zip": data.addressZipCode,
		"city": data.addressCity,
		"emergency_contact": data.emergencyContact,
		"birth_date": data.birthDate,
		"other_emails": data.otherEmails,
		"instrument_id": data.instrumentId,
		"instrument_reference": data.instrumentReference,
		"shirt_size": data.shirtSize,
		"member_since": data.memberSince,
	}).then(response => response.data)

	return response;
}

// ---

async function getMembersList() {
	const response = await instance.get(`/members`).then(response => response.data)
	
	return response;
}

// ---

async function sendMemberCreationInvite(email: string) {
	const response = await instance.post(`/members/create-by-email`, {
		"email": email,
	}).then(response => response.data)
	
	return response;
}

// ---

export { createMemberBySelfCreationForm, getMembersList, sendMemberCreationInvite, createMemberByAdmin }