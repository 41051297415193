// component
import SvgColor from '../../../components/svg-color';

// ---

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfigPublic = [
    {
        title: 'Accueil',
        path: '/',
        icon: icon('ic_dashboard'),
    },
    {
        title: 'Présences',
        path: '/attendance',
        icon: icon('ic_calendar'),
    },
    {
        title: 'Médias',
        path: '/medias',
        icon: icon('ic_library'),
    },
    {
        title: 'Contacts',
        path: '/contact',
        icon: icon('ic_chat'),
    },
];

const navConfigBureau = [
    {
        title: 'Membres',
        path: '/members',
        icon: icon('ic_user'),
    },
    {
        title: 'Gestion des présences',
        path: '/attendance-management',
        icon: icon('ic_calendar'),
    },
    {
        title: 'Gestion des médias',
        path: '/medias-management',
        icon: icon('ic_library'),
    },
];

const navConfigAdmin = [
    {
        title: 'Utilisateurs',
        path: '/users',
        icon: icon('ic_user'),
    },
    // {
    //     title: 'Roles',
    //     path: '/roles',
    //     icon: icon('ic_lock'),
    // },
    {
        title: 'Instruments',
        path: '/instruments',
        icon: icon('ic_job'),
    },
    {
        title: 'Raccourcis',
        path: '/shortcuts',
        icon: icon('ic_menu_item'),
    }
];

export { navConfigPublic, navConfigBureau, navConfigAdmin };
