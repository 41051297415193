import { Alert, AlertTitle, Box, Breadcrumbs, Button, Card, CardActions, CardContent, CardHeader, Container, Link, Stack, Typography, styled } from "@mui/material";
import { Timeline, TimelineDot, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import { Helmet } from "react-helmet-async";
import Iconify from "../../components/iconify/Iconify";
import { TITLE } from "../../constant";
import useSWR from "swr";
import { fetcher } from "../../api";

// ---

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

// ---

function MediasIndex() {
	const { data, isLoading, error } = useSWR('/medias', fetcher);
	const loadingSkeleton = Array(5).fill(0);

	const medias = !isLoading && data.data.sort((a: any, b: any) => {
		return new Date(b.date).getTime() - new Date(a.date).getTime();
	});
	
	if (error) {
		return (
			<Container maxWidth="xl">
				<Alert severity="error">
					<AlertTitle>Erreur</AlertTitle>
					Une erreur est survenue lors du chargement des médias.
				</Alert>
			</Container>
		)
	}

	return (
		<>
			<Helmet>
				<title>Médias { TITLE }</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Box sx={{ mb: 5 }}>
							<Typography variant="h4" gutterBottom>
								Médias
							</Typography>
							<Breadcrumbs separator="›" aria-label="breadcrumb">
								<Link underline="hover" color="inherit" href="/">
									Accueil
								</Link>
								<Typography color="text.primary">Médias</Typography>
							</Breadcrumbs>
						</Box>
					</Stack>
				</HeaderTitle>

				<Timeline 
					position="right"
					sx={{
						[`& .${timelineOppositeContentClasses.root}`]: {
							flex: 0.1,
						},
					}}
				>
					{isLoading && loadingSkeleton.map((_, index) => (
						<TimelineItem key={index}>
							<TimelineOppositeContent sx={{ py: '12px', px: 2 }}>
								<Typography variant="body2" color="text.secondary">
									Loading...
								</Typography>
							</TimelineOppositeContent>
							<TimelineSeparator>
								<TimelineDot />
								<TimelineConnector />
							</TimelineSeparator>
							<TimelineContent sx={{ py: '12px', px: 2 }}>
								<Typography variant="h6" component="span">
									Loading...
								</Typography>
								<Typography>Loading...</Typography>
							</TimelineContent>
						</TimelineItem>
					))}

					{!isLoading && medias && medias.map((media: any) => {
						// Get thumbnail from Google Photos link
						// const thumbnail;

						return (
							<TimelineItem key={media.id}>
								<TimelineOppositeContent>
									<Typography variant="body2" color="text.secondary">
										{new Date(media.date).toLocaleDateString('fr-FR', {
											weekday: 'long',
											year: 'numeric',
											month: 'long',
											day: 'numeric',
										})}
									</Typography>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot color={
										media.type === 'photo' ? 'primary' : media.type === 'video' ? 'secondary' : 'info'
									}>
										<Iconify icon={
											media.type === 'photo' ? 'mdi:camera' : media.type === 'video' ? 'mdi:video' : 'mdi:file'
										} />
									</TimelineDot>
									{media.id !== medias[medias.length - 1].id && <TimelineConnector />}
								</TimelineSeparator>
								<TimelineContent>
									<Card>
										<CardHeader title={media.name} />
										
										<CardContent>
											<Stack direction="row" sx={{ justifyContent: 'space-between' }}>
												<Box>
													<Typography variant="body1" component="div">
														{media.description}
													</Typography>
													
													<Button sx={{ mt: 4 }} variant="contained" href={media.link} target="_blank" color="primary" startIcon={<Iconify icon="solar:link-minimalistic-bold" width={20} />}>
														Accéder
													</Button>
												</Box>

												{/* {media.type === 'photo' && (
													<Box>
														<img
															src={thumbnail}
															alt={media.name}
															style={{
																maxWidth: '100%',
																height: 'auto',
																borderRadius: '4px',
																boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
															}}
														/>
													</Box>
												)} */}
											</Stack>
										</CardContent>
									</Card>
								</TimelineContent>
							</TimelineItem>)
					})}
				</Timeline>
			</Container>
		</>
	)
}

export default MediasIndex;