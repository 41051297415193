import axios from "axios";
import { BASE_URL } from "../constant";

const instance = axios.create({
		baseURL: BASE_URL,
		headers: {
			"Content-type": "application/x-www-form-urlencoded",
			"Accept": "application/json",
			"Authorization": `Bearer ${localStorage.getItem('token')}`
		},
		timeout: 10000
	})
	
instance.interceptors.response.use(
	(response) => {
		return response;
	}, (error) => {
		if (error.response.status === 401) {
			localStorage.removeItem('token');
			localStorage.removeItem('user');
			localStorage.removeItem('instrument');
			localStorage.removeItem('member-uuid');
			localStorage.removeItem('app-cache');
			window.location.href = "/auth/login";
		} else {
			return Promise.reject(error);
		}
	}
);

const fetcher = (url: string) => instance.get(url).then((res) => res.data);

export { instance, fetcher };