import { Box, Breadcrumbs, Card, Container, Grid, Link, Stack, Typography, styled } from "@mui/material";
import { Helmet } from "react-helmet-async";

import { TITLE } from "../constant";

// ---

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

// ---

function Contacts() {
  	return (
		<>
			<Helmet>
				<title>Contacts {TITLE}</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Box>
						<Typography variant="h4" gutterBottom>
							Contacts
						</Typography>
						<Breadcrumbs separator="›" aria-label="breadcrumb">
							<Link underline="hover" color="inherit" href="/">
								Accueil
							</Link>
							<Typography color="text.primary">
								Contacts
							</Typography>
						</Breadcrumbs>
					</Box>
				</HeaderTitle>
				
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Card>
							<Box p={2}>
								<Typography variant="h5" gutterBottom>
									Pierre BARUSSEAU
								</Typography>
								<Typography variant="body1">
									<strong>Chef d'Orchestre</strong><br />
									<Link href="tel:+33 6 63 04 65 30">
										06 63 04 65 30
									</Link>
									<br />
									<Link href="mailto:p.barusseau@orchestremontois.fr">
										p.barusseau@orchestremontois.fr
									</Link>
								</Typography>
							</Box>
						</Card>
					</Grid>
					
					<Grid item xs={12} md={6}>
						<Card>
							<Box p={2}>
								<Typography variant="h5" gutterBottom>
									Romain LABASTARDE
								</Typography>
								<Typography variant="body1">
									<strong>Co-président</strong><br />
									<Link href="tel:+33 6 70 15 65 58">
										06 70 15 65 58
									</Link>
									<br />
									<Link href="mailto:r.labastarde@orchestremontois.fr">
										r.labastarde@orchestremontois.fr
									</Link>
								</Typography>
							</Box>
						</Card>
					</Grid>

					<Grid item xs={12} md={6}>
						<Card>
							<Box p={2}>
								<Typography variant="h5" gutterBottom>
									Fabien DACHARRY
								</Typography>
								<Typography variant="body1">
									<strong>Co-président</strong><br />
									<Link href="tel:+33 7 81 44 67 63">
										07 81 44 67 63
									</Link>
									<br />
									<Link href="mailto:f.dacharry@orchestremontois.fr">
										f.dacharry@orchestremontois.fr
									</Link>
								</Typography>
							</Box>
						</Card>
					</Grid>

					<Grid item xs={12} md={6}>
						<Card>
							<Box p={2}>
								<Typography variant="h5" gutterBottom>
									Aurore BAZAS
								</Typography>
								<Typography variant="body1">
									<strong>Trésorière</strong><br />
									<Link href="tel:+33 6 26 76 12 97">
										06 26 76 12 97
									</Link>
									<br />
									<Link href="mailto:tresorerie@orchestremontois.fr">
										tresorerie@orchestremontois.fr
									</Link>
								</Typography>
							</Box>
						</Card>
					</Grid>

					<Grid item xs={12} md={6}>
						<Card>
							<Box p={2}>
								<Typography variant="h5" gutterBottom>
									Lucas ESPIET
								</Typography>
								<Typography variant="body1">
									<strong>Administrateur</strong><br />
									<Link href="mailto:webmaster@orchestremontois.fr">
										webmaster@orchestremontois.fr
									</Link>
								</Typography>
							</Box>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

export default Contacts;