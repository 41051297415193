import { Alert, AlertTitle, Box, Breadcrumbs, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, Link, MenuItem, Select, Skeleton, Stack, TextField, Typography, styled } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Iconify from "../../components/iconify/Iconify";
import { TITLE } from "../../constant";
import useSWR from "swr";
import { fetcher, instance } from "../../api";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { retrieveErrorMessages } from "../../utils/errorsDisplay";
import { LoadingButton } from "@mui/lab";

// ---

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

// ---

function AddMediaDialog({ open, handleClose }: { open: boolean, handleClose: () => void }) {
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState('');

	const addMedia = () => {
		setLoading(true);
		const name = (document.getElementById('name') as HTMLInputElement).value;
		const description = (document.getElementById('description') as HTMLInputElement).value;
		const date = (document.getElementById('date') as HTMLInputElement).value;
		const url = (document.getElementById('url') as HTMLInputElement).value;

		instance.post('/medias', {
			name: name,
			description: description,
			link: url,
			date: date,
			type: type,
		}).then(() => {
			enqueueSnackbar('Le raccourci a bien été ajouté.', { variant: 'success' });
			handleClose();
			setLoading(false);
		}).catch((response) => {
			const data = response?.data;
			enqueueSnackbar(retrieveErrorMessages(data?.code, data?.message, "Une erreur est survenue lors de l\'ajout du média."), { variant: 'error' });
			setLoading(false);
		});
	}

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
			<DialogTitle>Ajouter un média</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Stack direction="column" gap={2} mt={1}>
						<TextField
							label="Nom"
							id="name"
							variant="outlined"
							fullWidth
						/>
						<TextField
							label="Description"
							id="description"
							variant="outlined"
							fullWidth
						/>
						<TextField
							label="URL"
							id="url"
							variant="outlined"
							fullWidth
						/>
						<TextField
							id="date"
							label="Date de prise"
							type="date"
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							autoComplete="off"

						/>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="type-select-label">Type</InputLabel>
							<Select
								labelId="type-select-label"
								id="type-select"
								label="Type"
								value={type}
								placeholder="Choisir un type de média"
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 48 * 4.5 + 8,
											width: 250,
										},
									},
								}}
								// @ts-ignore
								onChange={(e: ChangeEvent<{ value: unknown }>) => setType(e.target.value as string)}
							>
								<MenuItem key={0} value={'photo'}>
									Photos
								</MenuItem>
								<MenuItem key={1} value={'video'}>
									Vidéos
								</MenuItem>
								<MenuItem key={2} value={'other'}>
									Autres
								</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleClose}>Annuler</Button>
				<LoadingButton loading={loading} variant="contained" onClick={addMedia}>Ajouter</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}


function DeleteMediaDialog({ open, handleClose, media }: { open: boolean, handleClose: () => void, media: any }) {
	const [loading, setLoading] = useState(false);

	const deleteMedia = () => {
		setLoading(true);
		instance.delete(`/medias/${media.id}`).then(() => {
			enqueueSnackbar('Le raccourci a bien été supprimé.', { variant: 'success' });
			handleClose();
			setLoading(false);
		}).catch((response) => {
			enqueueSnackbar(retrieveErrorMessages(response?.data?.code, "Une erreur est survenue durant la suppression du média"), { variant: 'error' });	
			setLoading(false);
		});
	}

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
			<DialogTitle>Supprimer un média</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Êtes-vous sûr de vouloir supprimer le média <strong>{media.name}</strong> ?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleClose}>Annuler</Button>
				<LoadingButton loading={loading} variant="contained" onClick={deleteMedia} color="error">Supprimer</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}

function EditMediaDialog({ open, handleClose, media }: { open: boolean, handleClose: () => void, media: any }) {
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState(media.type);

	const editMedia = () => {
		setLoading(true);
		const name = (document.getElementById('name') as HTMLInputElement).value;
		const description = (document.getElementById('description') as HTMLInputElement).value;
		const url = (document.getElementById('url') as HTMLInputElement).value;
		const date = (document.getElementById('date') as HTMLInputElement).value;

		instance.put(`/medias/${media.id}`, {
			name: name,
			description: description,
			link: url,
			date: date,
			type: type,
		}).then(() => {
			enqueueSnackbar('Le raccourci a bien été modifié.', { variant: 'success' });
			handleClose();
			setLoading(false);
		}).catch((response) => {
			const data = response?.data;
			enqueueSnackbar(retrieveErrorMessages(data?.code, data?.message, 'Une erreur est survenue lors de la modification du média.'), { variant: 'error' });
			setLoading(false);
		});
	}

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
			<DialogTitle>Modifier un média</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Stack direction="column" gap={2} mt={1}>
						<TextField
							label="Nom"
							id="name"
							variant="outlined"
							fullWidth
							defaultValue={media.name}
						/>
						<TextField
							label="Description"
							id="description"
							variant="outlined"
							fullWidth
							defaultValue={media.description}
						/>
						<TextField
							id="date"
							label="Date de prise"
							type="date"
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							autoComplete="off"
							defaultValue={media.date}
						/>
						<TextField
							label="URL"
							id="url"
							variant="outlined"
							fullWidth
							defaultValue={media.link}
						/>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="type-select-label">Type</InputLabel>
							<Select
								labelId="type-select-label"
								id="type-select"
								label="Type"
								value={type}
								defaultValue={media.type}
								placeholder="Choisir un type de média"
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 48 * 4.5 + 8,
											width: 250,
										},
									},
								}}
							>
								<MenuItem key={0} value={'photo'}>
									Photos
								</MenuItem>
								<MenuItem key={1} value={'video'}>
									Vidéos
								</MenuItem>
								<MenuItem key={2} value={'other'}>
									Autres
								</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleClose}>Annuler</Button>
				<LoadingButton loading={loading} variant="contained" onClick={editMedia}>Modifier</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}

// ---

function MediasManagement() {
	const { data, isLoading, error } = useSWR('/medias', fetcher);
	const loadingSkeleton = Array(5).fill(0);

	const [addMediaDialogOpen, setAddMediaDialogOpen] = useState(false);

	const [deleteMediaDialogOpen, setDeleteMediaDialogOpen] = useState(false);
	const [mediaToDelete, setMediaToDelete] = useState({});

	const [editMediaDialogOpen, setEditMediaDialogOpen] = useState(false);
	const [mediaToEdit, setMediaToEdit] = useState({});

	const medias = !isLoading && data.data;
	
	return (
		<>
			<Helmet>
				<title>Gestion des médias { TITLE }</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Box sx={{ mb: 5 }}>
							<Typography variant="h4" gutterBottom>
								Gestion des médias
							</Typography>
							<Breadcrumbs separator="›" aria-label="breadcrumb">
								<Link underline="hover" color="inherit" href="/">
									Accueil
								</Link>
								<Link underline="hover" color="inherit" href="/medias">
									Médias
								</Link>
								<Typography color="text.primary">Gestion</Typography>
							</Breadcrumbs>
						</Box>
						<Box>
							<Button 
								variant="contained"
								onClick={() => setAddMediaDialogOpen(true)}
							>
								<Iconify icon="mingcute:add-line" mr={1} /> Ajouter un média
							</Button>
						</Box>
					</Stack>
				</HeaderTitle>

				{error && (
					<Alert severity="error" sx={{ my: 3 }}>
						<AlertTitle>Erreur d'actualisation</AlertTitle>
						Les données les plus récentes n'ont pas pu être récupérées. Les données affichées peuvent être obsolètes.
					</Alert>
				)}

				{isLoading && (
					<Stack gap={'26px'}>
					{loadingSkeleton.map((_, index) => {
						return (
							<Card key={index}>
								<CardContent
									sx={{
										width: '100%',
										flexGrow: 1,
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
									}}
								>	
									<Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
										<Skeleton variant="rounded" width={260} height={30} />
										<Skeleton variant="rounded" width={320} height={20} />
										<Stack direction="row" gap={1}>
											<Skeleton variant="circular" width={30} height={30} />
											<Skeleton variant="circular" width={30} height={30} />
										</Stack>
									</Stack>
								</CardContent>
							</Card>
						);
					})}					
					</Stack>
				)}

				{!isLoading && medias?.length === 0 && (
					<Alert severity="info" sx={{ my: 3 }}>
						<AlertTitle>Aucun raccourci</AlertTitle>
						Aucun raccourci n'a été créé pour le moment.
					</Alert>
				)}

				{!isLoading && medias?.length > 0 && (
					<Stack gap={'26px'}>
					{medias.map((Media: any, index: number) => {
						return (
							<Card key={index}>
								<CardContent
									sx={{
										width: '100%',
										flexGrow: 1,
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
									}}
								>	
									<Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
										<Stack>
											<Typography variant="h6" component="h2">
												{Media.name}
											</Typography>
											<Typography variant="body2" component="p">
												{Media.description}
											</Typography>
										</Stack>
										<Link 
											href={Media.url}
											target="_blank"
											underline="none"
											color="inherit"
											sx={{
												'@media (max-width: 900px)': {
													display: 'none',
												},
											}}
										>
											<Typography variant="body2" component="p" color="text.secondary" fontStyle="oblique">
												{Media.url}
											</Typography>
										</Link>
										<Stack direction="row" gap={0}>
											<IconButton
												onClick={() => {
													setMediaToEdit(Media);
													setEditMediaDialogOpen(true);
												}}
											>
												<Iconify icon="solar:pen-bold" />
											</IconButton>
											<IconButton
												color="error"
												onClick={() => {
													setMediaToDelete(Media);
													setDeleteMediaDialogOpen(true);
												}}
											>
												<Iconify icon="solar:trash-bin-2-bold" />
											</IconButton>
										</Stack>
									</Stack>
								</CardContent>
							</Card>
						);
					})}
					</Stack>
				)}

				<AddMediaDialog
					open={addMediaDialogOpen}
					handleClose={() => setAddMediaDialogOpen(false)}
				/>

				<DeleteMediaDialog
					open={deleteMediaDialogOpen}
					handleClose={() => setDeleteMediaDialogOpen(false)}
					media={mediaToDelete}
				/>

				<EditMediaDialog
					open={editMediaDialogOpen}
					handleClose={() => setEditMediaDialogOpen(false)}
					media={mediaToEdit}
				/>

			</Container>
		</>
	)
}

export default MediasManagement;