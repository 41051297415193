// @ts-nocheck

import { Autocomplete, Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Navigate, useLocation, useParams } from "react-router";
import SimpleBar from "simplebar-react";
import { capitalCaseTransform } from "change-case";
import { useCallback, useRef, useState } from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fr from 'date-fns/locale/fr';
import { LoadingButton } from "@mui/lab";
import { createMemberBySelfCreationForm } from "../../api/members";
import { formatToTimeZone } from 'date-fns-timezone';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MemberCreationValidationSchema } from "../../utils/yupValidation";
import { enqueueSnackbar } from "notistack";
import { TITLE } from "../../constant";
import { instance } from "../../api";

// ---

interface ByEmailMemberCreationFormValues {
	firstName: string;
	lastName: string;
	birthDate: Date;
	instrumentId: Number;
	instrumentReference: string;
	phone: string;
	emergencyContact: string;
	otherEmails: string;
	address: string;
	gender: Number;
	shirtSize: string;
}

await instance.get('/instruments').then((response) => {
	localStorage.setItem('instruments', JSON.stringify(response.data));
});

const instruments = (JSON.parse(localStorage.getItem('instruments')) || []).sort(
	(a, b) => a.name.localeCompare(b.name)
), RenderItem = (value) => {
	return <MenuItem value={value.id}>{value.name}</MenuItem>
}

// ---

function ByEmailMemberCreationForm() {
	const { token, email } = useParams();
	const { state } = useLocation();
	const [loading, setLoading] = useState(false);

	const [birthDateValue, setBirthDateValue] = useState<Date | null>(null);
	const [requestSuccessful, setRequestSuccessful] = useState(false);

	const getOptionLabel = useCallback(
		(option: PlaceType) => (typeof option === 'string' ? option : option.label),
		[]
	);

	const { 
		register,
		handleSubmit,
		reset,
		getValues,
		formState: { errors }, 
	} = useForm<ByEmailMemberCreationFormValues>({
		mode: 'onChange',
		resolver: yupResolver(MemberCreationValidationSchema),
	});

	const [addressAutocompleteOptions, setAddressAutocompleteOptions] = useState([]);
	const previousController = useRef();
	
	if (requestSuccessful) return (<Navigate to="/auth/login"/>);

	const addressAutocomplete = async (address: string) => {
		const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${address}&autocomplete=1`);
		const data = await response.json();
		console.log(data);

		return data;
	}

	const getData = (searchTerm: string) => {		
		const controller = new AbortController();
		const signal = controller.signal;
		previousController.current = controller;

		if (searchTerm.length < 3) {
			setAddressAutocompleteOptions([]);
			return;
		}

		fetch(`https://api-adresse.data.gouv.fr/search/?q=${searchTerm}&type=housenumber&autocomplete=1`, {
			signal,
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json"
			}
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (myJson) {
				const updatedOptions = myJson.features.map((adr) => {
				return {
					label: adr.properties.label,
					street: adr.properties.name,
					city: adr.properties.city,
					zipCode: adr.properties.postcode,
				};
			});
			setAddressAutocompleteOptions(updatedOptions);
		})
		.catch(function (error) {
			console.error(error);
		});
	};
	  
	const addressAutocompleteChange = (event, value, reason) => {
		if (value) {
			getData(value);
		} else {
			setAddressAutocompleteOptions([]);
		}
	};

	const handleCreation = (data: ByEmailMemberCreationFormValues) => {
		setLoading(true);
		delete data.address;

		data = {
			...data,
			firstName: capitalCaseTransform(data.firstName),
			lastName: data.lastName.toUpperCase(),
			token,
			email,
			birthDate: formatToTimeZone(birthDateValue, 'YYYY-MM-DD', { timeZone: 'Europe/Paris' }),
			address: addressAutocompleteOptions[0].street,
			addressCity: addressAutocompleteOptions[0].city,
			addressZipCode: addressAutocompleteOptions[0].zipCode,
		}

		createMemberBySelfCreationForm(data)
			.then((response) => {
				if (response.status == 'success') {
					enqueueSnackbar('Votre compte a bien été créé. Vous pouvez désormais vous connecter.', { variant: 'success' });
					setLoading(true);
					setRequestSuccessful(true);
					return;
				}
			}).catch((error) => {
				setLoading(false);
				enqueueSnackbar('Une erreur est survenue lors de la création de votre compte. Merci de réessayer. (' + error.response.data.error + ')', { variant: 'error' });
			});
	}

	return (
		<>
			<Helmet>
				<title>Formulaire de renseignement { TITLE }</title>
			</Helmet>

			
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					marginBottom: '32px',
				}}
			>

				<Typography
					sx={{
						fontWeight: 700,
						lineHeight: 1.2,
						fontSize: '1.4823rem !important',
					}}
				>
					Formulaire de renseignement
				</Typography>
				<Typography sx={{ margin: '8px, 0 0' }}>
					Renseignez les informations suivantes pour finaliser votre inscription.
				</Typography>
			</Box>

			<SimpleBar style={{ maxHeight: 'calc(100% - 100px)' }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						marginTop: '5px',
					}}
				>
					<form onSubmit={handleSubmit(handleCreation)} >
						<Stack spacing={2}>
							<TextField 
								{...register('firstName')}
								id="firstName"
								label="Prénom *"
								disabled={loading}
								error={!!errors.firstName}
								helperText={errors.firstName && errors?.firstName?.message}
							/>
							<TextField
								{...register('lastName')}
								id="lastName"
								label="NOM *"
								disabled={loading}
								error={!!errors.lastName}
								helperText={errors.lastName && errors?.lastName?.message}
							/>

							<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
								<FormControl fullWidth>
									<InputLabel id="gender-label" disabled={loading} error={!!errors.gender}>Genre *</InputLabel>
									<Select
										{...register('gender')}
										id="gender"
										labelId="gender-label"
										label="Genre *"
										disabled={loading}
										error={!!errors.gender}
									>
										<MenuItem value="1">Homme</MenuItem>
										<MenuItem value="2">Femme</MenuItem>
									</Select>
									<FormHelperText error={!!errors.gender}>{errors.gender && errors?.gender?.message}</FormHelperText>
								</FormControl>

								<FormControl fullWidth>
									<InputLabel id="shirtSize-label" disabled={loading} error={!!errors.shirtSize}>Taille T-Shirt *</InputLabel>
									<Select
										{...register('shirtSize')}
										id="shirtSize"
										labelId="shirtSize-label"
										label="Taille T-Shirt *"
										disabled={loading}
										error={!!errors.shirtSize}
									>
										<MenuItem value="XSS">XXS</MenuItem>
										<MenuItem value="XS">XS</MenuItem>
										<MenuItem value="S">S</MenuItem>
										<MenuItem value="M">M</MenuItem>
										<MenuItem value="L">L</MenuItem>
										<MenuItem value="XL">XL</MenuItem>
										<MenuItem value="XXL">XXL</MenuItem>
										<MenuItem value="3XL">3XL</MenuItem>
										<MenuItem value="4XL">4XL</MenuItem>
										<MenuItem value="5XL">5XL</MenuItem>
									</Select>
									<FormHelperText error={!!errors.shirtSize}>{errors.shirtSize && errors?.shirtSize?.message}</FormHelperText>
								</FormControl>
							</Stack>

							<Autocomplete
								id="address"
								autoComplete
								filterOptions={(x) => x}
								filterSelectedOptions
								getOptionLabel={getOptionLabel}
								isOptionEqualToValue={(option, value) => option.label === value.label}
								onInputChange={addressAutocompleteChange}
								options={addressAutocompleteOptions}
								renderInput={(params) => 
									<TextField 
										{...register('address')}
										{...params} 
										label="Adresse *"
										error={!!errors.address}
										helperText={errors.address && errors?.address?.message}
									/>}
								disabled={loading}
								noOptionsText="Aucune adresse trouvée"
								autoHighlight={true}
							>
							</Autocomplete>

							<FormControl>
								<InputLabel id="instrumentId-label" disabled={loading} error={!!errors.instrumentId}>Pupitre *</InputLabel>
								<Select
									{...register('instrumentId')}
									id="instrumentId"
									labelId="instrumentId-label"
									label="Pupitre *"
									disabled={loading}
									error={!!errors.instrumentId}
								>
									{instruments.map(RenderItem)}
								</Select>
								<FormHelperText error={!!errors.instrumentId}>{errors.instrumentId && errors?.instrumentId?.message}</FormHelperText>
							</FormControl>

							<TextField
								{...register('instrumentReference')}
								id="instrumentReference"
								label="Référence de l'instrument *"
								disabled={loading}
								error={!!errors.instrumentReference}
								helperText={errors.instrumentReference && errors?.instrumentReference?.message}
							/>

							{/* TODO: Fix this validation */}
							<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
								<DatePicker
									label="Date de naissance *"
									disabled={loading}
									disableFuture
									id="birthDate"
									openTo="year"
									views={['year', 'month', 'day']}
									onChange={(newValue) => {
										setBirthDateValue(newValue);
									}}
								/>
							</LocalizationProvider>

							<TextField
								{...register('email')}
								id="email"
								label="Adresse e-mail *"
								disabled={true}
								value={email}
							/>

							<TextField
								{...register('phone')}
								placeholder="+33 X XX XX XX XX"
								id="phone"
								label="Numéro de téléphone *"
								disabled={loading}
								error={!!errors.phone}
								helperText={errors.phone && errors?.phone?.message}
							/>

							<TextField
								{...register('emergencyContact')}
								id="emergencyContact"
								label="Personne à contacter en cas d'urgence"
								disabled={loading}
							/>

							<TextField
								{...register('otherEmails')}
								id="otherEmails"
								label="Autres adresses pour la mailing-list (une par ligne)"
								multiline
								disabled={loading}
							/>
						</Stack>

						<LoadingButton
							loading={loading}
							size="large"
							type="submit"
							fullWidth
							variant="contained"
							sx={{
								marginTop: '16px',
							}}
						>
							Valider
						</LoadingButton>
					</form>
				</Box>
			</SimpleBar>
		</>
	);
}

export default ByEmailMemberCreationForm;