import { Navigate, useRoutes } from 'react-router-dom';
import { isLogged, getRole } from './api/auth';

// Layouts
import DashboardLayout from './layouts/dashboard';
import AuthLayout from './layouts/auth/AuthLayout';

// Pages
import Login from "./pages/auth/Login";
import Home from './pages/Home';
import ForgotPassword from './pages/auth/ForgotPassword';
import VerifyEmail from './pages/auth/VerifyEmail';
import ResetPassword from './pages/auth/ResetPassword';
import Error404 from './pages/errors/404';
import Members from './pages/members/Index';
import Shortcuts from './pages/Shortcuts';
import Instruments from './pages/Instruments';
import Roles from './pages/Roles';
import Users from './pages/users/Index';
import AttendancesManagement from './pages/attendance/Management';
import Attendances from './pages/attendance/Index';
import Contacts from './pages/Contacts';
import ByEmailMemberCreationForm from './pages/members/ByEmailCreationForm';
import AttendanceDetails from './pages/attendance/Details';
import AttendanceManagementDetails from './pages/attendance/ManagementDetails';
import Profile from './pages/users/Profile';
import MemberDetails from './pages/members/Details';
import MediasIndex from './pages/medias/Index';
import MediasManagement from './pages/medias/Management';
import TableFullView from './pages/attendance/TableFullView';
import Madeleine from './pages/attendance/Madeleine';

// import Locked from './pages/auth/Locked';
// ---

let authRoutes = {
    path: '/auth',
    element: <AuthLayout />,
    children: [
        { element: <Navigate to='/auth/login' />, index: true },
        { path: 'login', element: <Login /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password/:token', element: <ResetPassword /> },
        { path: 'verify-email/:token', element: <VerifyEmail /> }
    ]
}

let createMemberRoutes = {
    path: '/member-creation-form',
    element: <AuthLayout />,
    children: [
        { element: <Navigate to='/404' />, index: true },
        { path: ':token/:email', element: <ByEmailMemberCreationForm /> }
    ]
}

let dashboardRoutes = {
    path: '/',
    element: <DashboardLayout />,
    children: [
        { element: <Home />, index: true },
        { path: 'profile', element: <Profile /> },
        { path: 'attendance', element: <Attendances /> },
        { path: 'attendance/full', element: <TableFullView />},
        { path: 'attendance/:uuid', element: <AttendanceDetails /> },
        { path: 'medias', element: <MediasIndex /> },
        { path: 'contact', element: <Contacts /> }
    ]
}

let bureauDashboardRoutes = [
    { path: 'members', element: <Members /> },
    { path: 'members/:uuid', element: <MemberDetails /> },
    { path: 'attendance-management', element: <AttendancesManagement /> },
    { path: 'attendance-management/:uuid', element: <AttendanceManagementDetails /> },
    { path: 'medias-management', element: <MediasManagement /> },
    { path: 'repartition', element: <Madeleine /> }
]

let adminDashboardRoutes = [
    { path: 'shortcuts', element: <Shortcuts /> },
    { path: 'instruments', element: <Instruments /> },
    { path: 'roles', element: <Roles /> },
    { path: 'users', element: <Users /> }
]

let compiledRoutes = [
    {
        path: '*',
        element: <Error404 />
    },
    createMemberRoutes,
    authRoutes
];

if (isLogged()) {
    if (getRole() == 1) {
        dashboardRoutes.children.push(...adminDashboardRoutes, ...bureauDashboardRoutes)
    } else if (getRole() == 2) {
        dashboardRoutes.children.push(...bureauDashboardRoutes)
    }
}

compiledRoutes.push(dashboardRoutes);

export default function Router() {
    const routes = useRoutes(compiledRoutes);
    return routes;
}