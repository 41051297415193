import { Box, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Iconify from "../../components/iconify/Iconify";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { instance } from "../../api";
import { Navigate, useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import { TITLE } from "../../constant";
import { retrieveErrorMessages } from "../../utils/errorsDisplay";

function ResetPassword() {
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
    const { token } = useParams();

    const [requestSuccessful, setRequestSuccessful] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }
    const handlePasswordConfirmationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirmation(event.target.value)
    }

    if (requestSuccessful) return (<Navigate to="/auth/login"/>);

    const handleResetPassword = async () => {
        setLoading(true);

        const response = await instance.patch('auth/password-reset/' + token, {
            password: password,
            password_confirmation: passwordConfirmation,
        }).then((response) => response.data)
        .then((response) => {
            if (response.status == 'success') {
                enqueueSnackbar('Votre mot de passe a bien été réinitialisé. Vous pouvez vous connecter.', { variant: 'success' });
                setRequestSuccessful(true);
            }
        }).catch((error) => {
            setLoading(false);
            enqueueSnackbar(retrieveErrorMessages(error.response.data.error, error.response.data.message, "Votre mot de passe n\'a pas pu être réinitialisé. Veuillez réessayer."), { variant: 'error' });
        });
    }

	return (
		<>
            <Helmet>
                <title>Réinitialiser son mot de passe { TITLE }</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '32px',
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 700,
                        lineHeight: 1.2,
                        fontSize: '1.4823rem !important',
                    }}
                >
                    Réinitialiser son mot de passe
                </Typography>
                <Typography
                    sx={{
                        margin: '8px, 0 0'
                    }}
                >
                    Vous pouvez choisir un nouveau mot de passe.
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Stack spacing={2}>
                    <TextField
                        required
                        id="password" 
                        label="Mot de passe" 
                        variant="outlined" 
                        autoFocus
                        disabled={loading}
						type={showPassword ? 'text' : 'password'}
                        onChange={handlePasswordChange}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        {/* @ts-ignore */}
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                              </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        required
                        disabled={loading}
                        id="password_confirmation" 
                        label="Mot de passe (confirmation)" 
                        variant="outlined" 
                        type={showPassword ? 'text' : 'password'}
                        onChange={handlePasswordConfirmationChange}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        {/* @ts-ignore */}
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                              </InputAdornment>
                            ),
                        }}
                    />
                </Stack>

                <LoadingButton
                    loading={loading}
                    size="large"
                    type="submit"
                    fullWidth
                    variant="contained"
					sx={{
						marginTop: '24px',
					}}
                    onClick={handleResetPassword}
                >
                    Modifier le mot de passe
                </LoadingButton>
            </Box>
        </>
	)
}

export default ResetPassword;