import { Helmet } from 'react-helmet-async';

import { Alert, AlertTitle, Button, Card, CardContent, CardHeader, CardMedia, Container, Grid, IconButton, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { MAINTENANCE, TITLE } from '../constant';
import Iconify from '../components/iconify';
import useSWR from 'swr';
import { fetcher } from '../api';
import TimeAgo from 'timeago-react';

// ---

export default function Home() {
	const { data, error, isLoading } = useSWR('/shortcuts', fetcher);

	const maintenance = MAINTENANCE;

	// Bonjour / Bonsoir in function of the time (18h = Bonsoir / 6h = Bonjour)
	const date = new Date();
	const hours = date.getHours();
	const message = hours >= 6 && hours < 18 ? 'Bonjour' : 'Bonsoir';

	const user = JSON.parse(localStorage.getItem('user') || '{}');

	return (
		<>
			<Helmet>
				<title>Accueil { TITLE }</title>
			</Helmet>

			<Container maxWidth="xl">
				<Typography variant="h4" sx={{ mb: 5 }}>
					{message} {user.first_name} ! 👋
				</Typography>

				{maintenance.active && (
					<Alert variant="filled" severity="info" sx={{ marginTop: '16px' }}>
						<AlertTitle>Maintenance</AlertTitle>
						La plateforme sera en maintenance le <strong>{ maintenance.date }</strong> de <strong>{ maintenance.hours }</strong>. L'accès sera donc désactivé durant cette période. Merci pour votre compréhension.
					</Alert>
				)}

				<Grid container spacing={2} mt={2}>
					<Grid item xs={12} md={9}>
						{/* <Card>
							<CardMedia component="img" height="auto" image="./assets/images/convoc_ag.png" alt="Bannière de l'association" />
							<CardHeader title="Assemblée générale de 2024" />
							<CardContent>
								<Stack spacing={2}>
									<Typography variant="body1">
										Le bureau de l'association vous informe de la tenue de l'assemblée générale de l'association le <strong>vendredi 19 janvier 2024 à 21h</strong>, <TimeAgo datetime={'2024-01-19 21:00:00'} locale='fr' />, à la Salle de répétition.
									</Typography>

									<Typography variant="body1">
										L'ordre du jour est le suivant :
										<Stack spacing={0} sx={{ pl: 2 }}>
											<Typography variant="body1" component="span">- Rapport moral de l’année 2023 par les coprésidents</Typography>
											<Typography variant="body1" component="span">- Rapport financier par la trésorière</Typography>
											<Typography variant="body1" component="span">- Rapport d’activité par le secrétaire</Typography>
											<Typography variant="body1" component="span">- Bilan musical 2023 du chef d’orchestre</Typography>
											<Typography variant="body1" component="span">- Présentation de l’année 2024 par les coprésidents et le chef d'orchestre</Typography>
											<Typography variant="body1" component="span">- Élection du nouveau conseil d’administration</Typography>
											<Typography variant="body1" component="span">- Questions diverses</Typography>
										</Stack>
									</Typography>

									<Typography variant="body1">
										Le bureau de l'association vous invite à participer à cette assemblée générale, et vous remercie de votre présence.
									</Typography>

									<Stack spacing={1}>
										<Typography variant="body1">
											Vous trouverez ci-dessous les documents relatifs à cette assemblée générale :
										</Typography>

										<Stack spacing={1} direction="row">
											<Button variant="contained" color="primary" startIcon={<Iconify icon="mdi:file-pdf" width={20} />} href="./documents/Convocation AG 2024.pdf" target="_blank">Convocation</Button>
											<Button variant="outlined" color="secondary" startIcon={<Iconify icon="mdi:file-pdf" width={20} />} href="./documents/Procuration AG.pdf" target="_blank">Procuration</Button>
											<Button variant="text" color="secondary" startIcon={<Iconify icon="mdi:file-pdf" width={20} />} href="./documents/Autorisation parentale.pdf" target="_blank">Autorisation parentale</Button>
										</Stack>
									</Stack>
								</Stack>
							</CardContent>
						</Card> */}

						{/* <Card>
							<CardMedia component="img" height="auto" image="./assets/images/madeleine2024.jpg" alt="Bannière Madeleine 2024" />
							<CardHeader title="Organisation de la Madeleine 2024" />
							<CardContent>
								<Stack spacing={2}>
									<Typography variant="body1" sx={{ textAlign: 'justify' }}>
										Comme chaque année, l'Orchestre Montois participe à la Madeleine de Mont-de-Marsan. Cette année, la Madeleine se déroulera du <strong>mercredi 17 juillet</strong> au <strong>dimanche 21 juillet 2024</strong>.
									</Typography>

									<Typography variant="body1" sx={{ textAlign: 'justify' }}>
										Vous trouverez ci-dessous les documents relatifs à l'organisation de la Madeleine 2024 avec, d'une part, le <strong>programme musical</strong> réalisé par Pierre, et d'autre part, l'<strong>organisation de la Madeleine</strong> réalisée par le bureau de l'association avec les diverses informations nécessaires pour le bon déroulement de ces festivités.
									</Typography>

									<Typography variant="body1" sx={{ textAlign: 'justify' }}>
										Enfin, vous trouverez également le formulaire de demande d'invitations pour les corridas.<br/>
										Comme chaque année, l'orchestre se voit offrir des invitations pour les corridas afin de les distribuer à ses membres, et ce dans la limite des places disponibles. Nous vous invitons donc à remplir le formulaire si vous souhaitez obtenir des invitations. Le bureau se chargera de les distribuer en fonction des demandes et se réserve le droit de refuser une demande si le nombre de places disponibles est dépassé.
									</Typography>

									<Stack spacing={1}>
										<Stack spacing={1} direction={{xs: "column", md: "row"}}>
											<Button variant="contained" color="success" startIcon={<Iconify icon="solar:music-note-3-bold" width={20} />} href="https://r.orchestremontois.fr/progm24" target="_blank">Programme musical</Button>
											<Button variant="contained" color="warning" startIcon={<Iconify icon="solar:sort-by-time-bold" width={20} />} href="https://r.orchestremontois.fr/orgam24" target="_blank">Organisation Madeleine</Button>
											<Button variant="outlined" color="error" startIcon={<Iconify icon="solar:gift-bold" width={20} />} href="https://r.orchestremontois.fr/invcm24" target="_blank">Demande d'invitations corridas</Button>
										</Stack>
									</Stack>
								</Stack>
							</CardContent>
						</Card> */}
					</Grid>
					<Grid item xs={12} md={3}>
						<Card>
							<CardHeader title="Raccourcis" />
							<CardContent>
								<Stack>
									{!isLoading && data && data.map((shortcut: any) => (
										<ListItemButton 
											key={shortcut.id} 
											component="a" 
											href={shortcut.url} 
											target="_blank"
											sx={{
												'&:hover': {
													backgroundColor: '#f5f5f5'
												},
												borderRadius: 1
											}}
										>
											<ListItemIcon>
												<Iconify icon="solar:link-bold" width={25} />
											</ListItemIcon>
											<ListItemText primary={shortcut.name} />
										</ListItemButton>
									))}

									{!isLoading && error && (
										<Typography variant="body1" color="error">
											Une erreur est survenue lors du chargement des raccourcis.
										</Typography>
									)}
								</Stack>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</>
	)
}