import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Navigate, Outlet } from "react-router";
import { isLogged } from "../../api/auth";
import useSWR from "swr";
import { MAINTENANCE } from "../../constant";

const BoxMain = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.common.white,
	display: 'flex',
	flex: '1 1 auto',
	height: '100%',
	width: '100%',
	[theme.breakpoints.up('md')]: {
		flexDirection: 'row',
	},
	[theme.breakpoints.down('md')]: {
		flexDirection: 'column-reverse',
		height: 'auto !important',
	}
}))

const BoxBackground = styled(Box)(({ theme}) => ({
	backgroundColor: theme.palette.grey[800],
	WebkitBoxAlign: 'center',
	alignItems: 'center',
	backgroundImage: 'url("/assets/gradient-bg.svg")',
	backgroundPositionX: 'center',
	backgroundPositionY: 'top',
	backgroundRepeat: 'no-repeat',
	color: theme.palette.common.white,
	display: 'flex',
	WebkitBoxPack: 'center',
	justifyContent: 'center',
	flex: '0 0 auto',
	padding: '32px',
	[theme.breakpoints.up('md')]: {
		flex: '1 1 auto',
		padding: '64px',
	},
	[theme.breakpoints.down('md')]: {
		flex: '0 0 auto',
		padding: '32px',
	}
}))

const BoxContent = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.common.white,
	display: 'flex',
	flexDirection: 'column',
	maxWidth: '100%',
	[theme.breakpoints.up('md')]: {
		flex: '0 0 auto',
		justifyContent: 'center',
		padding: '64px',
		width: '600px',
	},
	[theme.breakpoints.down('md')]: {
		flex: '1 1 auto',
		padding: '32px',
	}
}))

export default function AuthLayout() {
	const { data, isLoading, error } = useSWR('https://status.sapi.net/v2/components.json', (url) => fetch(url).then((res) => res.json()));

	let status = 'OPERATIONAL';

	let maintenance = MAINTENANCE;

	let components;
	if (!isLoading && data) {
		components = data.components.filter((component: any) => component.id === 'clora4lib43832bfoo6521ja0i')[0];
		status = components.status;
		if (status === 'UNDERMAINTENANCE') {
			maintenance.active = true;
			maintenance.date = new Date().toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
			maintenance.hours = new Date().toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
		}

		if (components.activeMaintenances?.[0])  {
			maintenance.active = true;
			maintenance.date = new Date(components.activeMaintenances[0].start).toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
			maintenance.hours = new Date(components.activeMaintenances[0].start).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }) + ' à ' + new Date(components.activeMaintenances[0].end).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
		}
	}
	

	if (isLogged()) return (<Navigate to="/" />);

	return (
		<BoxMain>
			<BoxBackground>
				<Box
					sx={{
						maxWidth: 900,
					}}
				>
					<Typography variant="h4"
						sx={{
							margin: '0 0 8px',
							fontWeight: 700,
							lineHeight: 1.2,
							fontSize: '2.125rem !important',
						}}
					>
						Bienvenue sur l'extranet de l'Orchestre Montois
					</Typography>
					<Typography variant="body1"
						sx={{
							margin: '0 0 32px',
							fontWeight: 400,
							lineHeight: 1.5,
							color: 'grey.600',
						}}
					>
						Vous êtes membre de l'Orchestre Montois ? Après vous être connecté, vous pourrez accéder aux différentes informations concernant l'orchestre. Vous pourrez aussi vous inscrire aux répétitions et aux concerts ainsi que consulter les partitions et bien plus.
					</Typography>

					{!maintenance.active && status === 'MAJOROUTAGE' && (
						<Alert variant="filled" severity="error" sx={{ marginTop: '16px' }}>
							<AlertTitle>Problème technique</AlertTitle>
							La plateforme rencontre actuellement un problème majeur. L'accès est donc désactivé. Merci de votre compréhension.
						</Alert>)
					}
					
					{!maintenance.active && status === 'PARTIALOUTAGE' && (
						<Alert variant="filled" severity="warning" sx={{ marginTop: '16px' }}>
							<AlertTitle>Problème technique</AlertTitle>
							La plateforme rencontre actuellement des problèmes techniques. L'accès peut ne pas être oppérationnel. Merci de votre compréhension.
						</Alert>)
					}

					{!maintenance.active && status === 'DEGRADEDPERFORMANCE' && (
						<Alert variant="filled" severity="warning" sx={{ marginTop: '16px' }}>
							<AlertTitle>Performance dégradées</AlertTitle>
							La plateforme rencontre actuellement des problèmes de performance. L'accès peut donc être ralenti. Merci de votre compréhension.
						</Alert>)
					}

					{maintenance.active && (
						<Alert variant="filled" severity="info" sx={{ marginTop: '16px' }}>
							<AlertTitle>Maintenance</AlertTitle>
							La plateforme sera en maintenance le <strong>{ maintenance.date }</strong> de <strong>{ maintenance.hours }</strong>. L'accès sera donc désactivé durant cette période. Merci pour votre compréhension.
						</Alert>)
					}
				</Box>
			</BoxBackground>
			<BoxContent>
				<Box sx={{
						marginBottom: '32px',
						flexDirection: 'row',
						display: 'inline-flex',
						alignItems: 'center',
						WebkitBoxAlign: 'center',
						gap: '10px',
					}}
				>
					<img src="/logo_color.svg" width={48} alt="Logo" />
					<img src="/om_text.png" height={24} alt="Orchestre Montois" />
				</Box>

				<Outlet />
			</BoxContent>
		</BoxMain>
	)
}