// @ts-nocheck

import Label from '../../components/label';
import Iconify from '../../components/iconify';

import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { ChangeEvent, useState, useRef, useCallback } from 'react';
import { Alert, AlertTitle, Autocomplete, Backdrop, Box, Breadcrumbs, Button, Card, Checkbox, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, Link, ListItemText, Menu, MenuItem, Paper, Select, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import SimpleBar from "simplebar-react";
import Avatar from "boring-avatars";
import { createMemberByAdmin, sendMemberCreationInvite } from "../../api/members";
import { enqueueSnackbar } from 'notistack';
import { fetcher, instance } from '../../api';
import useSWR from 'swr';
import { TITLE } from '../../constant';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fr from 'date-fns/locale/fr';
import { capitalCaseTransform } from 'change-case';
import { formatToTimeZone } from 'date-fns-timezone';

// ---

const instruments = (JSON.parse(localStorage.getItem('instruments')) || []).sort(
	(a, b) => a.name.localeCompare(b.name)
), RenderItem = (value) => {
	return (
		<MenuItem key={value.id} value={value.id}>
			<Checkbox checked={instruments.indexOf(value.name) > -1} />
			<ListItemText primary={value.name} />
		</MenuItem>
	)
}, RenderForCreation = (value) => {
	return <MenuItem value={value.id}>{value.name}</MenuItem>
}

function descendingComparator<Data>(a: Data, b: Data, orderBy: keyof Data[]) {
	const concatA = orderBy.map(key => String(a[key])).join(' ')
	const concatB = orderBy.map(key => String(b[key])).join(' ')

	if (concatB < concatA) {
		return -1;
	}
	if (concatB > concatA) {
		return 1;
	}
	return 0;
}
  
type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
		order: Order,
		orderBy: Key,
	): (
		a: { [key in Key]: number | string },
		b: { [key in Key]: number | string },
	) => number {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_member) => (_member.lastName  + ' ' + _member.firstName + ' ' + _member.phone).toLowerCase().indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

// ---

const FilterStack = styled(Stack)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		alignItems: 'center',
		paddingRight: '8px',
		webkitBoxAlign: 'center',
		gap: '16px',
		flexDirection: 'row',
	},
	[theme.breakpoints.down('md')]: {
		paddingRight: '20px',
		alignItems: 'flex-end',
		flexDirection: 'column',
		gap: '16px',
	},
	padding: '20px',
	display: 'flex',
}));

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

// ---

interface Data {
	id: number;
	uuid: string;
	lastName: string;
	firstName: string;
	email: string;
	address: string;
	zipCode: string;
	city: string;
	birthday: Date;
	phone: string;
	instrumentName: string;
	status: string;
}

interface HeadCell {
	disablePadding: boolean;
	id: string;
	sortedOn: keyof Data[];
	label: string;
	numeric: boolean;
}

const headCells: HeadCell[] = [
	{ id: 'name', sortedOn: ['lastName', 'firstName'], numeric: false, disablePadding: true, label: 'Nom' },
	{ id: 'address', sortedOn: ['zipCode', 'city', 'address'], numeric: false, disablePadding: false, label: 'Adresse' },
	{ id: 'birthday', sortedOn: ['birthday'], numeric: false, disablePadding: false, label: 'Date de naissance' },
	{ id: 'phone', sortedOn: ['phone'], numeric: false, disablePadding: false, label: 'Téléphone' },
	{ id: 'pupitre', sortedOn: ['instrumentName'], numeric: false, disablePadding: false, label: 'Instrument' },
	{ id: 'status', sortedOn: ['status'], numeric: false, disablePadding: false, label: 'Statut' },
];


interface EnhancedTableProps {
	numSelected: number;
	onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void;
	onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: keyof Data[];
	rowCount: number;
}

function CustomTableHead(props: EnhancedTableProps) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property: keyof Data[]) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell 
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.sortedOn ? order : false}
						sx={{ fontWeight: '600', fontSize: '0.875rem' }}
					>
						<TableSortLabel
							active={orderBy === headCell.sortedOn}
							direction={orderBy === headCell.sortedOn ? order : 'asc'}
							onClick={createSortHandler(headCell.sortedOn)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell sx={{ fontWeight: '600', fontSize: '0.875rem' }}></TableCell>
			</TableRow>
		</TableHead>
	)
}

// ---

const OptionBox = styled(Box)(({ theme }) => ({
	width: '100%',
	cursor: 'pointer',
	display: 'flex',
	borderRadius: '4px',
	padding: '1.5rem',
	transition: 'all 0.2s ease-in-out',
	border: '1px dashed ' + theme.palette.grey[300],
	'&:hover': {
		border: '1px dashed ' + theme.palette.primary.main,
		backgroundColor: theme.palette.grey[200],
		color: theme.palette.primary.main,
	},
}));

function PrintDialog(props: { open: boolean, onClose: () => void }) {
	const { onClose, open } = props;

	const handleClose = () => {
		onClose();
	}

	const getPdf = (url: string) => {
		instance.get(url).then((response) => {
			// With é à è ù etc. support
			const blob = new Blob([response.data], { type: 'application/pdf' });
			const url = window.URL.createObjectURL(blob);
			window.open(url, '_blank');
		}).catch((request) => {
			enqueueSnackbar("Une erreur est survenue lors de la génération du PDF.\n(" + request.response.data.error + ")", { variant: 'error' });
		});
	}

	const handleClickOption = (method: Integer) => {
		switch (method) {
			case 1:
				getPdf('/export/pdf/members');
				break;
			case 2:
				getPdf('/export/pdf/signature');
				break;
			case 3:
				getPdf('/export/pdf/instruments');
				break;
		}

		onClose();
	}

	return (
		<Dialog
			onClose={handleClose}
			open={open}
		>
			<DialogTitle>Impression des membres</DialogTitle>
			<DialogContent>
				<Typography>
					Vous pouvez imprimer les membres de l'orchestre. Un PDF sera généré et téléchargé.
				</Typography>

				<Stack direction="column" spacing={2} sx={{ marginTop: '20px' }}>
					<OptionBox onClick={() => handleClickOption(1)}>
						<Iconify icon="solar:user-id-line-duotone" width="50px" />
						<ListItemText sx={{marginLeft: '16px'}}>
							<Typography sx={{fontWeight: 600}}>Liste d'informations générales</Typography>
							<Typography sx={{fontSize: '0.875rem', color: 'grey.600'}}>Cette liste contient tous les membres et des informations générales. Pour avoir des informations détaillées : exportez un CSV.</Typography>
						</ListItemText>
					</OptionBox>

					<OptionBox onClick={() => handleClickOption(2)}>
						<Iconify icon="solar:pen-new-square-line-duotone" width="50px" />
						<ListItemText sx={{marginLeft: '16px'}}>
							<Typography sx={{fontWeight: 600}}>Liste d'émargement</Typography>
							<Typography sx={{fontSize: '0.875rem', color: 'grey.600'}}>Cette liste est faite pour permettre la signature de chaque membre. (membre inactifs compris)</Typography>
						</ListItemText>
					</OptionBox>

					<OptionBox onClick={() => handleClickOption(3)}>
						<Iconify icon="solar:archive-line-duotone" width="50px" />
						<ListItemText sx={{marginLeft: '16px'}}>
							<Typography sx={{fontWeight: 600}}>Références instruments</Typography>
							<Typography sx={{fontSize: '0.875rem', color: 'grey.600'}}>Cette liste contient les références des instruments de chaque membre.</Typography>
						</ListItemText>
					</OptionBox>
				</Stack>
			</DialogContent>
		</Dialog>
	)
}

function AddMemberDialog(props: { open: boolean, onClose: () => void }) {
	const { onClose, open } = props;

	const handleClose = () => {
		onClose();
	}

	const handleClickOption = (method: Integer) => {
		onClose(method);
	}

	return (
		<Dialog
			onClose={handleClose}
			open={open}
		>
			<DialogTitle>Ajouter un membre</DialogTitle>
			<DialogContent>
				<Typography>
					Vous disposez de deux méthodes afin d'ajouter un membre à l'orchestre. Vous pouvez d'une part entrer manuellement toutes les informations le concernant ou bien entrer seulement l'adresse email du futur membre. Dans ce cas, un email lui sera envoyé afin qu'il puisse compléter son profil de lui même.
				</Typography>

				<Stack direction="column" spacing={2} sx={{ marginTop: '20px' }}>
					<OptionBox onClick={() => handleClickOption(1)}>
						<Iconify icon="solar:user-id-line-duotone" width="51px" />
						<ListItemText sx={{marginLeft: '16px'}}>
							<Typography sx={{fontWeight: 600}}>Création manuelle</Typography>
							<Typography sx={{fontSize: '0.875rem', color: 'grey.600'}}>Vous devrez entrer toutes les informations le concernant.</Typography>
						</ListItemText>
					</OptionBox>

					<OptionBox onClick={() => handleClickOption(2)}>
						<Iconify icon="solar:link-round-angle-line-duotone" width="51px" />
						<ListItemText sx={{marginLeft: '16px'}}>
							<Typography sx={{fontWeight: 600}}>Création automatique</Typography>
							<Typography sx={{fontSize: '0.875rem', color: 'grey.600'}}>Un email sera envoyé au futur membre afin qu'il complète son profil.</Typography>
						</ListItemText>
					</OptionBox>
				</Stack>
			</DialogContent>
		</Dialog>
	)
}

function CreateMemberManuallyDialog(props: { open: boolean, onClose: () => void }) {
	const { onClose, open } = props;

	const [birthDateValue, setBirthDateValue] = useState<Date | null>(null);
	const [memberSinceValue, setMemberSinceValue] = useState<Date | null>(null);
	const [addressAutocompleteOptions, setAddressAutocompleteOptions] = useState([]);
	const [showSpinnerBackdrop, setShowSpinnerBackdrop] = useState(false);
	const previousController = useRef();

	const handleClose = () => {
		onClose();
	}

	const addressAutocomplete = async (address: string) => {
		const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${address}&&autocomplete=1`);
		const data = await response.json();
		console.log(data);

		return data;
	}

	const getData = (searchTerm: string) => {		
		const controller = new AbortController();
		const signal = controller.signal;
		previousController.current = controller;

		if (searchTerm.length < 3) {
			setAddressAutocompleteOptions([]);
			return;
		}

		fetch(`https://api-adresse.data.gouv.fr/search/?q=${searchTerm}&autocomplete=1`, {
			signal,
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json"
			}
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (myJson) {
				const updatedOptions = myJson.features.map((adr) => {
				return {
					label: adr.properties.label,
					street: adr.properties.name,
					city: adr.properties.city,
					zipCode: adr.properties.postcode,
				};
			});
			setAddressAutocompleteOptions(updatedOptions);
		})
		.catch(function (error) {
			console.error(error);
		});
	};

	const getOptionLabel = useCallback(
		(option: PlaceType) => (typeof option === 'string' ? option : option.label),
		[]
	);
	  
	const addressAutocompleteChange = (event, value, reason) => {
		if (value) {
			getData(value);
		} else {
			setAddressAutocompleteOptions([]);
		}
	};

	const handleCreate = () => {
		setShowSpinnerBackdrop(true);
		const firstName = document.getElementById('firstName').value;
		const lastName = document.getElementById('lastName').value;
		
		if (!firstName || !lastName) {
			enqueueSnackbar("Veuillez entrer un prénom et un nom.", { variant: 'warning' });
			return;
		}

		const instrumentId = instruments.find((instrument) => instrument.name === document.getElementById('instrumentId')?.textContent).id;
		const gender = document.getElementById('gender')?.textContent === 'Homme' ? 1 : 2;
		const shirtSize = document.getElementById('shirtSize')?.textContent;

		const data = {
			firstName: capitalCaseTransform(firstName),
			lastName: lastName.toUpperCase(),
			address: addressAutocompleteOptions[0].street,
			addressCity: addressAutocompleteOptions[0].city,
			addressZipCode: addressAutocompleteOptions[0].zipCode,
			birthDate: formatToTimeZone(birthDateValue, 'YYYY-MM-DD', { timeZone: 'Europe/Paris' }),
			memberSince: formatToTimeZone(memberSinceValue, 'YYYY-MM-DD', { timeZone: 'Europe/Paris' }),
			email: document.getElementById('email').value,
			emergencyContact: document.getElementById('emergencyContact').value,
			instrumentId: instrumentId,
			instrumentReference: document.getElementById('instrumentReference').value,
			otherEmails: document.getElementById('otherEmails').value,
			phone: document.getElementById('phone').value,
			shirtSize: shirtSize,
			gender: gender,
		}

		createMemberByAdmin(data).then((response) => {
			enqueueSnackbar("Le membre a bien été créé.", { variant: 'success' });
			handleClose();
			setShowSpinnerBackdrop(false);
			return;
		}).catch((request) => {
			enqueueSnackbar("Une erreur est survenue lors de la création du membre.\n(" + request.response.data.error + ")", { variant: 'error' });
			setShowSpinnerBackdrop(false);
		});
	}

	return (
		<>
			<Dialog
				onClose={handleClose}
				open={open}
			>
				<DialogTitle>Création d'un membre</DialogTitle>
				<DialogContent>
					<Typography>
						Vous pouvez créer un membre en entrant manuellement toutes les informations le concernant.
					</Typography>

					<Stack direction="column" spacing={2} sx={{ marginTop: '20px' }}>
						<Stack direction="row" spacing={2}>
							<TextField
								label="Prénom"
								name="firstName"
								type="text"
								id="firstName"
								variant="outlined"
								fullWidth
							/>

							<TextField
								label="Nom"
								name="lastName"
								type="text"
								id="lastName"
								variant="outlined"
								fullWidth
							/>
						</Stack>

						<Stack direction="row" spacing={2}>
							<FormControl fullWidth>
								<InputLabel id="gender-label">Genre</InputLabel>
								<Select
									id="gender"
									labelId="gender-label"
									label="Genre"
								>
									<MenuItem value="1">Homme</MenuItem>
									<MenuItem value="2">Femme</MenuItem>
								</Select>
							</FormControl>

							<FormControl fullWidth>
								<InputLabel id="shirtSize-label">Taille T-Shirt</InputLabel>
								<Select
									id="shirtSize"
									labelId="shirtSize-label"
									label="Taille T-Shirt"
								>
									<MenuItem value="XSS">XXS</MenuItem>
									<MenuItem value="XS">XS</MenuItem>
									<MenuItem value="S">S</MenuItem>
									<MenuItem value="M">M</MenuItem>
									<MenuItem value="L">L</MenuItem>
									<MenuItem value="XL">XL</MenuItem>
									<MenuItem value="XXL">XXL</MenuItem>
									<MenuItem value="3XL">3XL</MenuItem>
									<MenuItem value="4XL">4XL</MenuItem>
									<MenuItem value="5XL">5XL</MenuItem>
								</Select>
							</FormControl>
						</Stack>

						<Stack direction="row" spacing={2}>
							<TextField
								label="Adresse email"
								name="email"
								type="text"
								id="email"
								variant="outlined"
								fullWidth
							/>

							<TextField
								label="Téléphone"
								name="phone"
								type="text"
								id="phone"
								variant="outlined"
								fullWidth
							/>
						</Stack>

						<Autocomplete
							id="address"
							autoComplete
							filterOptions={(x) => x}
							filterSelectedOptions
							getOptionLabel={getOptionLabel}
							isOptionEqualToValue={(option, value) => option.label === value.label}
							onInputChange={addressAutocompleteChange}
							options={addressAutocompleteOptions}
							renderInput={(params) => 
								<TextField 
									{...params} 
									label="Adresse"
								/>}
							noOptionsText="Aucune adresse trouvée"
							autoHighlight={true}
						>
						</Autocomplete>

						<Stack direction="row" spacing={2}>
							<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
								<DatePicker
									label="Date de naissance"
									disableFuture
									id="birthDate"
									openTo="year"
									views={['year', 'month', 'day']}
									onChange={(newValue) => {
										setBirthDateValue(newValue);
									}}
									sx={{ width: '100%' }}
								/>
							</LocalizationProvider>

							<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
								<DatePicker
									label="Membre depuis ?"
									disableFuture
									id="memberSince"
									openTo="year"
									views={['year', 'month', 'day']}
									onChange={(newValue) => {
										setMemberSinceValue(newValue);
									}}
									sx={{ width: '100%' }}
								/>
							</LocalizationProvider>
						</Stack>

						<Stack direction="row" spacing={2}>
							<FormControl fullWidth>
								<InputLabel id="instrumentId-label">Pupitre</InputLabel>
								<Select
									id="instrumentId"
									labelId="instrumentId-label"
									label="Pupitre"
								>
									{instruments.map(RenderForCreation)}
								</Select>
							</FormControl>

							<TextField
								label="Référence instrument"
								name="instrumentReference"
								type="text"
								id="instrumentReference"
								fullWidth
							/>
						</Stack>

						<TextField
							label="Autres emails"
							name="otherEmails"
							type="text"
							id="otherEmails"
							fullWidth
							multiline
							rows={3}
						/>

						<TextField
							label="Contact d'urgence"
							name="emergencyContact"
							type="text"
							id="emergencyContact"
							fullWidth
							multiline
							rows={3}
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Annuler</Button>
					<Button variant="contained" color="primary" onClick={handleCreate}>
						Créer le membre
					</Button>
				</DialogActions>
			</Dialog>
			<Backdrop
				sx={{ color: '#fff', zIndex: 2000 }}
				open={showSpinnerBackdrop}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	)
}


function CreateMemberInvitationDialog(props: { open: boolean, onClose: () => void }) {
	const { onClose, open } = props;

	const handleClose = () => {
		onClose();
	}

	const handleEmail = () => {
		const email = document.getElementById('email-automatic-creation').value;
		if (!email) {
			enqueueSnackbar("Veuillez entrer une adresse email.", { variant: 'warning' });
			return;
		}


		sendMemberCreationInvite(email).then((response) => {
			if (response.status === "success") {
				enqueueSnackbar("L'invitation a bien été envoyée.", { variant: 'success' });
				handleClose();
			}
		}).catch((request) => {
			enqueueSnackbar("Une erreur est survenue lors de l'envoi de l'invitation.\n(" + request.response.data.error + ")", { variant: 'error' });
		});
	}

	return (
		<Dialog
			onClose={handleClose}
			open={open}
		>
			<DialogTitle>Création automatique</DialogTitle>
			<DialogContent>
				<Typography>
					Vous pouvez créer un membre en entrant son adresse email. Un email lui sera envoyé afin qu'il puisse compléter son profil de lui même.
				</Typography>

				<Stack direction="column" spacing={2} sx={{ marginTop: '20px' }}>
					<TextField
						label="Adresse email"
						name="email"
						type="text"
						id="email-automatic-creation"
						variant="outlined"
						fullWidth
					/>

					<Button variant="contained" color="primary" fullWidth onClick={handleEmail}>
						Envoyer l'invitation
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	)
}


// --- 

function Members() {
	const { data, error, isLoading } = useSWR('/members', fetcher);
	const [order, setOrder] = useState<Order>('asc');
	const [orderBy, setOrderBy] = useState<keyof Data[]>(['lastName', 'firstName']);
	const [selected, setSelected] = useState<readonly string[]>([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [instrumentIdFilter, setInstrumentIdFilter] = useState<string[]>([]);
	const [filterName, setFilterName] = useState('');
	const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);
	const [openPrintDialog, setOpenPrintDialog] = useState(false);
	const [openCreateMemberManuallyDialog, setOpenCreateMemberManuallyDialog] = useState(false);
	const [openCreateMemberInvitationDialog, setOpenCreateMemberInvitationDialog] = useState(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	let rows = [];
	if (!isLoading && data) {
		rows = data.sort(
				(a, b) => (a.last_name + ' ' + a.first_name).localeCompare(b.last_name + ' ' + b.first_name)
			).map((member) => ({
				id: member.id,
				uuid: member.uuid,
				firstName: member.first_name,
				lastName: member.last_name,
				gender: member.gender,
				email: member.email,
				phone: member.phone,
				birthday: member.birthday,
				shirtSize: member.shirt_size,
				cotisationPaid: member.cotisation_paid,
				address: member.address,
				zipCode: member.zip,
				city: member.city,
				memberSince: member.member_since,
				instrumentId: member.instrument_id,
				instrumentName: instruments.find((instrument) => instrument.id === member.instrument_id).name,
				created: member.created_at,
				updated: member.updated_at,
				status: member.unavailable ? 2 : 1,
		})) || [];
	}

	const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
	  setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
	  setAnchorEl(null);
	};

	const handleCloseAddMemberDialog = (method: Integer) => {
		setOpenAddMemberDialog(false);
		if (method === 1) {
			setOpenCreateMemberManuallyDialog(true);
		}

		if (method === 2) {
			setOpenCreateMemberInvitationDialog(true);
		}
	}

	const handleGlobalExport = () => {
		console.log('export');
	};

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof Data[],
	) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: readonly string[] = [];
	
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
	
		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleViewMember = (uuid: string) => {
		window.location.href = '/members/' + uuid;
	}

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFilterByName = (event) => {
		setPage(0);
		setFilterName(event.target.value);
	};
	
	const handleChange = (event: SelectChangeEvent<typeof instrumentIdFilter>) => {
		const {
			target: { value },
		} = event;
		setInstrumentIdFilter(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
	};

	let state1 = 0;
	let state2 = 0;
	let state0 = 0;
	rows.forEach((row) => {
		switch (row.status) {
			case 0:
				state0++;
				break;
			case 1:
				state1++;
				break;
			case 2:
				state2++;
				break;
		}
	});

	const isSelected = (uuid: string) => selected.indexOf(uuid) !== -1;
	const filteredMembers = applySortFilter(rows, getComparator(order, orderBy), filterName);

	const isNotFound = !filteredMembers.length && !!filterName;

	return (
		<>
			<Helmet>
				<title>Membres { TITLE }</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Box>
							<Typography variant="h4" gutterBottom>
								Membres
							</Typography>
							<Breadcrumbs separator="›" aria-label="breadcrumb">
								<Link underline="hover" color="inherit" href="/">
									Accueil
								</Link>
								<Typography color="text.primary">Membres</Typography>
							</Breadcrumbs>
						</Box>
						<Box>
							<Button 
								variant="contained"
								onClick={() => setOpenAddMemberDialog(true)}
							>
								<Iconify icon="mingcute:add-line" mr={1} /> Ajouter un membre
							</Button>
						</Box>
					</Stack>
				</HeaderTitle>

				{error && (
					<Alert severity="error" sx={{ my: 3 }}>
						<AlertTitle>Erreur d'actualisation</AlertTitle>
						Les données les plus récentes n'ont pas pu être récupérées. Les données affichées peuvent être obsolètes.
					</Alert>
				)}

				<Paper 
					elevation={0}
					rounded
				>
					<Card>
						<Tabs>
							<Tab label="Tous" iconPosition="end" icon={
								<Label className="active" color="primary" sx={{ ml: 1 }}>{rows.length}</Label>
							} />
							<Tab label="Présent" iconPosition="end" icon={
								<Label color="success" sx={{ ml: 1 }}>{state1}</Label>
							} />
							<Tab label="Absent" iconPosition="end" icon={
								<Label color="warning" sx={{ ml: 1 }}>{state2}</Label>
							} />
							<Tab label="Parti" iconPosition="end" icon={
								<Label color="error" sx={{ ml: 1 }}>{state0}</Label>
							} />
						</Tabs>
						<FilterStack justifyContent="space-between" p={2} gap={"20px"}>
							<FormControl 
								sx={{
									'@media (max-width: 900px)': {
										width: '100%',
									},
									'@media (min-width: 900px)': {
										width: '200px',
									},
								}}
							>
								<InputLabel htmlFor="pupitre">Pupitres</InputLabel>
								<Select
									variant="outlined"
									label="Pupitres"
									multiple
									id="pupitre"
									value={instrumentIdFilter}
									onChange={handleChange}
									renderValue={(selected) => selected.join(', ')}
									MenuProps={MenuProps}
								>
									{instruments.map(RenderItem)}
								</Select>
							</FormControl>
							<Stack sx={{width: '100%'}} direction="row" alignItems="center" justifyContent="space-between" gap={'16px'}>
								<TextField
									fullWidth
									id="search"
									placeholder="Rechercher..."
									variant="outlined"
									autoComplete="off"
									value={filterName}
          							onChange={handleFilterByName}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Iconify icon="eva:search-fill" />
											</InputAdornment>
										),
									}}
								/>
								<IconButton
									id="more-filter-btn"
									onClick={handleOpen}
								>
									<Iconify icon="eva:more-vertical-fill" />
								</IconButton>
								<Menu
									id="menu-more-filter-btn"
									anchorEl={anchorEl}
									open={Boolean(anchorEl)}
									onClose={handleClose}
									MenuListProps={{
										'aria-labelledby': 'menu-more-filter-btn'
									}}
								>
									<MenuItem onClick={() => {setOpenPrintDialog(true)}}>
										<Iconify icon="solar:printer-minimalistic-bold" mr={'16px'} />
										Imprimer
									</MenuItem>
									<MenuItem onClick={handleGlobalExport}>
										<Iconify icon="solar:export-bold" mr={'16px'} />
										Exporter
									</MenuItem>
								</Menu>
							</Stack>
						</FilterStack>
						{false && (<Stack
							direction="column"
							gap={2}
							sx={{
								paddingRight: '20px',
								paddingBottom: '20px',
								paddingTop: '0px',
								paddingLeft: '20px',
							}}
						>
							<Box
								sx={{
									lineHeight: '1.5',
									fontSize: '0.875rem',
									fontWeight: '400',
								}}
							>
								<strong>{ filteredMembers.length }</strong> résultat trouvé
							</Box>
							<Stack
								flexDirection={'row'}
								flexWrap={'wrap'}
								gap={2}
								flexGrow={1}
								alignItems="center"
							>
								<Paper
									sx={{
										border: '1px dashed rgba(145, 158, 171, 0.16)',
										padding: '8px',
										borderRadius: '8px',
									}}
								>
									<Stack
										direction="row"
										gap={'8px'}
									>
										<Typography sx={{ fontWeight: '600', fontSize: '0.875rem' }}>Statut : </Typography>
										<Stack>
											<Chip label="Actif" color="success" />
										</Stack>
									</Stack>
								</Paper>
								<Button color="error">
									<Iconify icon="solar:trash-bin-trash-bold" 
										sx={{
											marginRight: '8px',
											marginLeft: '-4px',
										}}
									/>
									Supprimer
								</Button>
							</Stack>
						</Stack> )}
						<TableContainer>
							<div style={{
								height: '100%',
								overflow: 'hidden'
							}}>
								<SimpleBar>
									<Table>
										<CustomTableHead
											numSelected={selected.length}
											order={order}
											orderBy={orderBy}
											onSelectAllClick={handleSelectAllClick}
											onRequestSort={handleRequestSort}
											rowCount={rows.length}
										/>
										<TableBody>
											{filteredMembers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
												const isItemSelected = isSelected(row.uuid);
												const labelId = `enhanced-table-checkbox-${index}`;
												const age = Math.abs(new Date(Date.now() - new Date(row.birthday).getTime()).getUTCFullYear() - 1970);
												const gender = row.gender == 'male' ? 'M.' : 'Mme'

												return (
													<TableRow
														hover
														onClick={(event) => handleClick(event, row.uuid)}
														aria-checked={isItemSelected}
														role="checkbox"
														tabIndex={-1}
														key={row.uuid}
														selected={isItemSelected}
														sx={{
															whiteSpace: 'nowrap',
															cursor: 'pointer',
														}}
													>
														<TableCell padding="checkbox">
															<Checkbox
																color="primary"
																checked={isItemSelected}
																inputProps={{
																	'aria-labelledby': labelId,
																}}
															/>
														</TableCell>

														<TableCell component="th" id={labelId} scope="row" 
															sx={{
																display: 'flex',
																alignItems: 'center',
																gap: '16px',
															}}
														>
															<Box
																title={`${row.lastName} ${row.firstName}`}
																sx={{
																	display: 'flex',
																}}
															>
																<Avatar
																	alt={`${row.lastName} ${row.firstName}`}
																	name={`${row.lastName} ${row.firstName}`}
																	variant='beam'
																	colors={['#CA2E55', '#00B8D9', '#22C55E', '#FFAB00', '#FF5630']}
																/>
															</Box>
															<ListItemText sx={{
																margin: 0,
																flexGrow: 1,
																flexShrink: 1,
																flexBasis: 'auto',
																minWidth: 0,
															}}>
																<Typography variant="body1" sx={{ fontWeight: 400, fontSize: '0.875rem' }}>{ row.lastName } { row.firstName }</Typography>
																<Typography variant="body2" sx={{ color: 'grey.500', fontWeight: 400, fontSize: '0.875rem' }}>
																	<Link
																		sx={{ color: 'grey.500', textDecoration: 'none' }}
																		href={'mailto:' + row.email}
																	>
																		{ row.email }
																	</Link>
																</Typography>
															</ListItemText>
														</TableCell>

														<TableCell>
															<Link
																sx={{ color: 'inherit', textDecoration: 'none' }}
																href={`https://www.google.fr/maps/place/${ row.address }, ${ row.zipCode } ${ row.city }`}
																target="_blank"
																rel="noopener"
																rel="noreferrer"
															>
																<ListItemText sx={{
																		margin: 0,
																		flexGrow: 1,
																		flexShrink: 1,
																		flexBasis: 'auto',
																		minWidth: 0,
																	}}
																>
																	<Typography variant="body1" sx={{ fontWeight: 400, fontSize: '0.875rem' }}>{ row.address }</Typography>
																	<Typography variant="body2" sx={{ color: 'grey.500', fontWeight: 400, fontSize: '0.875rem' }}>
																		{ row.zipCode }, { row.city }
																	</Typography>
																</ListItemText>
															</Link>
														</TableCell>
														
														<TableCell>
															<ListItemText sx={{
																	margin: 0,
																	flexGrow: 1,
																	flexShrink: 1,
																	flexBasis: 'auto',
																	minWidth: 0,
																}}
															>
																<Typography variant="body1" sx={{ fontWeight: 400, fontSize: '0.875rem' }}>
																	{ new Date(row.birthday).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }) }
																</Typography>
																<Typography variant="body2" sx={{ color: 'grey.500', fontWeight: 400, fontSize: '0.875rem' }}>
																	{ age } ans
																</Typography>
															</ListItemText>
														</TableCell>

														<TableCell>
															<Link
																sx={{ textDecoration: 'none', color: 'grey.800' }}
																href={'tel:' + row.phone}
															>
																{row.phone}
															</Link>
														</TableCell>

														<TableCell>
															<Label>
																{row.instrumentName}
															</Label>
														</TableCell>

														<TableCell>
															<Label
																color={row.status === 1 ? 'success' : row.status === 2 ? 'warning' : 'error'}
															>
																{row.status === 1 ? 'Présent' : row.status === 2 ? 'Absent' : 'Parti'}
															</Label>
														</TableCell>

														<TableCell align='right' sx={{
															paddingTop: '16px',
															paddingBottom: '16px',
															paddingLeft: '8px',
															paddingRight: '8px',
														}}>
															<Tooltip title="Voir en détail">
																<IconButton size="small" onClick={() => handleViewMember(row.uuid)}>
																	<Iconify icon="solar:eye-bold" />
																</IconButton>
															</Tooltip>
															<Tooltip title="Modifier">
																<IconButton size="small" onClick={() => handleEditPerson(row.uuid)}>
																	<Iconify icon="solar:pen-bold" />
																</IconButton>
															</Tooltip>
															<IconButton size="small" onClick={() => handleMorePerson(row.uuid)}>
																<Iconify icon="eva:more-vertical-fill" />
															</IconButton>											
														</TableCell>

													</TableRow>
												);
											})}
										</TableBody>

										{isNotFound && (
											<TableBody>
												<TableRow>
												<TableCell align="center" colSpan={8} sx={{ py: 3 }}>
													<Paper
													sx={{
														textAlign: 'center',
													}}
													>
													<Typography variant="h6" paragraph>
														Aucun résultat
													</Typography>

													<Typography variant="body2">
														Aucun résultat trouvé pour &nbsp;
														<strong>&quot;{filterName}&quot;</strong>.
														<br /> Essayez de vérifier les fautes d'orthographe ou de vérifier les filtres.
													</Typography>
													</Paper>
												</TableCell>
												</TableRow>
											</TableBody>
										)}

										{!isNotFound && !rows.length && (
											<TableBody>
												<TableRow>
													<TableCell align="center" colSpan={8} sx={{ py: 3 }}>
														<Paper
															sx={{
																textAlign: 'center',
															}}
														>
															<Typography variant="h6" paragraph>
																Aucune donnée
															</Typography>
														</Paper>
													</TableCell>
												</TableRow>
											</TableBody>
										)}
									</Table>
								</SimpleBar>
							</div>
						</TableContainer>
						<Box>
							<TablePagination 
								rowsPerPageOptions={[10, 25, 50, 100]}
								component="div"
								count={filteredMembers.length}
								rowsPerPage={rowsPerPage}
								page={page}
								labelRowsPerPage="Lignes par page :"
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								labelDisplayedRows={({ from, to, count }) => { 
									return `Lignes ${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`; 
								}}
							/>
						</Box>
					</Card>
				</Paper>
			</Container>

			<AddMemberDialog
				open={openAddMemberDialog}
				onClose={handleCloseAddMemberDialog}
			/>

			<PrintDialog
				open={openPrintDialog}
				onClose={() => setOpenPrintDialog(false)}
			/>

			<CreateMemberManuallyDialog
				open={openCreateMemberManuallyDialog}
				onClose={() => setOpenCreateMemberManuallyDialog(false)}
			/>

			<CreateMemberInvitationDialog
				open={openCreateMemberInvitationDialog}
				onClose={() => setOpenCreateMemberInvitationDialog(false)}
			/>
		</>
	);
}

export default Members;