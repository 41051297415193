const errorMessages = {
	'E_ACCOUNT_DISABLED': 'Votre compte est désactivé.',
	'E_INVALID_PHONE_NUMBER': 'Numéro de téléphone invalide.',
	'E_MEMBER_NOT_FOUND': 'Membre introuvable.',
	'E_MEMBER_ALREADY_EXISTS': 'Membre déjà existant.',
	'E_USER_NOT_FOUND': 'Utilisateur introuvable.',
	'E_INVALID_AUTH_PASSWORD': 'Mot de passe invalide.',
	'E_ACCOUNT_EMAIL_NOT_VERIFIED': 'Votre adresse email n\'a pas été vérifiée.',
	'E_INVALID_EMAIL_VERIFICATION_TOKEN': 'Jeton de vérification invalide.',
	'E_EMAIL_ALREADY_VERIFIED': 'Adresse email déjà vérifiée.',
	'E_PASSWORD_NOT_PROVIDED': 'Mot de passe non fourni.',
	'E_INVALID_PASSWORD_RESET_TOKEN': 'Jeton de réinitialisation de mot de passe invalide.',
} as { [key: string]: string }

function retrieveErrorMessages(errorCode: string, errorMessage: string, fallbackErrorMessage = null as string | null) {
	if (errorMessages[errorCode]) {
		return errorMessages[errorCode]
	} else if (fallbackErrorMessage) {
		return fallbackErrorMessage
	} else {
		if (errorCode) {
			return `Une erreur est survenue. (${errorCode})`
		} else {
			return `Une erreur inconnue est survenue.`
		}
	}
}

export { retrieveErrorMessages };