import * as yup from 'yup';

export const MemberCreationValidationSchema = yup.object().shape({
	phone: yup
		.string()
		.matches(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gmi, 'Merci de renseigner un numéro de téléphone valide (ex : +33 6 12 34 56 78)')
		.required('Merci de renseigner un numéro de téléphone'),
	firstName: yup
		.string()
		.required('Merci de renseigner un prénom'),
	lastName: yup
		.string()
		.required('Merci de renseigner un nom de famille'),
	gender: yup
		.string()
		.required('Merci de renseigner un genre'),
	// birthDate: yup
	// 	.date()
	// 	.typeError('Merci de renseigner une date de naissance valide')
	// 	.max(new Date(), 'Merci de renseigner une date de naissance valide')
	// 	.required('Merci de renseigner une date de naissance'),
	instrumentId: yup
		.number()
		.typeError('Merci de correctement sélectionner un instrument')
		.required('Merci de sélectionner un instrument'),
	shirtSize: yup
		.string()
		.typeError('Merci de correctement sélectionner une taille de t-shirt')
		.required('Merci de sélectionner une taille de t-shirt'),
	instrumentReference: yup
		.string()
		.required('Merci de renseigner la référence de votre/vos instrument'),
	address: yup
		.string()
		.required('Merci de renseigner une adresse'),
});

export const LoginValidationSchema = yup.object().shape({
	email: yup
		.string()
		.email('L\'email doit être valide !')
		.required('Merci de renseigner un email'),
	password: yup
		.string()
		.required('Merci de renseigner un mot de passe'),
	rememberMe: yup
		.boolean()
});

export const UpdatePasswordValidationSchema = yup.object().shape({
	old_password: yup
		.string()
		.required('Merci de renseigner votre ancien mot de passe'),
	password: yup
		.string()
		.required('Merci de renseigner un mot de passe'),
	confirmation_password: yup
		.string()
		.oneOf([yup.ref('password'), ''], 'Les mots de passe ne correspondent pas')
		.required('Merci de confirmer votre mot de passe'),
});

export const UpdateEmailValidationSchema = yup.object().shape({
	email: yup
		.string()
		.email('L\'email doit être valide !')
		.required('Merci de renseigner un email'),
	confirmation_email: yup
		.string()
		.oneOf([yup.ref('email'), ''], 'Les emails ne correspondent pas')
		.required('Merci de confirmer votre email'),
});