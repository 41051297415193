// @ts-nocheck
import { Avatar as MuiAvatar, Box, Breadcrumbs, Card, CardContent, Checkbox, Container, Dialog, DialogTitle, Grid, IconButton, Link, List, ListItemAvatar, ListItemButton, ListItemText, Menu, MenuItem, Skeleton, Stack, Tab, Tabs, Tooltip, Typography, styled, Button, TextField, FormControlLabel, FormControl, InputLabel, Select, DialogContent, DialogActions, DialogContentText, Backdrop, CircularProgress, CardHeader, ListSubheader, Autocomplete, Divider } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Navigate, useParams } from "react-router";
import useSWR from 'swr';
import { fetcher, instance } from "../../api";
import Iconify from "../../components/iconify/Iconify";
import { MouseEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import Label from "../../components/label/Label";
import Avatar from "boring-avatars";
import { enqueueSnackbar } from "notistack";
import { TITLE } from "../../constant";
import { retrieveErrorMessages } from "../../utils/errorsDisplay";
import { formatToTimeZone } from "date-fns-timezone";
import { capitalCaseTransform } from "change-case";
import Chart, { useChart } from "../../components/chart";
import palette from "../../theme/palette";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkEmoji from 'remark-emoji'
import { is } from "date-fns/locale";
import { grey } from "@mui/material/colors";
import { set, sub } from "date-fns";

// ---

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

const instruments = (JSON.parse(localStorage.getItem('instruments')) || []).sort(
	(a, b) => a.rank < b.rank ? -1 : 1
), RenderItem = (value: any) => {
	return (
		<MenuItem key={value.id} value={value.id}>
			<Checkbox checked={instruments.indexOf(value.name) > -1} />
			<ListItemText primary={value.name} />
		</MenuItem>
	)
};

const subInstruments = (JSON.parse(localStorage.getItem('sub-instruments')) || []).sort(
	(a, b) => a.name.localeCompare(b.name)
);

const responseValues = [
	['Pas de réponse', 'grey.500', 'solar:notification-remove-broken'],
	['Participe', 'success.main', 'solar:check-circle-broken'],
	['Ne participe pas', 'error.main', 'solar:close-circle-broken'],
	['Disponible si nécessaire', 'info.main', 'solar:dialog-broken'],
	['Ne sais pas', 'warning.main', 'solar:question-circle-broken']
];

const skeletonLoadingCards = {}
// Random number between 3 and 5
let random1 = Math.floor(Math.random() * (5 - 3 + 1)) + 3;
for (let i = 1; i < random1; i++) {
	skeletonLoadingCards[i] = []
	// Random number between 3 and 7
	let random2 = Math.floor(Math.random() * (7 - 3 + 1)) + 3;
	for (let j = 0; j < random2; j++) {
		skeletonLoadingCards[i].push(j)
	}
}

// ---

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`attendance-management-details-tabpanel-${index}`}
			aria-labelledby={`attendance-management-details-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `attendance-management-details-tab-${index}`,
		'aria-controls': `attendance-management-details-tabpanel-${index}`,
	};
}

// ---

function AttendanceInformationsPanel(
	{ data, isLoading, error, isEditing, editedData, setEditedData, type, setType, isAllDay, setIsAllDay }:
		{ data: object, isLoading: boolean, error: boolean, isEditing: boolean, editedData: object, setEditedData: any, type: string, setType: any, isAllDay: boolean, setIsAllDay: any}
) {
	// Charts
	const chartRef = useRef(null);

	/**
	useEffect(() => {
		if (chartRef && chartRef.current && chartRef.current.chart) {
			const chartInstance = chartRef.current.chart;
			chartInstance.addEventListener('click', handleSeriesClick);
		}

		return () => {
			if (chartRef && chartRef.current && chartRef.current.chart) {
				const chartInstance = chartRef.current.chart;
				chartInstance.removeEventListener('click', handleSeriesClick);
			}
		};
	}, [chartRef]);

	const handleSeriesClick = (event, chartContext, config) => {
		// Récupérer les données sur la série cliquée
		const seriesIndex = config.seriesIndex;
		const dataPointIndex = config.dataPointIndex;

		console.log('Série cliquée :', seriesIndex, dataPointIndex)
		
		// Exemple de redirection vers une page différente en fonction de la série cliquée
		// Les dataPointIndex correspondent à l'index des instruments dans le tableau instruments
		for (let i = 0; i < instruments.length; i++) {
			if (dataPointIndex + 2 === instruments[i].rank) {
				setTabValue(1)
				scrollToInstrumentSection(instruments[i].id)
			}
		}

	};
	*/

	let instrumentsChart = instruments.filter((instrument) => {
		return instrument.id != 12
	});

	const chartOrder = [1, 3, 4, 2, 0]
	const chartOptions = useChart({
		colors: chartOrder.map((index) => palette[responseValues[index][1].split('.')[0]][responseValues[index][1].split('.')[1]]),
		chart: { type: 'bar', height: 350, stacked: true },
		plotOptions: { bar: { horizontal: true }, dataLabels: { hideOverflowingLabels: true } },
		legend: { show: false },
		dataLabels: { enabled: false }, // Text on bars
		xaxis: {
			categories: instrumentsChart.map((instrument) => instrument.name),
		},
	});

	let chartData = []
	if (!isLoading) {
		chartData = [
			{
				name: responseValues[1][0],
				data: instrumentsChart.map((instrument) => {
					return data.byInstrument[instrument.id]?.[1] || null;
				}),
			},
			{
				name: responseValues[3][0],
				data: instrumentsChart.map((instrument) => {
					return data.byInstrument[instrument.id]?.[3] || null;
				}),
			},
			{
				name: responseValues[4][0],
				data: instrumentsChart.map((instrument) => {
					return data.byInstrument[instrument.id]?.[4] || null;
				}),
			},
			{
				name: responseValues[2][0],
				data: instrumentsChart.map((instrument) => {
					return data.byInstrument[instrument.id]?.[2] || null;
				}),
			},
			{
				name: responseValues[0][0],
				data: instrumentsChart.map((instrument) => {
					return data.byInstrument[instrument.id]?.[0] || null;
				}),
			}
		];
	}

	return (
		<Grid container spacing={4}>
			<Grid item xs={12} md={9}>
				<Card sx={{ p: 2 }}>
					<Stack direction="column" gap={2}>
						<Box>
							<Typography variant="h6" sx={{fontWeight: 600, fontSize: '1.125rem'}}>Description</Typography>
							{!isEditing ? (<Typography variant="body1" sx={{ fontSize: '0.875rem', whiteSpace: 'break-spaces' }}>{isLoading ? (<Skeleton variant="rounded" width={450} height={110} />) : data.attendance.description ?
								(<Markdown
									remarkPlugins={[[remarkGfm], [remarkEmoji, { emoticon: true, accessible: true }]]}
									skipHtml
									className={'markdownReact'}
								>
									{ data.attendance.description }
								</Markdown>) : 'Aucune description'}</Typography>)
								: (
									<TextField
										id="description"
										variant="outlined"
										fullWidth
										multiline
										maxRows={15}
										placeholder="Entrez une description (Markdown autorisé)"
										defaultValue={data.attendance.description ?? ''}
										onChange={(e) => setEditedData({ ...editedData, description: e.target.value })}
									/>
								)
							}
						</Box>
						<Box>
							<Typography variant="h6" sx={{fontWeight: 600, fontSize: '1.125rem'}}>Programme</Typography>
							{!isEditing ? (<Typography variant="body1" sx={{ fontSize: '0.875rem', whiteSpace: 'break-spaces' }}>{isLoading ? (<Skeleton variant="rounded" width={380} height={110} />) : data.attendance.program ?
								(<Markdown
									remarkPlugins={[[remarkGfm], [remarkEmoji, { emoticon: true, accessible: true }]]}
									skipHtml
									className={'markdownReact'}
								>
									{ data.attendance.program }
								</Markdown>) : 'Aucun programme'
							}</Typography>) : (
									<TextField
										id="program"
										variant="outlined"
										fullWidth
										multiline
										maxRows={15}
										placeholder="Renseigner le programme (Markdown autorisé)"
										defaultValue={data.attendance.program ?? ''}
										onChange={(e) => setEditedData({ ...editedData, program: e.target.value })}
									/>
							)}
						</Box>
						<Box>
							<Typography variant="h6" sx={{fontWeight: 600, fontSize: '1.125rem', display: 'flex' }}>
								Notes
								<Tooltip title="Seulement visible par le bureau" placement="right-end">
									<Iconify icon="eva:eye-off-fill" sx={{ml: 1, width: '20px', height: '30px'}} />
								</Tooltip>
							</Typography>
							{!isEditing ? (<Typography variant="body1" sx={{fontSize: '0.875rem', whiteSpace: 'break-spaces'}}>{isLoading ? (<Skeleton variant="rounded" width={420} height={110} />) : data.attendance.notes ?? 'Aucune note'}</Typography>) : (
								<TextField
									id="notes"
									variant="outlined"
									fullWidth
									multiline
									rows={4}
									placeholder="Entrez des notes (seulement visibles par le bureau)"
									defaultValue={data.attendance.notes ?? ''}
									onChange={(e) => setEditedData({ ...editedData, notes: e.target.value })}
								/>
							)}
						</Box>
						<Typography variant="body2" color="text.secondary" sx={{fontSize: '0.650rem', fontStyle: 'italic'}}>Dernière modification le {isLoading ? (<Skeleton variant="text" width={150} height={24} />) : new Date(data.attendance.updated_at).toLocaleDateString('fr-FR', {
							weekday: 'long',
							year: 'numeric',
							month: 'long',
							day: 'numeric',
							hour: 'numeric',
							minute: 'numeric',
						})}</Typography>
					</Stack>
				</Card>

				<Card sx={{ p: 2, mt: 2 }}>
					{isLoading ? (<Skeleton variant="rectangular" width={'100%'} height={350} sx={{ borderRadius: '9px' }} />) : <Chart options={chartOptions} series={chartData} type="bar" height={350} ref={chartRef} />}
				</Card>
			</Grid>
			<Grid item xs={12} md={3}>
				<Card sx={{ p: 2, mb: 2 }}>
					<Stack direction="column" gap={2}>
						<Stack direction="row" gap={2}>
							<Iconify icon="solar:calendar-date-bold" />
							<ListItemText sx={{mt: 0}}>
								<Typography variant="body1" color="text.secondary" sx={{mb: '4px', fontSize: ' 0.875rem'}}>Date</Typography>
								{!isEditing ? (<Typography variant="body2" color="text.primary" sx={{fontWeight: 600, fontSize: '0.875rem'}}>{isLoading ? (<Skeleton variant="text" width={150} height={24} />) : capitalCaseTransform(new Date(data.attendance.date).toLocaleDateString('fr-FR', {
									weekday: 'long',
									year: 'numeric',
									month: 'long',
									day: 'numeric'
								}))}</Typography>) : (
									<TextField
										id="date"
										variant="outlined"
										fullWidth
										type="date"
										defaultValue={formatToTimeZone(new Date(data.attendance.date), 'YYYY-MM-DD', { timeZone: 'Europe/Paris' })}
										onChange={(e) => setEditedData({ ...editedData, date: e.target.value })}	
									/>
								)}
							</ListItemText>
						</Stack>
						<Stack direction="row" gap={2}>
							<Iconify icon="solar:clock-circle-bold" />
							<ListItemText sx={{mt: 0}}>
								<Typography variant="body1" color="text.secondary" sx={{mb: '4px', fontSize: ' 0.875rem'}}>Heure</Typography>
								{!isEditing ? (<Typography variant="body2" color="text.primary" sx={{fontWeight: 600, fontSize: '0.875rem'}}>{isLoading ? (<Skeleton variant="text" width={150} height={24} />) : data.attendance.all_day ? 'Toute la journée' : data.attendance.start_time.replace(':', 'h').split(':')[0] + ' - ' + data.attendance.end_time.replace(':', 'h').split(':')[0] }</Typography>) : (
									<Stack direction="row" gap={2}>
										<TextField
											id="start_time"
											variant="outlined"
											fullWidth
											type="time"
											disabled={isAllDay}
											defaultValue={data.attendance.start_time}
											onChange={(e) => setEditedData({ ...editedData, start_time: e.target.value })}
										/>
										<TextField
											id="end_time"
											variant="outlined"
											fullWidth
											type="time"
											disabled={isAllDay}
											defaultValue={data.attendance.end_time}
											onChange={(e) => setEditedData({ ...editedData, end_time: e.target.value })}
										/>
									</Stack>
								)}
							</ListItemText>
						</Stack>
						<Stack direction="row" gap={2}>
							<Iconify icon="solar:map-point-bold" />
							<ListItemText sx={{mt: 0}}>
								<Typography variant="body1" color="text.secondary" sx={{mb: '4px', fontSize: ' 0.875rem'}}>Lieu</Typography>
								{!isEditing ? (<Typography variant="body2" color="text.primary" sx={{fontWeight: 600, fontSize: '0.875rem'}}>{isLoading ? (<Skeleton variant="text" width={150} height={24} />) : data.attendance.location}</Typography>) : (
									<TextField
										id="location"
										variant="outlined"
										fullWidth
										placeholder="Entrez un lieu"
										defaultValue={data.attendance.location}
										onChange={(e) => setEditedData({ ...editedData, location: e.target.value })}
									/>
								)}
							</ListItemText>
						</Stack>
						{isEditing ? null : isLoading ? null : Boolean(data.attendance.locked) && <Stack direction="row" gap={2} color="error.main">
							<Iconify icon="eva:lock-fill" />
							<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem'}}>Verrouillé</Typography>
						</Stack>}
						{isEditing ? null : isLoading ? null : Boolean(data.attendance.cancelled) && <Stack direction="row" gap={2} color="error.main">
							<Iconify icon="eva:backspace-fill" />
							<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem'}}>Annulé</Typography>
						</Stack>}

						{isEditing && (
							<Stack direction="row" gap={2}>
								<Iconify icon="solar:bookmark-bold" />
								<FormControl fullWidth variant="outlined">
									<InputLabel id="type-select-label">Type de présence</InputLabel>
									<Select
										labelId="type-select-label"
										id="type-select"
										label="Type de présence"
										value={type}
										defaultValue={data.attendance.type}
										onChange={(e) => {
											setType(e.target.value)
											setEditedData({ ...editedData, type: e.target.value })
										}}
									>
										<MenuItem key={0} value={'vie-asso'}>
											Vie de l'Orchestre
										</MenuItem>
										<MenuItem key={1} value={'prestation'}>
											Prestation
										</MenuItem>
										<MenuItem key={1} value={'repetition'}>
											Répétition
										</MenuItem>
										<MenuItem key={1} value={'autre'}>
											Autre
										</MenuItem>
									</Select>
								</FormControl>
							</Stack>
						)}

						{isEditing && (
							<Stack direction="column" gap={1} sx={{mt: 1}}>
								<FormControlLabel
									control={<Checkbox id="all_day" defaultChecked={data.attendance.all_day} />}
									label="Toute la journée"
									onChange={(e) => {
										setIsAllDay(e.target.checked)
										setEditedData({ ...editedData, all_day: e.target.checked })
									}}
								/>
								<FormControlLabel
									control={<Checkbox id="locked" defaultChecked={data.attendance.locked} />}
									label="Verrouillé"
									onChange={(e) => setEditedData({ ...editedData, locked: e.target.checked })}
								/>
								<FormControlLabel
									control={<Checkbox id="cancelled" defaultChecked={data.attendance.cancelled} />}
									label="Annulé"
									onChange={(e) => setEditedData({ ...editedData, cancelled: e.target.checked })}
								/>
							</Stack>
						)}
					</Stack>
				</Card>
				<Card sx={{ p: 2, mb: 2 }}>
					<Stack direction="column" gap={2}>
						<Tooltip title="Présents" color="success.main" placement="left-start">
							<Stack direction="row" gap={2}>
								<Iconify icon="solar:check-circle-broken" />
								<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem', display: 'flex'}}>{isLoading ? (<Skeleton variant="text" width={50} height={24} sx={{marginRight:1}} />) : data.response.numAttending > 0 ? data.response.numAttending : 'Aucun' } musicien{!isLoading && data.response.numAttending > 1 ? 's' : '' }</Typography>
							</Stack>
						</Tooltip>
						<Tooltip title="Disponible si nécessaire" color="info.main" placement="left-start">
							<Stack direction="row" gap={2}>
								<Iconify icon="solar:dialog-broken" />
								<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem', display: 'flex'}}>{isLoading ? (<Skeleton variant="text" width={50} height={24} sx={{marginRight:1}} />) : data.response.numOnlyIfNeeded > 0 ? data.response.numOnlyIfNeeded : 'Aucun' } musicien{!isLoading && data.response.numOnlyIfNeeded > 1 ? 's' : '' }</Typography>
							</Stack>
						</Tooltip>
						<Tooltip title="Ne participe pas" color="error.main" placement="left-start">
							<Stack direction="row" gap={2}>
								<Iconify icon="solar:close-circle-broken" />
								<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem', display: 'flex'}}>{isLoading ? (<Skeleton variant="text" width={50} height={24} sx={{marginRight:1}} />) : data.response.numNotAttending > 0 ? data.response.numNotAttending : 'Aucun' } musicien{!isLoading && data.response.numNotAttending > 1 ? 's' : '' }</Typography>
							</Stack>
						</Tooltip>
						<Tooltip title="Ne sais pas" placement="left-start">
							<Stack direction="row" gap={2} color="warning.main">
								<Iconify icon="solar:question-circle-broken" />
								<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem', display: 'flex'}}>{isLoading ? (<Skeleton variant="text" width={50} height={24} sx={{marginRight:1}} />) : data.response.numNotSure > 0 ? data.response.numNotSure : 'Aucun' } musicien{!isLoading && data.response.numNotSure > 1 ? 's' : '' }</Typography>
							</Stack>
						</Tooltip>
						<Tooltip title="Pas de réponse" color="grey.600" placement="left-start">
							<Stack direction="row" gap={2}>
								<Iconify icon="solar:notification-remove-broken" />
								<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem', display: 'flex'}}>{isLoading ? (<Skeleton variant="text" width={50} height={24} sx={{marginRight:1}} />) : data.response.numNoResponse > 0 ? data.response.numNoResponse : 'Aucun' } musicien{!isLoading && data.response.numNoResponse > 1 ? 's' : '' }</Typography>
							</Stack>
						</Tooltip>
						<Tooltip title="Total" placement="left-start">
							<Stack direction="row" gap={2}>
								<Iconify icon="solar:slash-circle-broken" />
								<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem', display: 'flex'}}>{isLoading ? (<Skeleton variant="text" width={50} height={24} sx={{marginRight:1}} />) : data.response.totalResponses + ' / ' + data.response.totalMembers } réponses</Typography>
							</Stack>
						</Tooltip>
					</Stack>
				</Card>
			</Grid>
		</Grid>
	)
}

function AttendanceResponsePanel({ data, isLoading, error, uuid }: { data: object, isLoading: boolean, error: boolean, uuid: string }) {
	const [changePresenceOfMemberOpened, setChangePresenceOfMemberOpened] = useState(false);
	const [response, setResponse] = useState(0);
	const [memberUuid, setMemberUuid] = useState('');
	const [showSpinnerBackdrop, setShowSpinnerBackdrop] = useState(false);

	const handleClose = () => {
		setAnchorEl(null);
		setChangePresenceOfMemberOpened(false);
	};

	const openChangePresenceOfMember = (selectedMemberUuid: string, response: number) => (event: SyntheticEvent) => {
		setResponse(response)
		setMemberUuid(selectedMemberUuid)
		setChangePresenceOfMemberOpened(true);
	}

	const setPresenceOfMember = (selectedMemberUuid: string, response: number) => (event: SyntheticEvent) => {
		setShowSpinnerBackdrop(true)
		instance.put('/attendance/' + uuid + '/memberUpdate', {response: response, member_uuid: selectedMemberUuid}
		).then(() => {
			enqueueSnackbar('La présence de a été mise à jour.', { variant: 'success' })
			setShowSpinnerBackdrop(false)
		}).catch((error) => {
			enqueueSnackbar(retrieveErrorMessages(error.response.data.error, error.response.data.message), { variant: 'error' })
			setShowSpinnerBackdrop(false)
		})
		setChangePresenceOfMemberOpened(false);
		Object.keys(data.instrumentDetails).forEach((instrumentNo) => {
			data.instrumentDetails[instrumentNo].forEach((member, index) => {
				if (member.uuid === selectedMemberUuid) {
					data.instrumentDetails[instrumentNo][index].response = response
				}
			})
		})
	}

	return (
		<>
			<Stack>
				{isLoading ? Object.keys(skeletonLoadingCards).map((instrumentNo) => {
					return (
						<>
							<Typography variant="h6" sx={{fontWeight: 600, fontSize: '1.125rem', marginBottom: 2}}>
								<Skeleton variant="text" width={200} height={24} />
							</Typography>
							<Grid container spacing={2} sx={{marginBottom: 5}}>
								{skeletonLoadingCards[instrumentNo].map((member, index) => {
									return (
										<Grid item xl={3} md={4} sm={6} xs={12} key={index}>
											<Card>
												<CardContent
													sx={{
														display: 'flex',
													}}
												>
													<Skeleton variant="circular" width={48} height={48} sx={{marginRight: 2}} />
													<Stack>
														<Skeleton variant="text" width={200} height={24} />
														<Skeleton variant="text" width={120} height={16} />
													</Stack>
												</CardContent>
												<Box
													sx={{
														display: 'flex',
														justifyContent: 'center',
														padding: '8px',
														backgroundColor: 'grey.500',
														color: 'white',
														cursor: 'pointer'
													}}
												>
													<Skeleton variant="text" width={150} height={24} />
												</Box>
											</Card>
										</Grid>
									)
								})}
							</Grid>
						</>
					)
				}) :
				Object.keys(data.instrumentDetails).length > 0 && 
					Object.keys(data.instrumentDetails).sort((a, b) => {
						return instruments.find((instrument) => instrument.id === parseInt(a))?.rank > instruments.find((instrument) => instrument.id === parseInt(b))?.rank ? 1 : -1
					}).map((instrumentNo) => {
						const instrument = instruments.find((instrument) => instrument.id === parseInt(instrumentNo))
						return (
							<>
								<Typography variant="h6" sx={{fontWeight: 600, fontSize: '1.125rem', marginBottom: 2}} id={'instrumentSection' + instrumentNo}>
									{instrument?.name}
									<Label color="default" sx={{ ml: 1 }}>{data.byInstrument[instrument.id][1]}</Label>
								</Typography>
								<Grid container spacing={2} sx={{marginBottom: 5}}>
								{data.instrumentDetails[instrumentNo].map((member, index) => {
									const SUB_INSTRUMENT = data.attendance.type === 'prestation' ? subInstruments.find((subInstrument) => subInstrument.id === parseInt(member.part))?.name || instrument?.name : null
									return (
										<Grid item xl={3} md={4} sm={6} xs={12} key={index}>
											<Card>
												<CardContent>
													<Link underline="none" href={'/members/' + member.uuid} sx={{
														display: 'flex',
														gap: '16px',
														color: 'inherit',
														cursor: 'pointer'
													}}>
														<Avatar name={member.name[0] + ' ' + member.name[1]} variant="beam" size={48} colors={['#CA2E55', '#00B8D9', '#22C55E', '#FFAB00', '#FF5630']} />
														<ListItemText>
															<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem'}}>{member.name[0]} {member.name[1]}</Typography>
															<Typography variant="body2" color="text.secondary" sx={{fontSize: '0.875rem'}}>{
																data.attendance.type === 'prestation' ? SUB_INSTRUMENT : instrument?.name
															}</Typography>
														</ListItemText>
													</Link>
												</CardContent>
												<Box
													onClick={openChangePresenceOfMember(member.uuid, member.response || 0)}
													sx={{
														display: 'flex',
														justifyContent: 'center',
														padding: '8px',
														backgroundColor: responseValues[member.response || 0][1],
														color: 'white',
														cursor: 'pointer'
													}}
												>
													{responseValues[member.response || 0][0]}
												</Box>
											</Card>
										</Grid>
									)
								})}								
							</Grid>
						</>)
					})
				}
			</Stack>

			<Dialog
				open={changePresenceOfMemberOpened}
				onClose={handleClose}
			>
				<DialogTitle>Changer l'état de présence</DialogTitle>
				<List sx={{ pt: 0 }}>
					{responseValues.map((responseValue, index) => {
						return (
							<ListItemButton sx={{ color: responseValue[1] }} onClick={setPresenceOfMember(memberUuid, index)}>
								<ListItemAvatar>
									<MuiAvatar sx={{
										backgroundColor: responseValue[1],
									}}>
										<Iconify icon={responseValue[2]} />
									</MuiAvatar>
								</ListItemAvatar>
								<ListItemText sx={{ color: 'black' }} primary={responseValue[0]} />
								{(index == response) && <IconButton edge="end">
									<Iconify icon="eva:checkmark-outline" />
								</IconButton>}
							</ListItemButton>
						)
					})}
				</List>
			</Dialog>

			<Backdrop
				sx={{ color: '#fff', zIndex: 2000 }}
				open={showSpinnerBackdrop}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	)
}

interface EditMemberDistributionPlaceData {
	subInstrument: {
		id: number | null,
		name: string | null
	},
	instrument: {
		id: number | null,
		name: string | null
	},
	place: number | null
	currentUser: {
		uuid: string | null
		name: string | null
	},
	newUser: {
		uuid: string | null
		name: string | null
	}
}

function AttendanceRepartitionPanel(
	{ data, isLoading, error, isEditing, distribution, setDistribution }:
		{ data: object, isLoading: boolean, error: boolean, isEditing: boolean, distribution: object, setDistribution: any }
) {
	const [addNewDistributionOpened, setAddNewDistributionOpened] = useState(false);
	const [editMemberDistributionPlaceOpened, setEditMemberDistributionPlaceOpened] = useState(false);
	const [editMemberDistributionPlaceData, setEditMemberDistributionPlaceData] = useState({
		subInstrument: {
			id: null,
			name: null
		},
		instrument: {
			id: null,
			name: null
		},
		place: null,
		currentUser: {
			uuid: null,
			name: null
		},
		newUser: {
			uuid: null,
			name: null
		}
	} as EditMemberDistributionPlaceData);
	const [editNumberDistributionOpened, setEditNumberDistributionOpened] = useState(false);
	const [editNumberDistributionSubInstrumentId, setEditNumberDistributionSubInstrumentId] = useState(null);
	const [addSubInstrumentList, setAddSubInstrumentList] = useState([]);
	const [showSpinnerBackdrop, setShowSpinnerBackdrop] = useState(false);

	const checkDistributionActive = () => {
		return data.attendance.type === 'prestation' && data.distribution !== null
	}
	let isDistributionActive = checkDistributionActive()

	const handleClose = () => {
		setAddNewDistributionOpened(false);
		setEditNumberDistributionOpened(false);
		setEditMemberDistributionPlaceOpened(false);
	}

	const handleSubmitNew = () => {
		setAddNewDistributionOpened(false);

		const nbAdded = document.getElementById('distributionNew')?.value
		if (nbAdded == '') {
			enqueueSnackbar('Veuillez renseigner un nombre de musiciens à ajouter.', { variant: 'error' })
			return;
		}

		let newDistribution = {}
		addSubInstrumentList.forEach((subInstrument) => {
			newDistribution[subInstrument.id] = parseInt(nbAdded)
		})
		setDistribution({ ...distribution, ...newDistribution })
		data.distribution = { ...distribution, ...newDistribution }
		setAddSubInstrumentList([])

		isDistributionActive = checkDistributionActive()
	}

	const handleSubmitEdit = () => {
		setEditNumberDistributionOpened(false);

		const nbEdited = document.getElementById('distributionEdit')?.value
		let newDistribution = { ...distribution }
		newDistribution[editNumberDistributionSubInstrumentId] = parseInt(nbEdited)
		setDistribution(newDistribution)
	}

	const changeMemberPresenceAndPlace = () => {
		setShowSpinnerBackdrop(true)

		if (editMemberDistributionPlaceData.currentUser.uuid == null) {
			instance.put('/attendance/' + data.attendance.uuid + '/memberUpdate', {
				response: 1,
				member_uuid: editMemberDistributionPlaceData.newUser.uuid,
				sub_instrument_id: editMemberDistributionPlaceData.subInstrument.id,
				place: editMemberDistributionPlaceData.place
			}).then(() => {
				data.instrumentDetails[editMemberDistributionPlaceData.instrument.id].find((member) => member.uuid === editMemberDistributionPlaceData.newUser.uuid).place = editMemberDistributionPlaceData.place
				data.instrumentDetails[editMemberDistributionPlaceData.instrument.id].find((member) => member.uuid === editMemberDistributionPlaceData.newUser.uuid).part = editMemberDistributionPlaceData.subInstrument.id
				data.instrumentDetails[editMemberDistributionPlaceData.instrument.id].find((member) => member.uuid === editMemberDistributionPlaceData.newUser.uuid).response = 1

				enqueueSnackbar('La présence de ' + editMemberDistributionPlaceData.newUser.name + ' a été mise à jour.', { variant: 'success' })
				setShowSpinnerBackdrop(false)
				setEditMemberDistributionPlaceOpened(false)
			}).catch((error) => {
				enqueueSnackbar(retrieveErrorMessages(error.response?.data?.error, error.response?.data?.message), { variant: 'error' })
				setShowSpinnerBackdrop(false)
				setEditMemberDistributionPlaceOpened(false)
			})
		} else if (editMemberDistributionPlaceData.newUser.uuid == null) {
			instance.put('/attendance/' + data.attendance.uuid + '/memberUpdate', {
				response: 3,
				member_uuid: editMemberDistributionPlaceData.currentUser.uuid,
				sub_instrument_id: editMemberDistributionPlaceData.subInstrument.id,
				place: null
			}).then(() => {
				enqueueSnackbar('La présence de ' + editMemberDistributionPlaceData.currentUser.name + ' a été mise à jour.', { variant: 'success' })
				setShowSpinnerBackdrop(false)
				setEditMemberDistributionPlaceOpened(false)

				data.instrumentDetails[editMemberDistributionPlaceData.instrument.id].find((member) => member.uuid === editMemberDistributionPlaceData.currentUser.uuid).response = 3
				data.instrumentDetails[editMemberDistributionPlaceData.instrument.id].find((member) => member.uuid === editMemberDistributionPlaceData.currentUser.uuid).place = null
			}).catch((error) => {
				enqueueSnackbar(retrieveErrorMessages(error.response?.data?.error, error.response?.data?.message), { variant: 'error' })
				setShowSpinnerBackdrop(false)
				setEditMemberDistributionPlaceOpened(false)
			})
		} else {
			sendRequestForDistribution(editMemberDistributionPlaceData.subInstrument.id, editMemberDistributionPlaceData.instrument.id, editMemberDistributionPlaceData.place, editMemberDistributionPlaceData.currentUser, editMemberDistributionPlaceData.newUser)
		}
	}

	const sendRequestForDistribution = (subInstrumentId: number, instrumentId: number, place: number, currentUser: object, newUser: object) => {
		instance.put('/attendance/' + data.attendance.uuid + '/memberUpdate', {
			response: 3,
			member_uuid: currentUser.uuid,
			sub_instrument_id: subInstrumentId,
			place: null
		}).then(() => {
			enqueueSnackbar('La présence de ' + currentUser.name + ' a été mise à jour.', { variant: 'success' })

			instance.put('/attendance/' + data.attendance.uuid + '/memberUpdate', {
				response: 1,
				member_uuid: newUser.uuid,
				sub_instrument_id: subInstrumentId,
				place: place
			}).then(() => {
				data.instrumentDetails[instrumentId].find((member) => member.uuid === newUser.uuid).place = place
				data.instrumentDetails[instrumentId].find((member) => member.uuid === newUser.uuid).part = subInstrumentId
				data.instrumentDetails[instrumentId].find((member) => member.uuid === newUser.uuid).response = 1
				data.instrumentDetails[instrumentId].find((member) => member.uuid === currentUser.uuid).response = 3
				data.instrumentDetails[instrumentId].find((member) => member.uuid === currentUser.uuid).place = null

				enqueueSnackbar('La présence de ' + newUser.name + ' a été mise à jour.', { variant: 'success' })
				setShowSpinnerBackdrop(false)
				setEditMemberDistributionPlaceOpened(false)
			}).catch((error) => {
				enqueueSnackbar(retrieveErrorMessages(error.response.data?.error, error.response.data?.message), { variant: 'error' })
				setShowSpinnerBackdrop(false)
				setEditMemberDistributionPlaceOpened(false)
			})
		}).catch((error) => {
			enqueueSnackbar(retrieveErrorMessages(error.response.data?.error, error.response.data?.message), { variant: 'error' })
			setShowSpinnerBackdrop(false)
			setEditMemberDistributionPlaceOpened(false)
		})
	}

	const sortedSubInstruments = subInstruments.sort((a, b) => {
		if (instruments.find((instrument) => instrument.id === a.instrumentId)?.rank > instruments.find((instrument) => instrument.id === b.instrumentId)?.rank) {
			return 1
		} else if (instruments.find((instrument) => instrument.id === a.instrumentId)?.rank < instruments.find((instrument) => instrument.id === b.instrumentId)?.rank) {
			return -1
		} else {
			return a.name.localeCompare(b.name)
		}
	})

	return (
		<>
			<Stack direction="column" gap={2}>

				<Card>
					<CardHeader title="Configuration de la répartition" />
					
					<CardContent>
						{isEditing ? (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
									paddingX: '16px',
									paddingY: '8px',
									borderRadius: '8px',
									border: '2px dashed',
									borderColor: 'grey.100',
									marginBottom: '8px',
									alignItems: 'center',
									justifyContent: 'center',
									width: '100%',
									color: 'grey.500',
									cursor: 'pointer'
								}}

								onClick={() => { setAddNewDistributionOpened(true) }}
							>
								<Typography variant="body1" sx={{ fontStyle: 'italic' }}>
									Cliquez pour ajouter une répartition
								</Typography>
							</Box>
						) : null}

						{isDistributionActive && (
							<>
								{Object.keys(distribution)
									.sort((a, b) => sortedSubInstruments.findIndex((subInstrument) => subInstrument.id === parseInt(a)) - sortedSubInstruments.findIndex((subInstrument) => subInstrument.id === parseInt(b)))
									.map((subInstrumentId, index) => {
										const subInstrumentName = subInstruments.find((subInstrument) => subInstrument.id === parseInt(subInstrumentId))?.name
										const subInstrumentDistribution = distribution[subInstrumentId]

										return (
											<Box
												key={index}
												sx={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'space-between',
													alignItems: 'center',
													paddingX: '16px',
													paddingY: '8px',
													borderRadius: '8px',
													backgroundColor: 'grey.100',
													marginBottom: '8px'
												}}
											>
												<Typography variant="body1">{subInstrumentName}</Typography>
												<Box
													sx={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														gap: '8px'
													}}
												>
													<Typography variant="body1" sx={{ fontWeight: 700 }}>{subInstrumentDistribution + ' demandé' + (subInstrumentDistribution > 1 ? 's' : '')}</Typography>
													{isEditing && (
														<>
															<IconButton
																onClick={() => {
																	setEditNumberDistributionSubInstrumentId(subInstrumentId)
																	setEditNumberDistributionOpened(true)
																}}
															>
																<Iconify icon="solar:pen-2-bold" />
															</IconButton>

															<IconButton
																color="error"
																onClick={() => {
																	const newDistribution = { ...distribution }
																	delete newDistribution[subInstrumentId]
																	setDistribution(newDistribution)
																}}
															>
																<Iconify icon="solar:trash-bin-2-bold" />
															</IconButton>
														</>
													)}
												</Box>
											</Box>
										)
								})}
							</>
						)}
						
						{!isEditing && !isDistributionActive && (
							<>
								<Typography variant="body1" sx={{ fontSize: '0.875rem' }}>Aucune répartition n'a été configurée pour cette prestation.</Typography>
								<Typography variant="body1" sx={{ fontSize: '0.875rem' }}>Pour configurer une répartition, veuillez commencer par éditer la présence.</Typography>
							</>
						)}
					</CardContent>
				</Card>

				{isDistributionActive && (
					<Card>
						<CardHeader title="Visualisation de la répartition" />
						
						<CardContent>
							<Stack direction="column" gap={2}>
								{Object.keys(distribution).map((subInstrumentId, index) => {
									const subInstrument = subInstruments.find((subInstrument) => subInstrument.id === parseInt(subInstrumentId))
									const subInstrumentName = subInstrument?.name
									const subInstrumentDistribution = distribution[subInstrumentId]
									const instrumentId = subInstrument?.instrumentId

									return (
										<Stack
											direction="column"
											key={index}
										>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'space-between',
													alignItems: 'center',
													paddingX: '16px',
													paddingY: '8px',
													borderRadius: '8px',
													backgroundColor: 'grey.100',
													marginBottom: '8px'
												}}
											>
												<Typography variant="body1" sx={{ fontWeight: 700 }}>{subInstrumentName}</Typography>
												<Box
													sx={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														gap: '8px'
													}}
												>
													<Typography variant="body1" sx={{ fontWeight: 700 }}>{subInstrumentDistribution + ' demandé' + (subInstrumentDistribution > 1 ? 's' : '')}</Typography>
												</Box>
											</Box>

											<Stack
												direction={{xs: "column", md: "row"}}
												gap={2}
											>
												{[...Array(distribution[subInstrument.id])].map((_, index) => {
													let filteredMembers = data.instrumentDetails[instrumentId].filter((member: any) => member.part == subInstrument.id);

													let displayed = false;
													if (filteredMembers) {
														let filteredMember = filteredMembers.find((member: any) => member.place == index + 1);
														if (filteredMember) {
															displayed = true;
															return (
																<Box
																	key={index}
																	sx={{
																		borderRadius: '10px',
																		border: '2px solid',
																		backgroundColor: 'grey.200',
																		width: '100%',
																		height: '40px',
																		cursor: 'pointer',
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		transition: 'all 0.2s ease-in-out',
																		":hover": {
																			border: '2px dashed',
																			borderColor: 'warning.light',
																			backgroundColor: 'white'
																		},
																		borderColor: responseValues[filteredMember.response][1],
																	}}

																	onClick={() => {
																		setEditMemberDistributionPlaceData({
																			subInstrument: {
																				id: subInstrument.id,
																				name: subInstrument.name
																			},
																			instrument: {
																				id: instrumentId,
																				name: instruments.find((instrument) => instrument.id === instrumentId)?.name
																			},
																			place: index + 1,
																			currentUser: {
																				uuid: filteredMember.uuid,
																				name: filteredMember.name[0] + ' ' + filteredMember.name[1]
																			},
																			newUser: {
																				uuid: null,
																				name: null
																			}
																		})
																		setEditMemberDistributionPlaceOpened(true)
																	}}
																>
																	<Typography variant="body1">{filteredMember.name[0] + ' ' + filteredMember.name[1]}</Typography>
																</Box>
															)
														}
													}

													if (!displayed) {
														return (
															<Box
																key={index}
																sx={{
																	borderRadius: '10px',
																	border: '2px dashed',
																	borderColor: 'error.light',
																	width: '100%',
																	height: '40px',
																	cursor: 'pointer',
																	transition: 'all 0.2s ease-in-out',
																	":hover": {
																		backgroundColor: 'grey.200',
																	},
																	display: 'flex',
																}}

																onClick={() => {
																	setEditMemberDistributionPlaceData({
																		subInstrument: {
																			id: subInstrument.id,
																			name: subInstrument.name
																		},
																		instrument: {
																			id: instrumentId,
																			name: instruments.find((instrument) => instrument.id === instrumentId)?.name
																		},
																		place: index + 1,
																		currentUser: {
																			uuid: null,
																			name: null
																		},
																		newUser: {
																			uuid: null,
																			name: null
																		}
																	})
																	setEditMemberDistributionPlaceOpened(true)
																}}
															/>
														)
													}

												})}
											</Stack>
											<Grid container spacing={1}>
												{data.instrumentDetails[instrumentId].filter((member: any) => {
													return member.part == subInstrument.id && member.response == 3
												}).map((member, index) => {
													return (
														<Grid item xs={12} sm={6} md={4} lg={3} key={subInstrument.id + '' + index + 'ifNesc'} sx={{ mt: 1 }}>
															<Tooltip
																title={responseValues[member.response][0] + ' en ' + subInstrument.name}
																placement="top"
															>
																<Box
																	sx={{
																		borderRadius: '10px',
																		border: '2px solid',
																		borderColor: responseValues[member.response][1],
																		backgroundColor: 'grey.200',
																		width: '100%',
																		height: '40px',
																		cursor: 'not-allowed',
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																	}}
																>
																	<Typography variant="body1">{member.name[0] + ' ' + member.name[1]}</Typography>
																</Box>
															</Tooltip>
														</Grid>
													)
												})}
											</Grid>
										</Stack>
									)
								})}
							</Stack>
						</CardContent>
					</Card>
				)}

			</Stack>

			<Dialog
				open={editMemberDistributionPlaceOpened}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
			>
				<DialogTitle>
					Attribuer un musicien à {editMemberDistributionPlaceData?.subInstrument.name} - Place {editMemberDistributionPlaceData?.place}
				</DialogTitle>
				
				<DialogContent>
					<Stack direction="column" gap={2} sx={{ mt: 2 }}>
						{editMemberDistributionPlaceData.currentUser.uuid && (
							<>
								<Typography variant="body1">
									Un musicien est déjà attribué à cette place.<br/>
									En changeant le musicien, il perdra sa place actuelle et sa réponse sera transformée en "Disponible si nécessaire".
								</Typography>

								<Typography variant="body1" sx={{ fontWeight: 600, fontSize: '0.875rem', color: 'grey.500' }}>
									Le musicien actuel est :
								</Typography>

								<Box
									sx={{
										borderRadius: '10px',
										border: '2px solid',
										borderColor: 'success.main',
										backgroundColor: 'grey.200',
										width: '100%',
										height: '40px',
										fontWeight: 600,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Typography variant="body1">{editMemberDistributionPlaceData.currentUser.name}</Typography>
								</Box>
								
								<Divider />
							</>
						)}
						<Autocomplete
							id="member-select"
							options={data.instrumentDetails[editMemberDistributionPlaceData.instrument.id]?.sort((a, b) => a.name.join(' ').localeCompare(b.name.join(' ')))}
							getOptionLabel={(option) => option.name[0] + ' ' + option.name[1]}
							getOptionKey={(option) => option.uuid}
							renderInput={(params) => <TextField {...params} label="Nouveau musicien" />}
							onChange={(event, value) => {
								setEditMemberDistributionPlaceData({
									...editMemberDistributionPlaceData,
									newUser: {
										uuid: value?.uuid,
										name: value?.name[0] + ' ' + value?.name[1]
									}
								})
							}}
						/>
					</Stack>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose}>Annuler</Button>
					{editMemberDistributionPlaceData.currentUser.uuid && (
						<Button onClick={changeMemberPresenceAndPlace} color="error" variant="outlined">Supprimer ancien</Button>
					)}
					<Button onClick={changeMemberPresenceAndPlace} color="primary" variant="outlined">Attribuer {editMemberDistributionPlaceData.currentUser.uuid && ("nouveau")}</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={editNumberDistributionOpened}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
			>
				<DialogTitle>Modifier le nombre de musiciens</DialogTitle>
				<DialogContent>
					<Stack direction="column" gap={2} sx={{ mt: 2 }}>
						<TextField
							id="distributionEdit"
							label="Nombre de musiciens"
							type="number"
							variant="outlined"
							fullWidth
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Annuler</Button>
					<Button onClick={handleSubmitEdit} color="primary" variant="outlined">Modifier</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={addNewDistributionOpened}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
			>
				<DialogTitle>Ajouter une répartition</DialogTitle>
				<DialogContent>
					<Stack direction="column" gap={2} sx={{ mt: 2 }}>
						<Autocomplete
							multiple
							labelId="sub-instrument-select-label"
							id="sub-instrument-select"
							label="Instrument"
							options={sortedSubInstruments}
							groupBy={(option) => instruments.find((instrument) => instrument.id === option.instrumentId)?.name}
							getOptionLabel={(option) => option.name}
							getOptionKey={(option) => option.id}
							renderInput={(params) => <TextField {...params} label="Instrument(s)" fullWidth />}
							onChange={(event, value) => setAddSubInstrumentList(value)}
							getOptionDisabled={(option) => Object.keys(distribution).includes(option.id.toString())}
						/>
						<TextField
							id="distributionNew"
							label="Nombre de musiciens"
							type="number"
							variant="outlined"
							fullWidth
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Annuler</Button>
					<Button onClick={handleSubmitNew} color="primary" variant="outlined">Ajouter</Button>
				</DialogActions>
			</Dialog>

			<Backdrop
				sx={{ color: '#fff', zIndex: 2000 }}
				open={showSpinnerBackdrop}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	)
}

function AttendanceManagementDetails() {
	const { uuid } = useParams<{ uuid: string }>();
	const { data, error, isLoading } = useSWR('/attendance-admin/' + uuid, fetcher)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isEditing, setIsEditing] = useState(false);
	const [tabValue, setTabValue] = useState(0);
	const [deleteConfirmationOpened, setDeleteConfirmationOpened] = useState(false);
	const [deleted, setDeleted] = useState(false);
	const [type, setType] = useState(null);
	const [editedData, setEditedData] = useState({
		name: null,
		description: null,
		program: null,
		notes: null,
		date: null,
		start_time: null,
		end_time: null,
		location: null,
		locked: null,
		cancelled: null
	});
	const [isAllDay, setIsAllDay] = useState(false);
	const [distribution, setDistribution] = useState({});

	useEffect(() => {
		if (!isLoading && !error) {
			resetModifications()
		}
	}, [isLoading]);

	const scrollToInstrumentSection = (id) => {
		const violation = document.getElementById('instrumentSection' + id);
		console.log('instrumentSection' + id)
		console.log(violation)
		window.scrollTo({
			top: violation.offsetTop,
			behavior: "smooth"
		});
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	const saveModifications = async () => {
		let requestDistribution = []
		Object.keys(distribution).forEach((subInstrumentId) => {
			requestDistribution.push({ sub_instrument_id: subInstrumentId, number: distribution[subInstrumentId], instrument_id: subInstruments.find((subInstrument) => subInstrument.id === parseInt(subInstrumentId)).instrumentId})
		})

		let formData = {}
		if (editedData.name != data.attendance.name) formData['name'] = editedData.name
		if (editedData.description != data.attendance.description) formData['description'] = editedData.description
		if (editedData.program != data.attendance.program) formData['program'] = editedData.program
		if (editedData.notes != data.attendance.notes) formData['notes'] = editedData.notes
		if (editedData.date != data.attendance.date) formData['date'] = editedData.date
		if (editedData.start_time != data.attendance.start_time) formData['start_time'] = editedData.start_time
		if (editedData.end_time != data.attendance.end_time) formData['end_time'] = editedData.end_time
		if (editedData.location != data.attendance.location) formData['location'] = editedData.location
		if (type != data.attendance.type) formData['type'] = type
		if (isAllDay != data.attendance.all_day) formData['all_day'] = isAllDay ? 'true' : 'false'
		if (editedData.locked != data.attendance.locked) formData['locked'] = editedData.locked ? 'true' : 'false'
		if (editedData.cancelled != data.attendance.cancelled) formData['cancelled'] = editedData.cancelled ? 'true' : 'false'
		if (distribution != data.distribution) formData['distribution'] = JSON.stringify(requestDistribution)

		await instance.put('/attendance/' + uuid, formData).then(() => {
			enqueueSnackbar('La présence a bien été modifiée.', { variant: 'success' });
			setIsEditing(false)

			if (editedData.name != data.attendance.name) data.attendance.name = editedData.name
			if (editedData.description != data.attendance.description) data.attendance.description = editedData.description == '' ? null : editedData.description
			if (editedData.program != data.attendance.program) data.attendance.program = editedData.program == '' ? null : editedData.program
			if (editedData.notes != data.attendance.notes) data.attendance.notes = editedData.notes == '' ? null : editedData.notes
			if (editedData.date != data.attendance.date) data.attendance.date = editedData.date
			if (editedData.start_time != data.attendance.start_time) data.attendance.start_time = editedData.start_time
			if (editedData.end_time != data.attendance.end_time) data.attendance.end_time = editedData.end_time
			if (editedData.location != data.attendance.location) data.attendance.location = editedData.location
			if (type != data.attendance.type) data.attendance.type = type
			if (isAllDay != data.attendance.all_day) data.attendance.all_day = isAllDay
			if (editedData.locked != data.attendance.locked) data.attendance.locked = editedData.locked
			if (editedData.cancelled != data.attendance.cancelled) data.attendance.cancelled = editedData.cancelled
			if (distribution != data.distribution) data.distribution = distribution

		}).catch((error) => {
			enqueueSnackbar(retrieveErrorMessages(error.response.data.error, error.response.data.message, "La présence n\'a pas pu être modifiée. Veuillez réessayer."), { variant: 'error' });
		});
	}

	const deleteAttendance = async () => {
		await instance.delete('/attendance/' + uuid).then(() => {
			enqueueSnackbar('La présence a bien été supprimée.', { variant: 'success' });
			setDeleteConfirmationOpened(false)
			setDeleted(true)
		}).catch((error) => {
			enqueueSnackbar(retrieveErrorMessages(error.response.data.error, error.response.data.message, "La présence n\'a pas pu être supprimée. Veuillez réessayer."), { variant: 'error' });
		});
	}

	const resetModifications = () => {
		setEditedData({
			name: data.attendance.name,
			description: data.attendance.description,
			program: data.attendance.program,
			notes: data.attendance.notes,
			date: data.attendance.date,
			start_time: data.attendance.start_time,
			end_time: data.attendance.end_time,
			location: data.attendance.location,
			locked: data.attendance.locked,
			cancelled: data.attendance.cancelled
		})
		setType(data.attendance.type)
		setIsAllDay(data.attendance.all_day)
		setDistribution(data.distribution || {})
	}

	if (deleted) return (<Navigate to="/attendance-management"/>);
	if (error) return <div>Une erreur est survenue.</div>
	return (
		<>
			<Helmet>
				<title>{isLoading ? 'Chargement...' : data.attendance.name} { TITLE }</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
						<Box>
							<Typography variant="h4" gutterBottom>
								{!isEditing ? (isLoading ? (<Skeleton variant="text" width={400} height={36} />) : data.attendance.name) : (
									<TextField
										id="name"
										variant="outlined"
										label="Nom de la présence"
										fullWidth
										placeholder="Entrez un nom"
										defaultValue={data.attendance.name}
										onChange={(e) => setEditedData({ ...editedData, name: e.target.value })}
									/>
								)}
							</Typography>
							<Breadcrumbs separator="›" aria-label="breadcrumb">
								<Link underline="hover" color="inherit" href="/">
									Accueil
								</Link>
								<Link underline="hover" color="inherit" href="/attendance">
									Présences
								</Link>
								<Link underline="hover" color="inherit" href="/attendance-management">
									Gestion
								</Link>
								<Typography color="text.primary">{isLoading ? (<Skeleton variant="text" width={150} height={24} />) : data.attendance.name}</Typography>
							</Breadcrumbs>
						</Box>
						<Box sx={{
							flexGrow: 1,
						}} />
						{!isEditing ? (<IconButton
							onClick={() => setIsEditing(!isEditing)}
						>
							<Iconify icon="solar:pen-bold" />
						</IconButton>) : (
							<>
								<Button variant="contained" onClick={saveModifications}>Enregistrer</Button>
								<Button variant="outlined" onClick={() => {
										setIsEditing(!isEditing)
										resetModifications()
								}}>Annuler</Button>
							</>
						)}
						<IconButton
							id="menu-export-btn"
							onClick={handleOpen}
						>
							<Iconify icon="solar:export-bold" />
						</IconButton>
						<Menu
							id="export-menu"
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'menu-export-btn'
							}}
						>
							<MenuItem onClick={handleClose}>
								<Iconify icon="bi:file-pdf" mr={'16px'} />
								PDF
							</MenuItem>
							<MenuItem onClick={handleClose}>
								<Iconify icon="bi:filetype-csv" mr={'16px'} />
								Tableur
							</MenuItem>
						</Menu>
						<IconButton
							color="error"
							onClick={() => setDeleteConfirmationOpened(true)}
						>
							<Iconify icon="solar:trash-bin-2-bold" />
						</IconButton>
					</Stack>
				</HeaderTitle>
				<Tabs value={tabValue} onChange={handleChange} textColor="primary" indicatorColor="primary" sx={{mb: 2}}>
					<Tab label="Informations" {...a11yProps(0)} />
					<Tab label="Réponses" {...a11yProps(1)} iconPosition="end" icon={
						// @ts-ignore
						!isLoading && (<Label color="default" sx={{ ml: 1 }}>{data.response.totalResponses}</Label>)
					} />
					{!isLoading && data.attendance.type === 'prestation' && (
						<Tab label="Répartition" {...a11yProps(2)} />
					)}
				</Tabs>

				<TabPanel value={tabValue} index={0}>
					<AttendanceInformationsPanel
						data={data}
						isLoading={isLoading}
						error={error}
						isEditing={isEditing}
						type={type}
						setType={setType}
						editedData={editedData}
						setEditedData={setEditedData}
						isAllDay={isAllDay}
						setIsAllDay={setIsAllDay}
					/>
				</TabPanel>

				<TabPanel value={tabValue} index={1}>
					<AttendanceResponsePanel
						data={data}
						isLoading={isLoading}
						error={error}
						uuid={uuid}
					/>
				</TabPanel>

				<TabPanel value={tabValue} index={2}>
					<AttendanceRepartitionPanel
						data={data}
						isLoading={isLoading}
						error={error}
						isEditing={isEditing}
						distribution={distribution}
						setDistribution={setDistribution}
					/>
				</TabPanel>

				<Dialog
					open={deleteConfirmationOpened}
					onClose={() => setDeleteConfirmationOpened(false)}
				>
					<DialogTitle>Supprimer la présence</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Êtes-vous sûr de vouloir supprimer cette présence ? Cette action est irréversible.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={() => setDeleteConfirmationOpened(false)}>Annuler</Button>
						<Button variant="contained" onClick={() => deleteAttendance()} color="error">Supprimer</Button>
					</DialogActions>
				</Dialog>
			</Container>
		</>
	)
}

export default AttendanceManagementDetails;