import { instance } from "."
import * as Sentry from "@sentry/react";

async function login(email: string, password: string, rememberMe: boolean) {
    const response = instance.post("/auth/login", {
        email: email,
        password: password,
        remember_me: rememberMe
    }).then(async (response: any) => {
        if (response.status == 200) {
            
            Sentry.setUser({
                email: email,
                id: response.data.id,
                username: response.data.first_name + " " + response.data.last_name,
                verified: response.data.email_verified_at ? true : false,
                role: response.data.role_id
            });
            
            localStorage.setItem("token", response.data.token.token)
            const token = response.data.token.token
            await instance.get('/profile/user', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(async (response: any) => {
                localStorage.setItem("user", JSON.stringify(response.data))
                if (response.data.id !== 1) {
                    await instance.get('/profile/folder', {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }).then(async (response: any) => {
                        localStorage.setItem("instrument", JSON.stringify(response.data))
                        await instance.get('/profile/member', {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                            }).then((response: any) => {
                                localStorage.setItem("member-uuid", response.data.uuid)
                            }
                        )
                    })
                }
            })

            instance.get('/instruments').then((response: any) => {
                localStorage.setItem("instruments", JSON.stringify(response.data))
            })

            instance.get('/sub-instruments').then((response: any) => {
                localStorage.setItem("sub-instruments", JSON.stringify(response.data))
            })
        }
        return response
    }).then(response => response.data)

    return response;
}

async function logout() {
    
    Sentry.setUser(null);

    instance.post("/auth/logout").then(() => {
        localStorage.clear()
        localStorage.removeItem("member-uuid")
        window.location.href = "/auth/login"
    })

    return;
}

function isLogged() {
    const token = localStorage.getItem("token")
    if (token) {
        const user = JSON.parse(localStorage.getItem("user") || '[]')

        Sentry.setUser({
            email: user.email,
            id: user.id,
            username: user.first_name + " " + user.last_name,
            verified: user.email_verified_at ? true : false,
            role: user.role_id
        });

        return true
    } else {
        return false
    }
}

function getRole() {
    const user = localStorage.getItem("user")
    if (user) {
        return JSON.parse(user).role_id as number
    } else {
        return false
    }
}

export { login, logout, isLogged, getRole }