// @ts-nocheck

import { Alert, AlertTitle, Box, Breadcrumbs, Button, Card, CardContent, Container, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Link, Skeleton, Stack, TextField, Typography, styled } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Iconify from "../../components/iconify/Iconify";
import Label from "../../components/label/Label";
import { capitalCaseTransform } from "change-case";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState } from "react";

import useSWR from 'swr';
import { fetcher } from "../../api";
import { BASE_URL, TITLE } from "../../constant";
import { enqueueSnackbar } from "notistack";


// ---

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

// ---

function Attendances() {
	const { data, error, isLoading } = useSWR('/attendance', fetcher)
	const [icalDialogOpen, setIcalDialogOpen] = useState(false);
	const loadingSkeleton = Array(5).fill(0);

	const icalUrl = (`${BASE_URL}/attendance/${data?.[1]}/ical`);

	const attendances = data?.[0].map((attendance) => {
		let responseText, responseColor, responseIcon;
		switch (attendance.response) {
			case 0:
				responseText = 'Pas de réponse';
				responseColor = 'grey.500';
				responseIcon = 'solar:notification-remove-broken';
				break;
			case 1:
				responseText = 'Participe';
				responseColor = 'success.main';
				responseIcon = 'solar:check-circle-broken';
				break;
			case 2:
				responseText = 'Ne participe pas';
				responseColor = 'error.main';
				responseIcon = 'solar:close-circle-broken';
				break;
			case 3:
				responseText = 'Disponible si nécessaire';
				responseColor = 'info.main';
				responseIcon = 'solar:dialog-broken';
				break;
			case 4:
				responseText = 'Ne sais pas';
				responseColor = 'warning.main';
				responseIcon = 'solar:question-circle-broken';
				break;
			default:
				responseText = 'Pas de réponse';
				responseColor = 'grey.500';
				responseIcon = 'solar:notification-remove-broken';
				break;
		}

		return {
			uuid: attendance.attendance.uuid,
			name: attendance.attendance.name,
			date: new Date(attendance.attendance.date),
			startTime: attendance.attendance.start_time,
			endTime: attendance.attendance.end_time,
			allDay: Boolean(attendance.attendance.all_day),
			location: attendance.attendance.location,

			locked: Boolean(attendance.attendance.locked),
			cancelled: Boolean(attendance.attendance.cancelled),
			
			type: attendance.attendance.type,
			description: attendance.attendance.description,
			program: attendance.attendance.program,
			notified: attendance.attendance.notified,

			createdAt: new Date(attendance.attendance.created_at),
			updatedAt: new Date(attendance.attendance.updated_at),

			response: {
				text: responseText,
				color: responseColor,
				icon: responseIcon,
			},
		}
	}) || [];

	const showIcalLink = () => {
		setIcalDialogOpen(true);
	};

	const hideIcalLink = () => {
		setIcalDialogOpen(false);
	};

	return (
		<>
			<Helmet>
				<title>Présences { TITLE }</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Box>
							<Typography variant="h4">
								Présences
							</Typography>
							<Breadcrumbs separator="›" aria-label="breadcrumb">
								<Link underline="hover" color="inherit" href="/">
									Accueil
								</Link>
								<Typography color="text.primary">Présences</Typography>
							</Breadcrumbs>
						</Box>
						<Stack direction="row" gap={2}>
							<IconButton aria-label="Ajouter au calendrier" onClick={showIcalLink}>
								<Iconify icon="solar:calendar-add-bold" width="24px" />
							</IconButton>
							<IconButton aria-label="Acceder à la vue tabulaire" onClick={() => window.location.href = '/attendance/full'}>
								<Iconify icon="solar:eye-scan-bold" width="24px" />
							</IconButton>
						</Stack>
					</Stack>
				</HeaderTitle>

				{error && (
					<Alert severity="error" sx={{ my: 3 }}>
						<AlertTitle>Erreur d'actualisation</AlertTitle>
						Les données les plus récentes n'ont pas pu être récupérées. Les données affichées peuvent être obsolètes.
					</Alert>
				)}

				{attendances.length > 0 && (
					<Stack gap={'26px'}>
						{attendances.map((attendance, index) => {
							let attendanceType;
							if (attendance.type === 'prestation') {
								attendanceType = 'Prestation';
							} else if (attendance.type === 'repetition') {
								attendanceType = 'Répétition';
							} else if (attendance.type === 'vie-asso') {
								attendanceType = 'Vie de l\'Orchestre';
							} else {
								attendanceType = 'Autre';
							}

							return (
								<Link id={index} underline="none" color="inherit" href={'/attendance/' + attendance.uuid}>
									<Card
										sx={{
											borderLeft: '4px solid',
											borderLeftColor: attendance.response.color,
										}}
									>
										<Stack direction="row">
											<CardContent
												sx={{
													width: '100%',
													flexGrow: 1,
												}}
											>

												<Label>{ attendanceType }</Label>
												{attendance.cancelled && (<Label color="error" ml={1}>Annulé</Label>)}

												<Typography variant="h6" component="div" gutterBottom sx={{ textDecoration: attendance.cancelled ? 'line-through' : 'none' }}>
													{attendance.name}
												</Typography>

												<Typography variant="body2" sx={{display: 'flex'}}>
													<Iconify icon="solar:calendar-date-broken" width="20px" mr={1} /> {capitalCaseTransform(attendance.date.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }))}
												</Typography>
												<Typography variant="body2" sx={{display: 'flex'}}>	
													<Iconify icon="solar:clock-circle-broken" width="20px" mr={1} /> {attendance.allDay ? 'Toute la journée' : attendance.startTime.replace(':', 'h').split(':')[0] + ' - ' + attendance.endTime.replace(':', 'h').split(':')[0]}
												</Typography>
												<Typography variant="body2" sx={{display: 'flex'}}>	
													<Iconify icon="solar:map-point-broken" width="20px" mr={1} /> {attendance.location}
												</Typography>

											</CardContent>
											<Box sx={{
												paddingRight: '24px',
												flexGrow: 1, 
												justifyContent: 'center', 
												alignContent: 'center', 
												textAlign: 'center' ,
											}}>
												<Iconify icon={attendance.response.icon} width="30px" color={attendance.response.color} />
											</Box>
										</Stack>
									</Card>
								</Link>
								)
							}
						)}
					</Stack>
				)}


				{!isLoading && attendances.length == 0 && (
					<Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: '100%', marginTop: '10%' }} gap={2}>
						<img src="/assets/characters/no_data.svg" alt="Aucune présence" width="250px" />
						<Typography variant="h6" component="div" gutterBottom>
							Parfait, aucune présence pour le moment
						</Typography>
					</Stack>
				)}

				{isLoading && (
					<Stack gap={'26px'}>
						{loadingSkeleton.map((_, index) => {
							return (								
								<Skeleton variant="rounded" width={'100%'} height={176} />
							)
						})}
					</Stack>
				)}

				<Dialog
					open={icalDialogOpen}
					onClose={hideIcalLink}
				>
					<DialogTitle>
						Lien iCal
					</DialogTitle>

					<DialogContent>
						<DialogContentText>
							Vous pouvez utiliser le lien ci-dessous pour ajouter les présences à votre calendrier.
						</DialogContentText>
						
						<TextField
							sx={{ mt: 2 }}
							label="Lien iCal"
							value={icalUrl}
							fullWidth
						/>

						<CopyToClipboard text={icalUrl}>
							<Button onClick={() => {enqueueSnackbar('Lien copié', { variant: 'success' })}} sx={{ mt: 1 }} variant="outlined" startIcon={<Iconify icon="solar:copy-bold" />}>
								Copier le lien
							</Button>
						</CopyToClipboard>
					</DialogContent>

				</Dialog>
			</Container>
		</>
  );
}

export default Attendances;