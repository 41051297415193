import Iconify from "../../components/iconify";

export default function Alert(theme) {
	return {
		MuiAlert: {
			styleOverrides: {
				root: {
					borderRadius: theme.shape.borderRadiusSm,
					fontWeight: '400',
					fontSize: theme.typography.pxToRem(14),
					'&.MuiAlert-filledSuccess': {
						color: theme.palette.common.white,
						backgroundColor: theme.palette.success.main,
					},
					'&.MuiAlert-filledInfo': {
						color: theme.palette.common.white,
						backgroundColor: theme.palette.info.main,
					},
					'&.MuiAlert-filledWarning': {
						color: theme.palette.common.black,
						backgroundColor: theme.palette.warning.main,
					},
					'&.MuiAlert-filledError': {
						color: theme.palette.common.white,
						backgroundColor: theme.palette.error.main,
					},
					'&.MuiAlert-standardSuccess': {
						color: theme.palette.success.darker,
						backgroundColor: theme.palette.success.lighter,
						'& .MuiAlert-icon': {
							color: theme.palette.success.main,
						},
					},
					'&.MuiAlert-standardInfo': {
						color: theme.palette.info.darker,
						backgroundColor: theme.palette.info.lighter,
						'& .MuiAlert-icon': {
							color: theme.palette.info.main,
						},
					},
					'&.MuiAlert-standardWarning': {
						color: theme.palette.warning.darker,
						backgroundColor: theme.palette.warning.lighter,
						'& .MuiAlert-icon': {
							color: theme.palette.warning.main,
						},
					},
					'&.MuiAlert-standardError': {
						color: theme.palette.error.darker,
						backgroundColor: theme.palette.error.lighter,
						'& .MuiAlert-icon': {
							color: theme.palette.error.main,
						},
					},
				},
			},
			defaultProps: {
				iconMapping: {
					success: <Iconify icon="eva:checkmark-circle-2-fill" width={24} />,
					info: <Iconify icon="eva:info-fill" width={24} />,
					warning: <Iconify icon="eva:alert-triangle-fill" width={24} />,
					error: <Iconify icon="solar:danger-bold" width={24} />,
				},
			},
		},
	}
}