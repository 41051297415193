import { Alert, AlertColor, Box, Checkbox, FormControlLabel, IconButton, InputAdornment, Link, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import Iconify from "../../components/iconify/Iconify";
import { LoadingButton } from '@mui/lab';
import { Navigate, useLocation } from "react-router";
import { login } from "../../api/auth";

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginValidationSchema } from "../../utils/yupValidation";
import { enqueueSnackbar } from "notistack";
import { TITLE } from "../../constant";
import { retrieveErrorMessages } from "../../utils/errorsDisplay";

// ---

interface LoginFormValues {
	email: string;
	password: string;
	rememberMe: boolean | undefined;
}

// ---

function LoginPage() {
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);

	const state = useLocation().state as { message: string, severity: AlertColor } | undefined;

	const [hasAlert, setHasAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error');

	const { 
		register,
		handleSubmit,
		setValue,
		reset,
		watch,
		getValues,
		formState: { errors }, 
	} = useForm<LoginFormValues>({
		mode: 'onChange',
		// resolver: yupResolver(LoginValidationSchema),
	});

	const setAlert = (message: string, severity: AlertColor) => {
		setHasAlert(true);
		setAlertMessage(message || 'Une erreur est survenue');
		setAlertSeverity(severity);
	}
	
	const handleLogin = async (data: LoginFormValues) => {
		setLoading(true);

		await login(data.email, data.password, (data.rememberMe || false))
			.then((response) => {
				if (response.status == 'success') {
					enqueueSnackbar('Vous êtes maintenant connecté.', { variant: 'success' })
					setTimeout(() => {
						window.location.href = '/';
					}, 2000);
				}
			}).catch((error) => {
				console.log(error);
				setLoading(false);
				setAlert(retrieveErrorMessages(error.response.data?.error, error.response.data?.message), 'error');
				reset({ email: data.email, password: '', rememberMe: data.rememberMe });
			});
	}

	return (
		<>
			<Helmet>
				<title>Connexion { TITLE }</title>
			</Helmet>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					marginBottom: '32px',
				}}
			>
				{hasAlert &&
					<Alert variant="standard" severity={alertSeverity} sx={{ marginBottom: '16px' }}>
						{alertMessage}
					</Alert>
				}

				<Typography
					sx={{
						fontWeight: 700,
						lineHeight: 1.2,
						fontSize: '1.4823rem !important',
					}}
				>
					Connexion
				</Typography>
				<Typography
					sx={{
						margin: '8px, 0 0'
					}}
				>
					Pour continuer, veuillez vous connecter.
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<form onSubmit={handleSubmit(handleLogin)}>
					<Stack spacing={2}>
						<TextField 
							{...register('email')}
							id="email" 
							label="Adresse e-mail" 
							variant="outlined" 
							autoFocus
							disabled={loading}
							error={!!errors.email}
							helperText={errors.email && errors?.email?.message}
						/>
						<TextField
							{...register('password')}
							disabled={loading}
							id="password" 
							label="Mot de passe" 
							variant="outlined" 
							type={showPassword ? 'text' : 'password'}
							error={!!errors.password}
							helperText={errors.password && errors?.password?.message}
							InputProps={{
								endAdornment: (
								<InputAdornment position="end">
										<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
											{/* @ts-ignore */}
											<Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
										</IconButton>
								</InputAdornment>
								),
							}}
						/>
					</Stack>

					<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
						<FormControlLabel
							control={<Checkbox 
								{...register('rememberMe')}
								name="remember"
							/>}
							label="Se souvenir de moi"
						/>
						<Link variant="subtitle2" underline="hover" href="/auth/forgot-password">
							Mot de passe oublié ?
						</Link>
					</Stack>

					<LoadingButton
						loading={loading}
						size="large"
						type="submit"
						fullWidth
						variant="contained"
					>
						Se connecter
					</LoadingButton>
				</form>
			</Box>
		</>
	)
}

export default LoginPage;