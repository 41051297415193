// @ts-nocheck

import { Alert, AlertTitle, Backdrop, Box, Breadcrumbs, Card, CircularProgress, Container, Dialog, DialogContent, DialogTitle, Grid, IconButton, Link, ListItemText, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import { useState } from "react";
import useSWR from 'swr';
import { fetcher, instance } from "../../api";
import Iconify from "../../components/iconify/Iconify";
import { styled } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import { TITLE } from "../../constant";
import { retrieveErrorMessages } from "../../utils/errorsDisplay";
import { capitalCaseTransform } from "change-case";
import Label from "../../components/label/Label";
import Chart, { useChart } from "../../components/chart";
import palette from "../../theme/palette";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";
import remarkEmoji from "remark-emoji";

// ---

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

const instruments = (JSON.parse(localStorage.getItem('instruments')) || []).sort(
	(a, b) => a.rank < b.rank ? -1 : 1
);

const subInstruments = (JSON.parse(localStorage.getItem('sub-instruments')) || []).sort(
	(a, b) => a.name.localeCompare(b.name)
);

const responseValues = [
	['Pas de réponse', 'grey.500', 'solar:notification-remove-broken'],
	['Participe', 'success.main', 'solar:check-circle-broken'],
	['Ne participe pas', 'error.main', 'solar:close-circle-broken'],
	['Disponible si nécessaire', 'info.main', 'solar:dialog-broken'],
	['Ne sais pas', 'warning.main', 'solar:question-circle-broken']
];

// ---

function AttendanceDetails() {
	const { uuid } = useParams<{ uuid: string }>();
	const { data, error, isLoading } = useSWR('/attendance/' + uuid, fetcher)
	const [openChangePresenceStatusDialog, setOpenChangePresenceStatusDialog] = useState(false);
	const [showSpinnerBackdrop, setShowSpinnerBackdrop] = useState(false);

	const openStatusDialog = () => {
		setOpenChangePresenceStatusDialog(true);
	}

	// Charts
	let instrumentsChart = instruments.filter((instrument) => {
		return instrument.id != 12
	});
	
	const chartOrder = [1, 3, 4, 2, 0]
	const chartOptions = useChart({
		colors: chartOrder.map((index) => palette[responseValues[index][1].split('.')[0]][responseValues[index][1].split('.')[1]]),
		chart: { type: 'bar', height: 350, stacked: true },
		plotOptions: { bar: { horizontal: true } },
		legend: { show: false },
		xaxis: {
			categories: instrumentsChart.map((instrument) => instrument.name),
		},
	});

	let chartData = []
	if (!isLoading) {
		chartData = [
			{
				name: responseValues[1][0],
				data: instrumentsChart.map((instrument) => {
					return data.byInstrument[instrument.id]?.[1] || null;
				}),
			},
			{
				name: responseValues[3][0],
				data: instrumentsChart.map((instrument) => {
					return data.byInstrument[instrument.id]?.[3] || null;
				}),
			},
			{
				name: responseValues[4][0],
				data: instrumentsChart.map((instrument) => {
					return data.byInstrument[instrument.id]?.[4] || null;
				}),
			},
			{
				name: responseValues[2][0],
				data: instrumentsChart.map((instrument) => {
					return data.byInstrument[instrument.id]?.[2] || null;
				}),
			},
			{
				name: responseValues[0][0],
				data: instrumentsChart.map((instrument) => {
					return data.byInstrument[instrument.id]?.[0] || null;
				}),
			}
		];
	}

	const updatePresenceStatus = (status: number, subInstrumentId: number|null = null, place: number|null = null) => () => {
		setShowSpinnerBackdrop(true);
		let response = {response: status};
		if (subInstrumentId) {
			response = {
				response: status, 
				sub_instrument_id: subInstrumentId,
				place: place
			};
		}

		instance.put('/attendance/' + uuid + '/personal-state', response).then(() => {
			setOpenChangePresenceStatusDialog(false);
			setShowSpinnerBackdrop(false);

			data.instrumentDetails[Object.keys(data.instrumentDetails)[0]].find((member:any) => member.uuid == localStorage.getItem('member-uuid')).response = status;
			data.memberResponse.response = status;

			data.instrumentDetails[Object.keys(data.instrumentDetails)[0]].find((member:any) => member.uuid == localStorage.getItem('member-uuid')).part = subInstrumentId;
			data.memberResponse.part = subInstrumentId;

			data.instrumentDetails[Object.keys(data.instrumentDetails)[0]].find((member:any) => member.uuid == localStorage.getItem('member-uuid')).place = place;
			data.memberResponse.place = place;
		}).catch((error) => {
			enqueueSnackbar(retrieveErrorMessages(error.response.data.error, error.response.data.message), { variant: 'error' });
			setOpenChangePresenceStatusDialog(false);
			setShowSpinnerBackdrop(false);
		});
	}
	if (error) {
		return (
			<Alert severity="error" sx={{ my: 3 }}>
				<AlertTitle>Erreur d'actualisation</AlertTitle>
				Les données les plus récentes n'ont pas pu être récupérées. Les données affichées peuvent être obsolètes.
			</Alert>
		)
	}

	return (
		<>
			<Helmet>
				<title>{isLoading ? 'Chargement...' : data.attendance.name} { TITLE }</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
						<Box>
							<Typography variant="h4" gutterBottom>
								{isLoading ? (<Skeleton variant="text" width={400} height={36} />) : data.attendance.name}
							</Typography>
							<Breadcrumbs separator="›" aria-label="breadcrumb">
								<Link underline="hover" color="inherit" href="/">
									Accueil
								</Link>
								<Link underline="hover" color="inherit" href="/attendance">
									Présences
								</Link>
								<Typography color="text.primary">{isLoading ? (<Skeleton variant="text" width={150} height={24} />) : data.attendance.name}</Typography>
							</Breadcrumbs>
						</Box>
						<Box sx={{
							flexGrow: 1,
						}} />
						<IconButton
							onClick={() => window.print()}
						>
							<Iconify icon="solar:printer-minimalistic-bold" />
						</IconButton>
					</Stack>
				</HeaderTitle>

				{error && (
					<Alert severity="error" sx={{ my: 3 }}>
						<AlertTitle>Erreur d'actualisation</AlertTitle>
						Les données les plus récentes n'ont pas pu être récupérées. Les données affichées peuvent être obsolètes.
					</Alert>
				)}

				{!isLoading && data.memberResponse.response == 3 && (
					<Alert severity="warning" sx={{ my: 3 }}>
						<AlertTitle>Attention</AlertTitle>
						En indiquant que vous êtes disponible si nécessaire, <b>vous vous engagez à être présent</b> si le besoin se fait sentir ou si une personne de votre pupitre se désiste, <b>même au dernier moment</b>.<br/> Merci de ne pas sélectionner cette option si vous n'êtes pas sûr de pouvoir être présent.
					</Alert>
				)}

				{!isLoading && data.attendance.cancelled == true && (
					<Alert severity="error" sx={{ my: 3 }}>
						<AlertTitle>Annulé</AlertTitle>
						Cette présence a été annulée. Vous pouvez toujours indiquer votre statut de présence, mais il ne sera pas pris en compte.
					</Alert>
				)}

				{!isLoading && data.attendance.locked == true && (
					<Alert severity="info" sx={{ my: 3 }}>
						<AlertTitle>Verrouillé</AlertTitle>
						Cette présence a été verrouillée. Vous ne pouvez plus modifier votre statut de présence.
					</Alert>
				)}

				<Box>
					<Grid container spacing={4}>
						<Grid item xs={12} md={3}>
							<Card 
								onClick={openStatusDialog}
								sx={{
									p: 2, 
									mb: 2,
									backgroundColor: responseValues[isLoading ? 0 : data.memberResponse.response][1],
									cursor: 'pointer',
									transform: 'scale(1)',
									transition: 'all 0.2s ease-in-out',
									'&:hover': {
										backgroundColor: responseValues[isLoading ? 0 : data.memberResponse.response][1],
										opacity: 0.95,
										transform: 'scale(1.05)',
									}
								}}
							>
								<Stack direction="row" gap={2}>
									<Iconify icon={isLoading ? 'solar:notification-remove-broken' : responseValues[data.memberResponse.response][2]} width={25} color="white" />
									<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.975rem', display: 'flex', color: 'white'}}>{
										isLoading ? (<Skeleton variant="text" width={50} height={24} sx={{marginRight:1}} />) : 
											(data.attendance.type === 'prestation' && (data.memberResponse.response == 1 || data.memberResponse.response == 3) && data.memberResponse.part != null) ?  subInstruments.find((subInstrument) => subInstrument.id === parseInt(data.memberResponse.part)).name : responseValues[data.memberResponse.response][0] 
									}</Typography>
									<IconButton slot="end">
										<Iconify icon="solar:edit-bold" color="white" />
									</IconButton>
								</Stack>
							</Card>
							<Card sx={{ p: 2, mb: 2 }}>
								<Stack direction="column" gap={2}>
									<Stack direction="row" gap={2}>
										<Iconify icon="solar:calendar-date-bold" />
										<ListItemText sx={{mt: 0}}>
											<Typography variant="body1" color="text.secondary" sx={{mb: '4px', fontSize: ' 0.875rem'}}>Date</Typography>
											<Typography variant="body2" color="text.primary" sx={{fontWeight: 600, fontSize: '0.875rem'}}>{isLoading ? (<Skeleton variant="text" width={150} height={24} />) : capitalCaseTransform(new Date(data.attendance.date).toLocaleDateString('fr-FR', {
												weekday: 'long',
												year: 'numeric',
												month: 'long',
												day: 'numeric'
											}))}</Typography>
										</ListItemText>
									</Stack>
									<Stack direction="row" gap={2}>
										<Iconify icon="solar:clock-circle-bold" />
										<ListItemText sx={{mt: 0}}>
											<Typography variant="body1" color="text.secondary" sx={{mb: '4px', fontSize: ' 0.875rem'}}>Heure</Typography>
											<Typography variant="body2" color="text.primary" sx={{fontWeight: 600, fontSize: '0.875rem'}}>{isLoading ? (<Skeleton variant="text" width={150} height={24} />) : data.attendance.all_day ? 'Toute la journée' : data.attendance.start_time.replace(':', 'h').split(':')[0] + ' - ' + data.attendance.end_time.replace(':', 'h').split(':')[0] }</Typography>
										</ListItemText>
									</Stack>
									<Link
										sx={{ color: 'inherit', textDecoration: 'none' }}
										href={`https://www.google.fr/maps/place/${isLoading ? null : data.attendance.location }`}
										target="_blank"
										rel="noopener"
										rel="noreferrer"
									>
										<Stack direction="row" gap={2}>
											<Iconify icon="solar:map-point-bold" />
											<ListItemText sx={{mt: 0}}>
												<Typography variant="body1" color="text.secondary" sx={{mb: '4px', fontSize: ' 0.875rem'}}>Lieu</Typography>
												<Typography variant="body2" color="text.primary" sx={{fontWeight: 600, fontSize: '0.875rem'}}>{isLoading ? (<Skeleton variant="text" width={150} height={24} />) : data.attendance.location}</Typography>
											</ListItemText>
										</Stack>
									</Link>
									{isLoading ? null : Boolean(data.attendance.locked) && <Stack direction="row" gap={2} color="error.main">
										<Iconify icon="eva:lock-fill" />
										<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem'}}>Verrouillé</Typography>
									</Stack>}
									{isLoading ? null : Boolean(data.attendance.cancelled) && <Stack direction="row" gap={2} color="error.main">
										<Iconify icon="eva:backspace-fill" />
										<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem'}}>Annulé</Typography>
									</Stack>}
								</Stack>
							</Card>
							<Card sx={{ p: 2, mb: 2 }}>
								<Stack direction="column" gap={2}>
									{isLoading ? null : data.instrumentDetails[Object.keys(data.instrumentDetails)[0]].map((member:any, index:number) => {
										const SUB_INSTRUMENT = (data.attendance.type === 'prestation' && (member.response == 1 || member.response == 3) && member.part != null) ? subInstruments.find((subInstrument) => subInstrument.id === parseInt(member.part)).slug : null 
											return (
												<Tooltip title={responseValues[member.response][0]} placement="left-end">
													<Stack direction="row" gap={2} key={index}>
														<Iconify icon={responseValues[member.response][2]} color={responseValues[member.response][1]} />
														<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem', display: 'flex'}}>{member.name[0]} {member.name[1]} {SUB_INSTRUMENT && (<Label ml={1}>{ SUB_INSTRUMENT }</Label>)}</Typography>
													</Stack>
												</Tooltip>
											)
										})
									}
								</Stack>
							</Card>
							<Card sx={{ p: 2, mb: 2 }}>
								<Stack direction="column" gap={2}>
									<Tooltip title="Présents" color="success.main" placement="left-start">
										<Stack direction="row" gap={2}>
											<Iconify icon="solar:check-circle-broken" />
											<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem', display: 'flex'}}>{isLoading ? (<Skeleton variant="text" width={50} height={24} sx={{marginRight:1}} />) : data.response.numAttending > 0 ? data.response.numAttending : 'Aucun' } musicien{!isLoading && data.response.numAttending > 1 ? 's' : '' }</Typography>
										</Stack>
									</Tooltip>
									<Tooltip title="Disponible si nécessaire" color="info.main" placement="left-start">
										<Stack direction="row" gap={2}>
											<Iconify icon="solar:dialog-broken" />
											<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem', display: 'flex'}}>{isLoading ? (<Skeleton variant="text" width={50} height={24} sx={{marginRight:1}} />) : data.response.numOnlyIfNeeded > 0 ? data.response.numOnlyIfNeeded : 'Aucun' } musicien{!isLoading && data.response.numOnlyIfNeeded > 1 ? 's' : '' }</Typography>
										</Stack>
									</Tooltip>
									<Tooltip title="Ne participe pas" color="error.main" placement="left-start">
										<Stack direction="row" gap={2}>
											<Iconify icon="solar:close-circle-broken" />
											<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem', display: 'flex'}}>{isLoading ? (<Skeleton variant="text" width={50} height={24} sx={{marginRight:1}} />) : data.response.numNotAttending > 0 ? data.response.numNotAttending : 'Aucun' } musicien{!isLoading && data.response.numNotAttending > 1 ? 's' : '' }</Typography>
										</Stack>
									</Tooltip>
									<Tooltip title="Ne sais pas" placement="left-start">
										<Stack direction="row" gap={2} color="warning.main">
											<Iconify icon="solar:question-circle-broken" />
											<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem', display: 'flex'}}>{isLoading ? (<Skeleton variant="text" width={50} height={24} sx={{marginRight:1}} />) : data.response.numNotSure > 0 ? data.response.numNotSure : 'Aucun' } musicien{!isLoading && data.response.numNotSure > 1 ? 's' : '' }</Typography>
										</Stack>
									</Tooltip>
									<Tooltip title="Pas de réponse" color="grey.600" placement="left-start">
										<Stack direction="row" gap={2}>
											<Iconify icon="solar:notification-remove-broken" />
											<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem', display: 'flex'}}>{isLoading ? (<Skeleton variant="text" width={50} height={24} sx={{marginRight:1}} />) : data.response.numNoResponse > 0 ? data.response.numNoResponse : 'Aucun' } musicien{!isLoading && data.response.numNoResponse > 1 ? 's' : '' }</Typography>
										</Stack>
									</Tooltip>
									<Tooltip title="Total" placement="left-start">
										<Stack direction="row" gap={2}>
											<Iconify icon="solar:slash-circle-broken" />
											<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.875rem', display: 'flex'}}>{isLoading ? (<Skeleton variant="text" width={50} height={24} sx={{marginRight:1}} />) : data.response.totalResponses + ' / ' + data.response.totalMembers } réponses</Typography>
										</Stack>
									</Tooltip>
								</Stack>
							</Card>
						</Grid>
						<Grid item xs={12} md={9}>
							{!isLoading && data.attendance.type == 'prestation' && data.distribution != null && (
								<Card sx={{ p: 2, mb: 2 }}>
									<Stack direction="column" gap={2}>
										<Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.125rem' }}>Répartition</Typography>
										
										<Stack direction="column" gap={2}>
											{subInstruments.filter((subInstrument) => {
													return Object.keys(data.distribution).includes(subInstrument.id.toString())
												})
												.sort((a, b) => a.id > b.id ? 1 : -1)
												.map((subInstrument, index) => {
													return (
														<Box key={index} sx={{ mt: 1 }}>
															<Label>{subInstrument.name}</Label>
															<Stack direction={{xs: "column", md: "row"}} gap={1} sx={{ mt: 1 }}>
																{[...Array(data.distribution[subInstrument.id])].map((_, index) => {
																	let filteredMembers = data.instrumentDetails[Object.keys(data.instrumentDetails)[0]].filter((member: any) => member.part == subInstrument.id);

																	let displayed = false;
																	if (filteredMembers) {
																		let filteredMember = filteredMembers.find((member: any) => member.place == index + 1);
																		if (filteredMember) {
																			displayed = true;
																			return (
																				<Box
																					sx={{
																						borderRadius: '10px',
																						border: '2px solid',
																						borderColor: responseValues[filteredMember.response][1],
																						backgroundColor: 'grey.200',
																						width: '100%',
																						height: '40px',
																						cursor: 'not-allowed',
																						fontWeight: 600,
																						display: 'flex',
																						justifyContent: 'center',
																						alignItems: 'center',
																					}}

																					key={subInstrument.id + '' + index}
																				>
																					{filteredMember.name[0]} {filteredMember.name[1]}
																				</Box>
																			)
																		}
																	}
																	return (
																		<Tooltip
																			title={"Cliquez pour vous inscrire en " + subInstrument.name}
																			placement="top"
																			key={subInstrument.id + '' + index}
																		>
																			<Box
																				sx={{
																					borderRadius: '10px',
																					border: '2px dashed',
																					borderColor: 'error.light',
																					width: '100%',
																					height: '40px',
																					cursor: 'pointer',
																					transition: 'all 0.2s ease-in-out',
																					":hover": {
																						backgroundColor: 'grey.200',
																					},
																				}}
																				onClick={updatePresenceStatus(1, subInstrument.id, index + 1)}
																			/>
																		</Tooltip>
																	)
																})
																}
																<Tooltip
																	title={"Disponible en " + subInstrument.name}
																	placement="top"
																	key={subInstrument.id + '' + index}
																>
																	<IconButton
																		onClick={updatePresenceStatus(3, subInstrument.id)}
																	>
																		<Iconify icon="solar:add-circle-bold" />
																	</IconButton>
																</Tooltip>
															</Stack>
															<Grid container spacing={1}>
																{data.instrumentDetails[Object.keys(data.instrumentDetails)[0]].filter((member: any) => {
																	return member.part == subInstrument.id && member.response == 3
																}).map((member, index) => {
																	return (
																		<Grid item xs={12} sm={6} md={4} lg={3} key={subInstrument.id + '' + index + 'ifNesc'} sx={{ mt: 1 }}>
																			<Tooltip
																				title={responseValues[member.response][0] + ' en ' + subInstrument.name}
																				placement="top"
																			>
																				<Box
																					sx={{
																						borderRadius: '10px',
																						border: '2px solid',
																						borderColor: responseValues[member.response][1],
																						backgroundColor: 'grey.200',
																						width: '100%',
																						height: '40px',
																						cursor: 'not-allowed',
																						fontWeight: 600,
																						display: 'flex',
																						justifyContent: 'center',
																						alignItems: 'center',
																					}}
																				>
																					{member.name[0]} {member.name[1]}
																				</Box>
																			</Tooltip>
																		</Grid>
																	)
																})}
															</Grid>
														</Box>
													)
											})}
										</Stack>
									</Stack>
								</Card>
							)}
							
							<Card sx={{ p: 2 }}>
								<Stack direction="column" gap={2}>
									<Box>
										<Typography variant="h6" sx={{fontWeight: 600, fontSize: '1.125rem', whiteSpace: 'break-spaces'}}>Description</Typography>
										<Typography variant="body1" sx={{ fontSize: '0.875rem', whiteSpace: 'break-spaces' }}>
											{isLoading ? (<Skeleton variant="rounded" width={450} height={110} />) :
												data.attendance.description ?
													(<Markdown
														remarkPlugins={[[remarkGfm], [remarkEmoji, { emoticon: true, accessible: true }]]}
														skipHtml
														className={'markdownReact'}
													>
														{ data.attendance.description }
													</Markdown>) : 'Aucune description'}
										</Typography>
									</Box>
									<Box>
										<Typography variant="h6" sx={{fontWeight: 600, fontSize: '1.125rem'}}>Programme</Typography>
										<Typography variant="body1" sx={{ fontSize: '0.875rem', whiteSpace: 'break-spaces' }}>
											{isLoading ? (<Skeleton variant="rounded" width={380} height={110} />) :
												data.attendance.program ?
													(<Markdown
														remarkPlugins={[[remarkGfm], [remarkEmoji, { emoticon: true, accessible: true }]]}
														skipHtml
														className={'markdownReact'}
													>
														{ data.attendance.program }
													</Markdown>) : 'Aucun programme'}
										</Typography>
									</Box>
								</Stack>
							</Card>

							<Card sx={{ p: 2, mt: 2 }}>
								{isLoading ? (<Skeleton variant="rectangular" width={800} height={350} />) : <Chart options={chartOptions} series={chartData} type="bar" height={350} />}
							</Card>
						</Grid>
					</Grid>
				</Box>

				<Backdrop
					sx={{ color: '#fff', zIndex: 2000 }}
					open={showSpinnerBackdrop}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
				
				<Dialog
					open={openChangePresenceStatusDialog}
					onClose={() => setOpenChangePresenceStatusDialog(false)}
				>
					<DialogTitle>Changer de statut</DialogTitle>
					<DialogContent>
						<Stack direction="column" gap={2}>
							{responseValues.map((response, index) => {
								const isPrestation = !isLoading ? data.attendance.type === 'prestation' : false;
								const isDistributionEnabled = isPrestation && data.distribution != null;
								if (index == 0 || (!isDistributionEnabled && index == 3) || (isDistributionEnabled && index == 1)) return null;
								const selectableSubInstruments = isPrestation ? subInstruments.filter((subInstrument) => {
									return subInstrument.instrumentId == Object.keys(data.instrumentDetails)[0]
								}) : null;

								return (<>
									<Card
										onClick={!(isPrestation && (index == 1 || index == 3)) ? updatePresenceStatus(index, null) : null}
										sx={{
											p: 2, 
											backgroundColor: response[1],
											borderRadius: isPrestation && (index == 1 || index == 3) ? '12px 12px 0px 0px' : '12px',
											cursor: isPrestation && (index == 1 || index == 3) ? 'not-allowed' : 'pointer',
											transform: 'scale(1)',
											transition: 'all 0.2s ease-in-out',
											'&:hover': isPrestation && (index == 1 || index == 3) ? null : {
												backgroundColor: response[1],
												opacity: 0.95,
												transform: 'scale(1.05)',
											}
										}}
									>
										<Stack direction="row" gap={2}>
											<Iconify icon={response[2]} width={25} color="white" />
											<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.975rem', display: 'flex', color: 'white'}}>{response[0]}</Typography>
										</Stack>
									</Card>
									{isPrestation && ((!isDistributionEnabled && index == 1) || (isDistributionEnabled && index == 3)) && selectableSubInstruments.map((subInstrument, subIndex) => {
										return (
											<Box
												key={subIndex}
												onClick={updatePresenceStatus(index, subInstrument.id)}
												marginTop={-2}
												sx={{
													paddingBlock: 1,
													paddingInline: 2,
													backgroundColor: 'grey.300',
													borderRadius: subIndex == selectableSubInstruments.length - 1 ? '0px 0px 12px 12px' : 0,
													borderBottom: subIndex == selectableSubInstruments.length - 1 ? 0 : '1px solid',
													borderBottomColor: 'grey.400',
													cursor: 'pointer',
													transform: 'scale(1)',
													transition: 'all 0.2s ease-in-out',
													'&:hover': {
														opacity: 0.95,
														transform: 'scale(1.05)',
														borderBottomColor: 'grey.300',
													},
												}}
											>
												<Typography variant="body1" sx={{fontWeight: 600, fontSize: '0.975rem', display: 'flex', color: 'black'}}>{ subInstrument.name }</Typography>
											</Box>
										)
									})}
								</>)
							})}
						</Stack>
					</DialogContent>
				</Dialog>

			</Container>
		</>
	);
}

export default AttendanceDetails;