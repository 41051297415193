import { styled } from "@mui/system";
import { Container, Stack, Box, Typography, Skeleton, Breadcrumbs, Link, IconButton, Alert, AlertTitle, Card, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { RESPONSE_VALUES, TITLE } from "../../constant";
import Iconify from "../../components/iconify";
import { fetcher } from "../../api";
import useSWR from "swr";
import SimpleBar from "simplebar-react";
import { useState } from "react";
import Label from "../../components/label/Label";

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

// @ts-ignore
const instruments = (JSON.parse(localStorage.getItem('instruments')) || []).sort(
	// @ts-ignore
	(a: Object, b: Object) => a.rank < b.rank ? -1 : 1
);

// @ts-ignore
const subInstruments = (JSON.parse(localStorage.getItem('sub-instruments')) || []).sort(
	// @ts-ignore
	(a, b) => a.name.localeCompare(b.name)
);

function Madeleine() {
	const { data, error, isLoading } = useSWR('/attendance-admin/distribution', fetcher)
	const [filteredInstrument, setFilteredInstrument] = useState(0);
	
	const handleFilter = (instrument: number) => {
		setFilteredInstrument(instrument);
	}

	const getAttendanceStatistics = (attendanceUuid: string) => {
		const attendance = data.attendances.find((attendance: any) => attendance.uuid == attendanceUuid);
		const members = data.members.filter((member: any) => member.instrument == (filteredInstrument == 0 ? member.instrument : filteredInstrument));
		const presentT = members.map((member: any) => data.responses[member.uuid][attendanceUuid].response == 1 ? 1 : 0).reduce((a: number, b: number) => a + b, 0);
		const ifNeededT = members.map((member: any) => data.responses[member.uuid][attendanceUuid].response == 3 ? 1 : 0).reduce((a: number, b: number) => a + b, 0);
		const noResponseT = members.map((member: any) => data.responses[member.uuid][attendanceUuid].response == 0 ? 1 : 0).reduce((a: number, b: number) => a + b, 0);
		const totalT = members.length;

		const byPart = {} as any;

		
		return {
			attendance,
			members,
			total: {
				present: presentT,
				ifNeeded: ifNeededT,
				total: totalT,
				noResponse: noResponseT,
				absent: totalT - presentT - ifNeededT
			},
			byPart: byPart
		};
	}

	const getAttendanceStatisticsByPart = (attendanceUuid: string, part: number) => {
	}

	return (
		<>
			<Helmet>
				<title>Répartition Madeleine { TITLE }</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
						<Box>
							<Typography variant="h4" gutterBottom>
								Répartition de la Madeleine
							</Typography>
							<Breadcrumbs separator="›" aria-label="breadcrumb">
								<Link underline="hover" color="inherit" href="/">
									Accueil
								</Link>
								<Link underline="hover" color="inherit" href="/attendance">
									Présences
								</Link>
								<Link underline="hover" color="inherit" href="/attendance-management">
									Gestion
								</Link>
								<Typography color="text.primary">Répartition</Typography>
							</Breadcrumbs>
						</Box>
						<Box sx={{
							flexGrow: 1,
						}} />
						<FormControl>
							<InputLabel id="instrument-label">Pupitre</InputLabel>
							<Select
								value={filteredInstrument}
								onChange={(e) => handleFilter(e.target.value as number)} sx={{ minWidth: 120 }}
								id="instrument-label"
								label="Pupitre"
							>
								<MenuItem value={0}>Tous</MenuItem>
								{instruments.map((instrument: any) => (
									<MenuItem key={instrument.id} value={instrument.id}>{instrument.name}</MenuItem>
								))}
							</Select>
						</FormControl>
					</Stack>
				</HeaderTitle>

				{error && (
					<Alert severity="error" sx={{ my: 3 }}>
						<AlertTitle>Erreur d'actualisation</AlertTitle>
						Les données les plus récentes n'ont pas pu être récupérées. Les données affichées peuvent être obsolètes.
					</Alert>
				)}

				{!isLoading && filteredInstrument != 0 && (
					<Card sx={{ mb: 2 }}>
						<SimpleBar>
							<Table stickyHeader sx={{ overflow: 'hidden', width: '100%', maxHeight: '90%', borderRadius: 2 }}>
								<TableHead>
									<TableRow>
										<TableCell></TableCell>

										{data.attendances.map((row: any) => {
											const { attendance, total, byPart } = getAttendanceStatistics(row.uuid);

											return (
												<TableCell key={row.uuid}>
													{row.name}
												</TableCell>
											)
										})}
									</TableRow>
								</TableHead>

								<TableBody>
									{subInstruments
										.filter((subInstrument: any) => subInstrument.instrumentId == filteredInstrument)
										.map((subInstrument: any) => (
											<TableRow key={subInstrument.id}>
												<TableCell>
												{subInstrument.name}
												</TableCell>
												
												{data.attendances.map((attendance: any) => {
													if (data.distribution[attendance.uuid][subInstrument.instrumentId][subInstrument.id] == undefined) {
														return (
															<TableCell key={attendance.uuid} sx={{ textAlign: 'center', fontStyle: 'italic' }}>
																Pas demandé
															</TableCell>
														)
													}

													return (
														<TableCell key={attendance.uuid}>
															{/* <Typography sx={{ textAlign: 'center', fontStyle: 'italic' }}>
																{data.distribution[attendance.uuid][subInstrument.instrumentId][subInstrument.id]} demandé
															</Typography> */}
															<Typography
																sx={{
																	fontSize: 12,
																	color: RESPONSE_VALUES[1][1],
																	display: 'flex',
																	gap: 1
																}}>
																<Iconify icon={RESPONSE_VALUES[1][2]} />
																0
															</Typography>
															<Typography
																sx={{
																	fontSize: 12,
																	color: RESPONSE_VALUES[3][1],
																	display: 'flex',
																	gap: 1
																}}>
																<Iconify icon={RESPONSE_VALUES[3][2]} />
																0
															</Typography>
														</TableCell>
													)
												})}

											
											</TableRow>
									))}
								</TableBody>
							</Table>
						</SimpleBar>
					</Card>
				)}

				{!isLoading && data && (
					<Card>
						<SimpleBar>
							<Table stickyHeader sx={{ overflow: 'hidden', width: '100%', maxHeight: '90%', borderRadius: 2 }}>
								<TableHead>
									<TableRow>
										<TableCell>
										</TableCell>
										
										<TableCell>
											% présence<br />
											à l'année
										</TableCell>

										<TableCell>
											Total
										</TableCell>

										{data.attendances.map((row: any) => {
											const { attendance, total, byPart } = getAttendanceStatistics(row.uuid);

											return (
												<TableCell key={row.uuid}>
													{row.name}
													<Typography
														sx={{
															fontSize: 12,
															color: RESPONSE_VALUES[1][1],
															display: 'flex',
															gap: 1
														}}>
														<Iconify icon={RESPONSE_VALUES[1][2]} />
														{ total.present }
													</Typography>
													<Typography
														sx={{
															fontSize: 12,
															color: RESPONSE_VALUES[3][1],
															display: 'flex',
															gap: 1
														}}>
														<Iconify icon={RESPONSE_VALUES[3][2]} />
														{ total.ifNeeded }
													</Typography>
												</TableCell>
											)
										})}
									</TableRow>
								</TableHead>
								<TableBody>
									{data.members
										.filter((member: any) => member.instrument == (filteredInstrument == 0 ? member.instrument : filteredInstrument))
										.sort((a: any, b: any) => {
											return a.name.join(' ').localeCompare(b.name.join(' '));
										})
										.map((member: any) => {
											if (member.totalPresent == 0) {
												return;
											}
										
											return (
												<TableRow key={member.uuid}>
													<TableCell>
														{member.name.join(' ')}
														{filteredInstrument == 0 && (
															<>
																{/* @ts-ignore */}
																<Label sx={{ ml: 1 }}>
																	{instruments.find((instrument: any) => instrument.id == member.instrument).name}
																</Label>
															</>
														)}
													</TableCell>
													<TableCell>
														{member.presentPercentage.toFixed(2)}%
													</TableCell>
													<TableCell>
														<Typography
															sx={{
																fontSize: 12,
																color: RESPONSE_VALUES[1][1],
																display: 'flex',
																gap: 1
															}}>
															<Iconify icon={RESPONSE_VALUES[1][2]} />
															{member.totalPresent}
														</Typography>
														
													</TableCell>
													{data.attendances.map((attendance: any) => (
														<TableCell key={attendance.uuid} sx={{
															color: RESPONSE_VALUES[data.responses[member.uuid][attendance.uuid].response][1],
															textAlign: 'center'
														}}>
															<Tooltip title={RESPONSE_VALUES[data.responses[member.uuid][attendance.uuid].response][0]}>
																<Iconify icon={RESPONSE_VALUES[data.responses[member.uuid][attendance.uuid].response][2]} />
															</Tooltip>
														</TableCell>
													))}
												</TableRow>
											)
										})}
								</TableBody>
							</Table>
						</SimpleBar>
					</Card>
				)}
			</Container>
		</>
	);
}

export default Madeleine;