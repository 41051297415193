import { Navigate, useParams } from "react-router-dom";
import { instance } from "../../api";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Box, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { TITLE } from "../../constant";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../components/iconify";

function VerifyEmail() {
	const { token } = useParams();
	const [requestSuccessful, setRequestSuccessful] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);

	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');

	if (requestSuccessful) return (<Navigate to="/auth/login"/>);

	const setPasswordAndConfirm = async () => {
		setLoading(true);
		instance.patch('/auth/confirm/' + token, {
			password: password,
			password_confirmation: passwordConfirmation,
		})
			.then((response) => response.data)
			.then(() => {
				enqueueSnackbar('Votre adresse e-mail a bien été vérifiée. Vous pouvez désormais vous connecter.', { variant: 'success' });
				setRequestSuccessful(true);
			}).catch((error) => {
				enqueueSnackbar("Votre adresse e-mail n'a pas pu être vérifiée. (" + error.response.data.error + ")", { variant: 'error', autoHideDuration: 10000 });
				setRequestSuccessful(true);
			});
	}

	return (
		<>
			<Helmet>
				<title>Création de votre mot de passe { TITLE }</title>
			</Helmet>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					marginBottom: '32px',
				}}
			>
				<Typography
					sx={{
						fontWeight: 700,
						lineHeight: 1.2,
						fontSize: '1.4823rem !important',
					}}
				>
					Création de votre mot de passe
				</Typography>
				<Typography
					sx={{
						margin: '8px, 0 0'
					}}
				>
					Vous pouvez choisir un nouveau mot de passe.
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Stack spacing={2}>
					<TextField
						required
						id="password" 
						label="Mot de passe" 
						variant="outlined" 
						autoFocus
						disabled={loading}
						type={showPassword ? 'text' : 'password'}
						onChange={(event) => setPassword(event.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
										{/* @ts-ignore */}
										<Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<TextField
						required
						disabled={loading}
						id="password_confirmation" 
						label="Mot de passe (confirmation)" 
						variant="outlined" 
						type={showPassword ? 'text' : 'password'}
						onChange={(event) => setPasswordConfirmation(event.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
										{/* @ts-ignore */}
										<Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Stack>

				<LoadingButton
					loading={loading}
					size="large"
					type="submit"
					fullWidth
					variant="contained"
					sx={{
						marginTop: '24px',
					}}
					onClick={setPasswordAndConfirm}
				>
					Enregistrer votre mot de passe
				</LoadingButton>
			</Box>
		</>
	);
}

export default VerifyEmail;