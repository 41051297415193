import { Alert, AlertTitle, Box, Breadcrumbs, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link, Skeleton, Stack, TextField, Typography, styled } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Iconify from "../components/iconify/Iconify";
import { TITLE } from "../constant";
import useSWR from 'swr';
import { fetcher, instance } from "../api";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

// ---

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

// ---

function AddShortcutDialog({ open, handleClose }: { open: boolean, handleClose: () => void }) {
	const [loading, setLoading] = useState(false);

	const addShortcut = () => {
		setLoading(true);
		const name = (document.getElementById('name') as HTMLInputElement).value;
		const description = (document.getElementById('description') as HTMLInputElement).value;
		const url = (document.getElementById('url') as HTMLInputElement).value;

		instance.post('/shortcuts', {
			name: name,
			description: description,
			url: url,
		}).then(() => {
			enqueueSnackbar('Le raccourci a bien été ajouté.', { variant: 'success' });
			handleClose();
			setLoading(false);
		}).catch(() => {
			enqueueSnackbar('Une erreur est survenue lors de l\'ajout du raccourci.', { variant: 'error' });
			setLoading(false);
		});
	}

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
			<DialogTitle>Ajouter un raccourci</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Stack direction="column" gap={2} mt={1}>
						<TextField
							label="Nom"
							id="name"
							variant="outlined"
							fullWidth
						/>
						<TextField
							label="Description"
							id="description"
							variant="outlined"
							fullWidth
						/>
						<TextField
							label="URL"
							id="url"
							variant="outlined"
							fullWidth
						/>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleClose}>Annuler</Button>
				<LoadingButton loading={loading} variant="contained" onClick={addShortcut}>Ajouter</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}

function DeleteShortcutDialog({ open, handleClose, shortcut }: { open: boolean, handleClose: () => void, shortcut: any }) {
	const [loading, setLoading] = useState(false);

	const deleteShortcut = () => {
		setLoading(true);
		instance.delete(`/shortcuts/${shortcut.id}`).then(() => {
			enqueueSnackbar('Le raccourci a bien été supprimé.', { variant: 'success' });
			handleClose();
			setLoading(false);
		}).catch(() => {
			enqueueSnackbar('Une erreur est survenue lors de la suppression du raccourci.', { variant: 'error' });	
			setLoading(false);
		});
	}

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
			<DialogTitle>Supprimer un raccourci</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Êtes-vous sûr de vouloir supprimer le raccourci <strong>{shortcut.name}</strong> ?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleClose}>Annuler</Button>
				<LoadingButton loading={loading} variant="contained" onClick={deleteShortcut} color="error">Supprimer</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}

function EditShortcutDialog({ open, handleClose, shortcut }: { open: boolean, handleClose: () => void, shortcut: any }) {
	const [loading, setLoading] = useState(false);

	const editShortcut = () => {
		setLoading(true);
		const name = (document.getElementById('name') as HTMLInputElement).value;
		const description = (document.getElementById('description') as HTMLInputElement).value;
		const url = (document.getElementById('url') as HTMLInputElement).value;

		instance.put(`/shortcuts/${shortcut.id}`, {
			name: name,
			description: description,
			url: url,
		}).then(() => {
			enqueueSnackbar('Le raccourci a bien été modifié.', { variant: 'success' });
			handleClose();
			setLoading(false);
		}).catch(() => {
			enqueueSnackbar('Une erreur est survenue lors de la modification du raccourci.', { variant: 'error' });
			setLoading(false);
		});
	}

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
			<DialogTitle>Modifier un raccourci</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Stack direction="column" gap={2} mt={1}>
						<TextField
							label="Nom"
							id="name"
							variant="outlined"
							fullWidth
							defaultValue={shortcut.name}
						/>
						<TextField
							label="Description"
							id="description"
							variant="outlined"
							fullWidth
							defaultValue={shortcut.description}
						/>
						<TextField
							label="URL"
							id="url"
							variant="outlined"
							fullWidth
							defaultValue={shortcut.url}
						/>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleClose}>Annuler</Button>
				<LoadingButton loading={loading} variant="contained" onClick={editShortcut}>Modifier</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}

function Shortcuts() {
	const { data, error, isLoading } = useSWR('/shortcuts', fetcher)
	const loadingSkeleton = Array(5).fill(0);

	const [addShortcutDialogOpen, setAddShortcutDialogOpen] = useState(false);

	const [deleteShortcutDialogOpen, setDeleteShortcutDialogOpen] = useState(false);
	const [shortcutToDelete, setShortcutToDelete] = useState({});

	const [editShortcutDialogOpen, setEditShortcutDialogOpen] = useState(false);
	const [shortcutToEdit, setShortcutToEdit] = useState({});

	return (
		<>
			<Helmet>
				<title>Raccourcis { TITLE }</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Box>
							<Typography variant="h4" gutterBottom>
								Raccourcis
							</Typography>
							<Breadcrumbs separator="›" aria-label="breadcrumb">
								<Link underline="hover" color="inherit" href="/">
									Accueil
								</Link>
								<Typography color="text.primary">Raccourcis</Typography>
							</Breadcrumbs>
						</Box>
						<Box>
							<Button 
								variant="contained"
								onClick={() => setAddShortcutDialogOpen(true)}
							>
								<Iconify icon="mingcute:add-line" mr={1} /> Ajouter un raccourci
							</Button>
						</Box>
					</Stack>
				</HeaderTitle>

				{error && (
					<Alert severity="error" sx={{ my: 3 }}>
						<AlertTitle>Erreur d'actualisation</AlertTitle>
						Les données les plus récentes n'ont pas pu être récupérées. Les données affichées peuvent être obsolètes.
					</Alert>
				)}

				{isLoading && (
					<Stack gap={'26px'}>
					{loadingSkeleton.map((_, index) => {
						return (
							<Card key={index}>
								<CardContent
									sx={{
										width: '100%',
										flexGrow: 1,
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
									}}
								>	
									<Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
										<Skeleton variant="rounded" width={260} height={30} />
										<Skeleton variant="rounded" width={320} height={20} />
										<Stack direction="row" gap={1}>
											<Skeleton variant="circular" width={30} height={30} />
											<Skeleton variant="circular" width={30} height={30} />
										</Stack>
									</Stack>
								</CardContent>
							</Card>
						);
					})}					
					</Stack>
				)}

				{!isLoading && data?.length === 0 && (
					<Alert severity="info" sx={{ my: 3 }}>
						<AlertTitle>Aucun raccourci</AlertTitle>
						Aucun raccourci n'a été créé pour le moment.
					</Alert>
				)}

				{!isLoading && data?.length > 0 && (
					<Stack gap={'26px'}>
					{data.map((shortcut: any, index: number) => {
						return (
							<Card key={index}>
								<CardContent
									sx={{
										width: '100%',
										flexGrow: 1,
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
									}}
								>	
									<Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
										<Stack>
											<Typography variant="h6" component="h2">
												{shortcut.name}
											</Typography>
											<Typography variant="body2" component="p">
												{shortcut.description}
											</Typography>
										</Stack>
										<Link 
											href={shortcut.url}
											target="_blank"
											underline="none"
											color="inherit"
											sx={{
												'@media (max-width: 900px)': {
													display: 'none',
												},
											}}
										>
											<Typography variant="body2" component="p" color="text.secondary" fontStyle="oblique">
												{shortcut.url}
											</Typography>
										</Link>
										<Stack direction="row" gap={0}>
											<IconButton
												onClick={() => {
													setShortcutToEdit(shortcut);
													setEditShortcutDialogOpen(true);
												}}
											>
												<Iconify icon="solar:pen-bold" />
											</IconButton>
											<IconButton
												color="error"
												onClick={() => {
													setShortcutToDelete(shortcut);
													setDeleteShortcutDialogOpen(true);
												}}
											>
												<Iconify icon="solar:trash-bin-2-bold" />
											</IconButton>
										</Stack>
									</Stack>
								</CardContent>
							</Card>
						);
					})}
					</Stack>
				)}

				<AddShortcutDialog 
					open={addShortcutDialogOpen}
					handleClose={() => setAddShortcutDialogOpen(false)}
				/>

				<DeleteShortcutDialog
					open={deleteShortcutDialogOpen}
					handleClose={() => setDeleteShortcutDialogOpen(false)}
					shortcut={shortcutToDelete}
				/>

				<EditShortcutDialog
					open={editShortcutDialogOpen}
					handleClose={() => setEditShortcutDialogOpen(false)}
					shortcut={shortcutToEdit}
				/>

			</Container>
		</>
	)
}

export default Shortcuts;