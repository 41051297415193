import { Box, Breadcrumbs, Button, Container, Link, Stack, Typography, styled } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Iconify from "../components/iconify/Iconify";
import { TITLE } from "../constant";

// ---

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

// ---

function Roles() {
	return (
		<>
			<Helmet>
				<title>Rôles { TITLE }</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Box sx={{ mb: 5 }}>
							<Typography variant="h4" gutterBottom>
								Rôles & permissions
							</Typography>
							<Breadcrumbs separator="›" aria-label="breadcrumb">
								<Link underline="hover" color="inherit" href="/">
									Accueil
								</Link>
								<Typography color="text.primary">Rôles & permissions</Typography>
							</Breadcrumbs>
						</Box>
					</Stack>
				</HeaderTitle>
			</Container>
		</>
	)
}

export default Roles;