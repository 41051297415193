// @ts-nocheck

import Label from '../../components/label';
import Iconify from '../../components/iconify';

import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { Alert, AlertTitle, Box, Breadcrumbs, Button, Card, Checkbox, Chip, Container, FormControl, IconButton, InputAdornment, InputLabel, Link, ListItemText, Menu, MenuItem, Paper, Select, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import SimpleBar from "simplebar-react";
import { fetcher } from '../../api';
import useSWR from 'swr';
import { TITLE } from '../../constant';


// ---

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}
  
type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
		order: Order,
		orderBy: Key,
	): (
		a: { [key in Key]: number | string },
		b: { [key in Key]: number | string },
	) => number {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => (_user.lastName  + ' ' + _user.firstName).toLowerCase().indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}

// ---

const FilterStack = styled(Stack)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		alignItems: 'center',
		paddingRight: '8px',
		webkitBoxAlign: 'center',
		gap: '16px',
		flexDirection: 'row',
	},
	[theme.breakpoints.down('md')]: {
		paddingRight: '20px',
		alignItems: 'flex-end',
		flexDirection: 'column',
		gap: '16px',
	},
	padding: '20px',
	display: 'flex',
}));

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

// ---

interface HeadCell {
	disablePadding: boolean;
	id: keyof Data;
	label: string;
	numeric: boolean;
}

const headCells: HeadCell[] = [
	{ id: 'name', numeric: false, disablePadding: true, label: 'Nom' },
	{ id: 'pupitre', numeric: false, disablePadding: false, label: 'Email' },
	{ id: 'status', numeric: false, disablePadding: false, label: 'Statut' },
	{ id: 'created', numeric: false, disablePadding: false, label: 'Création & modification' },
];


interface EnhancedTableProps {
	numSelected: number;
	onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void;
	onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
}

function CustomTableHead(props: EnhancedTableProps) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell 
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={{ fontWeight: '600', fontSize: '0.875rem' }}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell sx={{ fontWeight: '600', fontSize: '0.875rem' }}></TableCell>
			</TableRow>
		</TableHead>
	)
}

// --- 

function Users() {
	const { data, error, isLoading } = useSWR('/users', fetcher);
	const [order, setOrder] = useState<Order>('asc');
	const [orderBy, setOrderBy] = useState<keyof Data>('calories');
	const [selected, setSelected] = useState<readonly string[]>([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [filterName, setFilterName] = useState('');

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	let rows = []
	if (!isLoading && data) {
		rows = data.sort(
			(a, b) => (a.last_name + ' ' + a.first_name).localeCompare(b.last_name + ' ' + b.first_name)
		).map((user) => ({
			id: user.id,
			firstName: user.first_name,
			lastName: user.last_name,
			email: user.email,
			emailVerified: Boolean(user.email_verified_at),
			disabled: Boolean(user.disabled),
			created: user.created_at,
			updated: user.updated_at,
		}));
	}

	const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
	  setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
	  setAnchorEl(null);
	};

	const handleGlobalPrint = () => {
		console.log('print');
	};
	const handleGlobalExport = () => {
		console.log('export');
	};

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof Data,
	) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};
	const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly string[] = [];
	
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
	
		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFilterByName = (event) => {
		setPage(0);
		setFilterName(event.target.value);
	};
	
	const handleChange = (event: SelectChangeEvent<typeof instrumentIdFilter>) => {
		const {
			target: { value },
		} = event;
	};

	let stateDisabled = 0;
	let stateNotDisabled = 0;

	let stateVerified = 0;
	let stateNotVerified = 0;
	rows.forEach((row) => {
		switch (row.disabled) {
			case true:
				stateDisabled++;
				break;
			case false:
				stateNotDisabled++;
				break;
		}

		switch (row.emailVerified) {
			case true:
				stateVerified++;
				break;
			case false:
				stateNotVerified++;
				break;
		}
	});

	const isSelected = (id: string) => selected.indexOf(id) !== -1;
	const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

	const isNotFound = !filteredUsers.length && !!filterName;

	return (
		<>
			<Helmet>
				<title>Utilisateurs { TITLE }</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Box>
							<Typography variant="h4" gutterBottom>
								Utilisateurs
							</Typography>
							<Breadcrumbs separator="›" aria-label="breadcrumb">
								<Link underline="hover" color="inherit" href="/">
									Accueil
								</Link>
								<Typography color="text.primary">Utilisateurs</Typography>
							</Breadcrumbs>
						</Box>
					</Stack>
				</HeaderTitle>

				{error && (
					<Alert severity="error" sx={{ my: 3 }}>
						<AlertTitle>Erreur d'actualisation</AlertTitle>
						Les données les plus récentes n'ont pas pu être récupérées. Les données affichées peuvent être obsolètes.
					</Alert>
				)}

				<Paper 
					elevation={0}
					rounded
				>
					<Card>
						<Tabs>
							<Tab label="Tous" iconPosition="end" icon={
								<Label className="active" color="primary" sx={{ ml: 1 }}>{rows.length}</Label>
							} />
							<Tab label="Actifs" iconPosition="end" icon={
								<Label color="success" sx={{ ml: 1 }}>{stateNotDisabled}</Label>
							} />
							<Tab label="Suspendus" iconPosition="end" icon={
								<Label color="error" sx={{ ml: 1 }}>{stateDisabled}</Label>
							} />
							<Tab label="Vérifié" iconPosition="end" icon={
								<Label color="success" sx={{ ml: 1 }}>{stateVerified}</Label>
							} />
							<Tab label="Non vérifié" iconPosition="end" icon={
								<Label color="error" sx={{ ml: 1 }}>{stateNotVerified}</Label>
							} />
						</Tabs>
						<FilterStack justifyContent="space-between" p={2} gap={"20px"}>
							<FormControl 
								sx={{
									'@media (max-width: 900px)': {
										width: '100%',
									},
									'@media (min-width: 900px)': {
										width: '200px',
									},
								}}
							>
								<InputLabel htmlFor="pupitre">Pupitres</InputLabel>
								<Select
									variant="outlined"
									label="Pupitres"
									multiple
									id="pupitre"
									onChange={handleChange}
									renderValue={(selected) => selected.join(', ')}
									MenuProps={MenuProps}
								>
									
								</Select>
							</FormControl>
							<Stack sx={{width: '100%'}} direction="row" alignItems="center" justifyContent="space-between" gap={'16px'}>
								<TextField
									fullWidth
									id="search"
									placeholder="Rechercher..."
									variant="outlined"
									autoComplete="off"
									value={filterName}
          							onChange={handleFilterByName}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Iconify icon="eva:search-fill" />
											</InputAdornment>
										),
									}}
								/>
								<IconButton
									id="more-filter-btn"
									onClick={handleOpen}
								>
									<Iconify icon="eva:more-vertical-fill" />
								</IconButton>
								<Menu
									id="menu-more-filter-btn"
									anchorEl={anchorEl}
									open={Boolean(anchorEl)}
									onClose={handleClose}
									MenuListProps={{
										'aria-labelledby': 'menu-more-filter-btn'
									}}
								>
									<MenuItem onClick={handleGlobalPrint}>
										<Iconify icon="solar:printer-minimalistic-bold" mr={'16px'} />
										Imprimer
									</MenuItem>
									<MenuItem onClick={handleGlobalExport}>
										<Iconify icon="solar:export-bold" mr={'16px'} />
										Exporter
									</MenuItem>
								</Menu>
							</Stack>
						</FilterStack>
						{false && (<Stack
							direction="column"
							gap={2}
							sx={{
								paddingRight: '20px',
								paddingBottom: '20px',
								paddingTop: '0px',
								paddingLeft: '20px',
							}}
						>
							<Box
								sx={{
									lineHeight: '1.5',
									fontSize: '0.875rem',
									fontWeight: '400',
								}}
							>
								<strong>1</strong> résultat trouvé
							</Box>
							<Stack
								flexDirection={'row'}
								flexWrap={'wrap'}
								gap={2}
								flexGrow={1}
								alignItems="center"
							>
								<Paper
									sx={{
										border: '1px dashed rgba(145, 158, 171, 0.16)',
										padding: '8px',
										borderRadius: '8px',
									}}
								>
									<Stack
										direction="row"
										gap={'8px'}
									>
										<Typography sx={{ fontWeight: '600', fontSize: '0.875rem' }}>Statut : </Typography>
										<Stack>
											<Chip label="Actif" color="success" />
										</Stack>
									</Stack>
								</Paper>
								<Button color="error">
									<Iconify icon="solar:trash-bin-trash-bold" 
										sx={{
											marginRight: '8px',
											marginLeft: '-4px',
										}}
									/>
									Supprimer
								</Button>
							</Stack>
						</Stack> )}
						<TableContainer>
							<div style={{
								height: '100%',
								overflow: 'hidden'
							}}>
								<SimpleBar>
									<Table>
										<CustomTableHead  
											numSelected={selected.length}
											order={order}
											orderBy={orderBy}
											onSelectAllClick={handleSelectAllClick}
											onRequestSort={handleRequestSort}
											rowCount={rows.length}
										/>
										<TableBody>
											{filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
												const isItemSelected = isSelected(row.id);
												const labelId = `user-checkbox-${index}`;

												return (
													<TableRow
														hover
														onClick={(event) => handleClick(event, row.id)}
														aria-checked={isItemSelected}
														role="checkbox"
														tabIndex={-1}
														key={row.id}
														selected={isItemSelected}
														sx={{
															whiteSpace: 'nowrap',
															cursor: 'pointer',
														}}
													>
														<TableCell padding="checkbox">
															<Checkbox
																color="primary"
																checked={isItemSelected}
																inputProps={{
																	'aria-labelledby': labelId,
																}}
															/>
														</TableCell>

														<TableCell component="th" id={labelId} scope="row">
															<Typography variant="body1" sx={{ fontWeight: 400, fontSize: '0.875rem' }}>{ row.lastName } { row.firstName }</Typography>
														</TableCell>

														<TableCell>
															<ListItemText sx={{
																margin: 0,
																flexGrow: 1,
																flexShrink: 1,
																flexBasis: 'auto',
																minWidth: 0,
															}}>
																<Typography variant="body1" sx={{ fontWeight: 400, fontSize: '0.875rem' }}>
																	<Link underline='hover' color='inherit' href={'mailto:' + row.email}>
																		{ row.email }
																	</Link>
																</Typography>
																<Label color={row.emailVerified ? 'success' : 'error'}>{ row.emailVerified ? 'Vérifié' : 'En attente de vérification' }</Label>
															</ListItemText>
														</TableCell>

														<TableCell>
															<Label color={row.disabled ? 'warning' : 'success'}>{row.disabled ? 'Suspendu' : 'Actif'}</Label>
														</TableCell>

														<TableCell>
															<ListItemText sx={{
																margin: 0,
																flexGrow: 1,
																flexShrink: 1,
																flexBasis: 'auto',
																minWidth: 0,
															}}>
																<Typography variant="body1" sx={{ fontWeight: 400, fontSize: '0.875rem' }}>{ new Date(row.created).toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
																<Typography variant="body2" sx={{ color: 'grey.500', fontWeight: 400, fontSize: '0.875rem' }}>
																	{ new Date(row.updated).toLocaleTimeString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
																</Typography>
															</ListItemText>
														</TableCell>

														<TableCell sx={{
															paddingTop: '16px',
															paddingBottom: '16px',
															paddingLeft: '8px',
															paddingRight: '8px',
														}}>
															<Tooltip title="Voir en détail">
																<IconButton size="small" onClick={() => handleViewuser(row.uuid)}>
																	<Iconify icon="solar:eye-bold" />
																</IconButton>
															</Tooltip>
															<Tooltip title="Modifier">
																<IconButton size="small" onClick={() => handleEditPerson(row.uuid)}>
																	<Iconify icon="solar:pen-bold" />
																</IconButton>
															</Tooltip>
															<IconButton size="small" onClick={() => handleMorePerson(row.uuid)}>
																<Iconify icon="eva:more-vertical-fill" />
															</IconButton>											
														</TableCell>

													</TableRow>
												);
											})}
										</TableBody>

										{isNotFound && (
											<TableBody>
												<TableRow>
												<TableCell align="center" colSpan={8} sx={{ py: 3 }}>
													<Paper
													sx={{
														textAlign: 'center',
													}}
													>
													<Typography variant="h6" paragraph>
														Aucun résultat
													</Typography>

													<Typography variant="body2">
														Aucun résultat trouvé pour &nbsp;
														<strong>&quot;{filterName}&quot;</strong>.
														<br /> Essayez de vérifier les fautes d'orthographe ou de vérifier les filtres.
													</Typography>
													</Paper>
												</TableCell>
												</TableRow>
											</TableBody>
										)}

										{!isNotFound && !rows.length && (
											<TableBody>
												<TableRow>
													<TableCell align="center" colSpan={8} sx={{ py: 3 }}>
														<Paper
															sx={{
																textAlign: 'center',
															}}
														>
															<Typography variant="h6" paragraph>
																Aucune donnée
															</Typography>
														</Paper>
													</TableCell>
												</TableRow>
											</TableBody>
										)}
									</Table>
								</SimpleBar>
							</div>
						</TableContainer>
						<Box>
							<TablePagination 
								rowsPerPageOptions={[10, 25, 50, 100]}
								component="div"
								count={rows.length}
								rowsPerPage={rowsPerPage}
								page={page}
								labelRowsPerPage="Lignes par page :"
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								labelDisplayedRows={({ from, to, count }) => { 
									return `Lignes ${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`; 
								}}
							/>
						</Box>
					</Card>
				</Paper>
			</Container>
		</>
	);
}

export default Users;