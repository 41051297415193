import { Alert, AlertTitle, Avatar, AvatarGroup, Box, Breadcrumbs, Button, Card, Checkbox, Chip, Container, Dialog, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, Link, ListItemText, Menu, MenuItem, Paper, Select, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip, Typography, styled } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Iconify from "../../components/iconify/Iconify";
import SimpleBar from "simplebar-react";
import useSWR from 'swr';
import { fetcher, instance } from "../../api";
import { ChangeEvent, MouseEvent, useState } from "react";
import Label from "../../components/label/Label";
import Icon from "../../components/color-utils/Icon";
import { capitalCaseTransform } from "change-case";
import { TITLE } from "../../constant";
import { enqueueSnackbar } from "notistack";
import { retrieveErrorMessages } from "../../utils/errorsDisplay";

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

const FilterStack = styled(Stack)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		alignItems: 'center',
		paddingRight: '8px',
		webkitBoxAlign: 'center',
		gap: '16px',
		flexDirection: 'row',
	},
	[theme.breakpoints.down('md')]: {
		paddingRight: '20px',
		alignItems: 'flex-end',
		flexDirection: 'column',
		gap: '16px',
	},
	padding: '20px',
	display: 'flex',
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

// ---

function AddPresenceDialog({ open, handleClose }: { open: boolean, handleClose: () => void }) {
	const [isAllDay, setIsAllDay] = useState(false);
	const [isLocked, setIsLocked] = useState(false);
	const [isCancelled, setIsCancelled] = useState(false);
	const [type, setType] = useState(0);
	const [showMoreOptions, setShowMoreOptions] = useState(false);

	const publish = async () => {
		const name = (document.getElementById('name') as HTMLInputElement).value;
		const description = (document.getElementById('description') as HTMLInputElement).value;
		const date = (document.getElementById('date') as HTMLInputElement).value;
		const startTime = (document.getElementById('start-time') as HTMLInputElement).value;
		const endTime = (document.getElementById('end-time') as HTMLInputElement).value;
		const location = (document.getElementById('location') as HTMLInputElement).value;
		const notes = document.getElementById('notes') ? (document.getElementById('notes') as HTMLInputElement).value : null ;
		const program = document.getElementById('program') ? (document.getElementById('program') as HTMLInputElement).value : null;

		let pType;
		if (type === 0) pType = 'vie-asso';
		else if (type === 1) pType = 'prestation';
		else if (type === 2) pType = 'repetition';
		else if (type === 3) pType = 'other';

		const data = {
			name,
			description,
			date,
			start_time: startTime,
			end_time: endTime,
			location,
			notes,
			program: program != null ? JSON.stringify(program.split(',').map((item: string) => item.trim())) : null,
			all_day: isAllDay ? 'true' : 'false',
			locked: isLocked ? 'true' : 'false',
			cancelled: isCancelled ? 'true' : 'false',
			type: pType
		};

		if (name === '' || date === '' || location === '') {
			enqueueSnackbar('Veuillez remplir tous les champs obligatoires.', {
				variant: 'error',
			});
			handleClose();
			return;
		}

		await instance.post('/attendance', data).then((res) => {
			enqueueSnackbar('Présence ' + res.data.name + ' ajoutée avec succès.', {
				variant: 'success',
			});
		}).catch((err) => {
			enqueueSnackbar(retrieveErrorMessages(err.response?.data.error, err.response?.data.message, 'Une erreur est survenue lors de l\'ajout de la présence.'), { variant: 'error' });
		})

		handleClose();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="md"
		>
			<SimpleBar>
				<Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
					<Typography variant="h6" fontWeight={600}>
						Ajouter une présence
					</Typography>
					<IconButton onClick={handleClose}>
						<Iconify icon="eva:close-fill" />
					</IconButton>
				</Stack>

				<Stack p={2} gap={2}>
					<TextField
						fullWidth
						id="name"
						label="Nom de l'événement"
						variant="outlined"
						autoComplete="off"
					/>
					<TextField
						fullWidth
						id="description"
						label="Description"
						variant="outlined"
						autoComplete="off"
						multiline
						rows={4}
					/>
					<Stack 
						direction={{ xs: 'column', md: 'row' }}
						alignItems={{ xs: 'flex-start', md: 'center' }} 
						justifyContent="space-between" 
						gap={2}
					>
						<TextField
							id="date"
							label="Date"
							type="date"
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							autoComplete="off"

						/>
						<TextField
							id="start-time"
							label="Heure de début"
							type="time"
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							disabled={isAllDay}
							variant="outlined"
							autoComplete="off"
						/>
						<TextField
							id="end-time"
							label="Heure de fin"
							type="time"
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							disabled={isAllDay}
							variant="outlined"
							autoComplete="off"
						/>
						<FormControlLabel
							sx={{ width: '100%' }}
							control={<Checkbox />}
							label="Toute la journée"
							// @ts-ignore
							onChange={(e: ChangeEvent<{ checked: boolean }>) => setIsAllDay(e.target.checked)}
						/>
					</Stack>
					<TextField
						fullWidth
						id="location"
						label="Lieu"
						variant="outlined"
						autoComplete="off"
					/>
					<FormControl fullWidth variant="outlined">
						<InputLabel id="type-select-label">Type</InputLabel>
						<Select
							labelId="type-select-label"
							id="type-select"
							label="Type"
							value={type}
							MenuProps={MenuProps}
							// @ts-ignore
							onChange={(e: ChangeEvent<{ value: unknown }>) => setType(e.target.value as number)}
						>
							<MenuItem key={0} value={0}>
								Vie de l'Orchestre
							</MenuItem>
							<MenuItem key={1} value={1}>
								Prestation
							</MenuItem>
							<MenuItem key={2} value={2}>
								Répétition
							</MenuItem>
							<MenuItem key={3} value={3}>
								Autre
							</MenuItem>
						</Select>
					</FormControl>

					<Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} sx={{ cursor: 'pointer' }} onClick={() => setShowMoreOptions(!showMoreOptions)}>
						<Typography
							variant="body2"
							fontWeight={300}
							color="text.secondary"
							sx={{
								whiteSpace: 'nowrap',
							}}
						>
							Plus d'options...
						</Typography>
						<hr 
							style={{
								borderColor: '#63738163',
								height: '1px',
								borderStyle: 'dashed',
								width: '100%',
							}}
						/>
					</Stack>

					{showMoreOptions && (
							<Stack 
								sx={{
									animation: 'fadeIn 0.3s ease-in-out',
								}} 
								direction="column" 
								gap={2}
							>
								<TextField
									fullWidth
									id="notes"
									label="Notes (seulement visibles par le bureau)"
									variant="outlined"
									autoComplete="off"
									multiline
									rows={4}
								/>
								<TextField
									fullWidth
									id="program"
									label="Programme"
									placeholder="Renseigner le programme dans l'ordre en séparant les morceaux par des virgules. Ex: Symphonie n°9, Concerto pour violon, ..."
									variant="outlined"
									autoComplete="off"
									multiline
									rows={4}
								/>
							</Stack>
						)
					}

					<Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
						<Stack 
							alignItems="center" 
							gap={{ xs: 0, md: 2 }}
							direction={{ xs: 'column', md: 'row' }}
						>
							<FormControlLabel
								control={<Checkbox />}
								label="Verrouiller l'événement"
								// @ts-ignore
								onChange={(e: ChangeEvent<{ checked: boolean }>) => setIsLocked(e.target.checked)}
							/>
							<FormControlLabel
								control={<Checkbox />}
								label="Enregistrer comme annulé"
								// @ts-ignore
								onChange={(e: ChangeEvent<{ checked: boolean }>) => setIsCancelled(e.target.checked)}
							/>
						</Stack>
						<Button variant="contained" onClick={publish}>Valider</Button>
					</Stack>
				</Stack>
			</SimpleBar>
		</Dialog>
	);
}

// ---

function AttendancesManagement() {
	const { data, error, isLoading } = useSWR('/attendance-admin', fetcher);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const [showPassedPresences, setShowPassedPresences] = useState(false);
	const [typeFilter, setTypeFilter] = useState(0); // 0 = all, 1 = vie de l'orchestre, 2 = prestations
	const [search, setSearch] = useState('');
	const [addPresenceDialogOpen, setAddPresenceDialogOpen] = useState(false);

	const loader = Array(6).fill(0);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const openDetails = (uuid: string) => {
		window.location.href = '/attendance-management/' + uuid;
	};

	let rows = data?.data.filter((row: any) => {
		if (search === '') return true;
		return row.attendance.name.toLowerCase().includes(search.toLowerCase());
	});

	rows = rows?.filter((row: any) => {
		const today = new Date().setHours(0, 0, 0, 0) - 1;

		if (!showPassedPresences && new Date(row.attendance.date) <= new Date(today)) return false;
		
		if (typeFilter === 1 && row.attendance.type !== 'vie-asso') return false;
		if (typeFilter === 2 && row.attendance.type !== 'prestation') return false;
		if (typeFilter === 3 && row.attendance.type !== 'repetition') return false;
		if (typeFilter === 4 && row.attendance.type !== 'autre') return false;
		return true;
	});

	return (
		<>
			<Helmet>
				<title>Gestion des présences { TITLE }</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Box>
							<Typography variant="h4" gutterBottom>
								Gestion des présences
							</Typography>
							<Breadcrumbs separator="›" aria-label="breadcrumb">
								<Link underline="hover" color="inherit" href="/">
									Accueil
								</Link>
								<Link underline="hover" color="inherit" href="/attendance">
									Présences
								</Link>
								<Typography color="text.primary">Gestion</Typography>
							</Breadcrumbs>
						</Box>
						<Box>
							<Button 
								variant="contained"
								onClick={() => setAddPresenceDialogOpen(true)}
							>
								{/* @ts-ignore */}
								<Iconify icon="mingcute:add-line" mr={1} /> Ajouter une présence
							</Button>
						</Box>
					</Stack>
				</HeaderTitle>

				{error && (
					<Alert severity="error" sx={{ my: 3 }}>
						<AlertTitle>Erreur d'actualisation</AlertTitle>
						Les données les plus récentes n'ont pas pu être récupérées. Les données affichées peuvent être obsolètes.
					</Alert>
				)}

				<Paper>
					<Card>
						<FilterStack justifyContent="space-between" p={2} gap={"20px"}>
							<FormControl 
								sx={{
									'@media (max-width: 900px)': {
										width: '100%',
									},
									'@media (min-width: 900px)': {
										width: '200px',
									},
								}}
							>
								<InputLabel htmlFor="typeSelect">Type</InputLabel>
								<Select
									variant="outlined"
									label="Type"
									id="typeSelect"
									value={typeFilter}
									// @ts-ignore
									onChange={(e: ChangeEvent<{ value: unknown }>) => setTypeFilter(e.target.value as number)}
								>
									<MenuItem key={0} value={0}>
										Tous
									</MenuItem>
									<MenuItem key={1} value={1}>
										Vie de l'Orchestre
									</MenuItem>
									<MenuItem key={2} value={2}>
										Prestation
									</MenuItem>
									<MenuItem key={3} value={3}>
										Répétition
									</MenuItem>
									<MenuItem key={4} value={4}>
										Autre
									</MenuItem>
								</Select>
							</FormControl>
							<FormControl 
								sx={{
									'@media (max-width: 900px)': {
										width: '100%',
									},
									'@media (min-width: 900px)': {
										width: '200px',
									},
								}}
							>
								<InputLabel htmlFor="passedSelect">Événements passés</InputLabel>
								<Select
									variant="outlined"
									label="Événements passés"
									id="passedSelect"
									value={showPassedPresences ? 1 : 0}
									// @ts-ignore
									onChange={(e: ChangeEvent<{ value: unknown }>) => setShowPassedPresences(e.target.value as boolean)}
								>
									<MenuItem key={0} value={0}>
										Non
									</MenuItem>
									<MenuItem key={1} value={1}>
										Oui
									</MenuItem>
								</Select>
							</FormControl>							
							<Stack sx={{width: '100%'}} direction="row" alignItems="center" justifyContent="space-between" gap={'16px'}>
								<TextField
									fullWidth
									id="search"
									placeholder="Rechercher..."
									variant="outlined"
									autoComplete="off"
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Iconify icon="eva:search-fill" />
											</InputAdornment>
										),
									}}
								/>
								<IconButton
									id="more-filter-btn"
									onClick={handleOpen}
								>
									<Iconify icon="eva:more-vertical-fill" />
								</IconButton>
								<Menu
									id="menu-more-filter-btn"
									anchorEl={anchorEl}
									open={Boolean(anchorEl)}
									onClose={handleClose}
									MenuListProps={{
										'aria-labelledby': 'menu-more-filter-btn'
									}}
								>
									<MenuItem onClick={() => null}>
										<Iconify icon="solar:printer-minimalistic-bold" mr={'16px'} />
										Imprimer
									</MenuItem>
									<MenuItem onClick={() => null}>
										<Iconify icon="solar:export-bold" mr={'16px'} />
										Exporter
									</MenuItem>
								</Menu>
							</Stack>
						</FilterStack>
						<TableContainer>
							<div style={{
								height: '100%',
								overflow: 'hidden'
							}}>
								<SimpleBar>
									<Table>
										<TableBody>
											{!isLoading && rows?.map((row: any) => {
												let attendanceType;
												if (row.attendance.type === 'vie-asso') attendanceType = 'Vie de l\'Orchestre';
												else if (row.attendance.type === 'prestation') attendanceType = 'Prestation';
												else if (row.attendance.type === 'repetition') attendanceType = 'Répétition';
												else if (row.attendance.type === 'other') attendanceType = 'Autre';

												return (
													<TableRow
														hover
														role="checkbox"
														tabIndex={-1}
														sx={{
															whiteSpace: 'nowrap',
															cursor: 'pointer',
														}}
														key={row.id}
														onClick={() => openDetails(row.attendance.uuid)}
													>
														<TableCell align="left" sx={{minWidth: 0, width: 'fit-content'}}>
															{row.attendance.locked ? (<Tooltip title="Verrouillé" placement="bottom">
																<Iconify sx={{color: 'error.main'}} icon="eva:lock-fill" />
															</Tooltip>) : null}
															{row.attendance.cancelled ? (<Tooltip title="Annulé" placement="bottom">
																<Iconify sx={{color: 'error.main'}} icon="eva:backspace-fill" />
															</Tooltip>) : null}
														</TableCell>
														<TableCell align="left" sx={{paddingLeft: 0}}>
															<Typography variant="body1" fontWeight={600}>
																{ row.attendance.name }
															</Typography>
														</TableCell>

														<TableCell align="left">
															{/* @ts-ignore */}
															<Label color="default">
																{ attendanceType }
															</Label>
														</TableCell>

														<TableCell>
															{capitalCaseTransform(new Date(row.attendance.date).toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }))}
														</TableCell>

														<TableCell>
															{ row.attendance.all_day ? 'Toute la journée' : row.attendance.start_time.replace(':', 'h').split(':')[0] + ' - ' + row.attendance.end_time.replace(':', 'h').split(':')[0]}
														</TableCell>

														<TableCell>
															{ row.attendance.location }
														</TableCell>

														<TableCell>
															<span style={{fontWeight: 800}}>{ row.response.numAttending > 0 ? row.response.numAttending : 'Aucun' }</span> { row.response.numAttending > 1 ? 'présents' : 'présent' }
														</TableCell>
														
														{/* <TableCell 
															align="right"
															sx={{
																paddingTop: '16px',
																paddingBottom: '16px',
																paddingLeft: '8px',
																paddingRight: '8px',
														}}>
															<IconButton size="small" onClick={() => null}>
																<Iconify icon="eva:more-vertical-fill" />
															</IconButton>											
														</TableCell> */}
													</TableRow>
											)})}

											{!isLoading && data?.data.length > 0 && rows?.length === 0 && (
												<TableRow>
													<TableCell align="center" colSpan={8} sx={{ py: 3 }}>
														<Paper
															sx={{
																textAlign: 'center',
															}}
														>
															<Typography variant="h6" paragraph>
																Aucune présence ne correspond à votre recherche
															</Typography>
														</Paper>
													</TableCell>
												</TableRow>
											)}

											{isLoading && loader.map((row) => {
												return (
													<TableRow key={row}>
														<TableCell align="left" sx={{minWidth: 0, width: 'fit-content', display: 'flex', gap: 1}}>
															<Skeleton variant="circular" width={24} height={24}/>
															<Skeleton variant="circular" width={24} height={24} />
														</TableCell>
														<TableCell align="left" sx={{paddingLeft: 0}}>
															<Skeleton variant="text" width={200} height={24} />
														</TableCell>

														<TableCell align="left">
															<Skeleton variant="text" width={100} height={24} />
														</TableCell>

														<TableCell>
															<Skeleton variant="text" width={100} height={24} />
														</TableCell>

														<TableCell>
															<Skeleton variant="text" width={100} height={24} />
														</TableCell>

														<TableCell>
															<Skeleton variant="text" width={100} height={24} />
														</TableCell>

														<TableCell>
															<Skeleton variant="text" width={100} height={24} />
														</TableCell>
														
														<TableCell 
															align="right"
															sx={{
																paddingTop: '16px',
																paddingBottom: '16px',
																paddingLeft: '8px',
																paddingRight: '8px',
																display: 'flex',
																gap: 1,
														}}>
															<Skeleton variant="circular" width={24} height={24} />
															<Skeleton variant="circular" width={24} height={24} />
															<Skeleton variant="circular" width={24} height={24} />							
														</TableCell>
													</TableRow>
												)
											})}
										</TableBody>

										{!isLoading && !data?.data.length && (
											<TableBody>
												<TableRow>
													<TableCell align="center" colSpan={8} sx={{ py: 3 }}>
														<Paper
															sx={{
																textAlign: 'center',
															}}
														>
															<Typography variant="h6" paragraph>
																Aucune présence de créée
															</Typography>
														</Paper>
													</TableCell>
												</TableRow>
											</TableBody>
										)}
									</Table>
								</SimpleBar>
							</div>
						</TableContainer>
					</Card>
				</Paper>

				<AddPresenceDialog 
					open={addPresenceDialogOpen}
					handleClose={() => setAddPresenceDialogOpen(false)}
				/>
			</Container>
		</>
	);
}

export default AttendancesManagement;