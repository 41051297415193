// @ts-nocheck
import { useParams } from "react-router";
import useSWR from "swr";
import { fetcher, instance } from "../../api";
import { Helmet } from "react-helmet-async";
import { Alert, Box, Breadcrumbs, Button, Card, CardContent, CardHeader, Container, Grid, Icon, IconButton, Link, Skeleton, Stack, SvgIcon, TextField, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { RESPONSE_VALUES, TITLE } from "../../constant";
import SvgColor from "../../components/svg-color";
import Iconify from "../../components/iconify";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { retrieveErrorMessages } from "../../utils/errorsDisplay";
import Chart, { useChart } from "../../components/chart";
import palette from "../../theme/palette";
import Label from "../../components/label";

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

// ---

const chartOrder = [1, 3, 4, 2, 0]
const chartColor = chartOrder.map((index) => palette[RESPONSE_VALUES[index][1].split('.')[0]][RESPONSE_VALUES[index][1].split('.')[1]])
const chartLabel = chartOrder.map((index) => RESPONSE_VALUES[index][0])

function getChartOptions(centerLabel: string) {
	return {
		labels: chartLabel,
		colors: chartColor,
		chart: { type: 'donut', height: 300, stacked: true },
		legend: { show: false },
		plotOptions: {
			pie: {
				donut: {
					labels: {
						total: {
							show: true,
							label: centerLabel,
						}
					}
				}
			}
		}
	}
}

function AllTimeChartsCard({ statistics }) {
	const dTotal = [
		statistics.responses.raw.present,
		statistics.responses.raw.ifNeeded,
		statistics.responses.raw.dontKnow,
		statistics.responses.raw.absent,
		statistics.responses.raw.noResponse,
	]

	const dPrestation = [
		statistics.allAttendances.memberPresent.prestation,
		statistics.allAttendances.memberIfNeeded.prestation,
		statistics.allAttendances.memberDontKnow.prestation,
		statistics.allAttendances.memberAbsent.prestation,
		statistics.allAttendances.memberNoResponse.prestation,
	]

	const dRepetition = [
		statistics.allAttendances.memberPresent.repetition,
		statistics.allAttendances.memberIfNeeded.repetition,
		statistics.allAttendances.memberDontKnow.repetition,
		statistics.allAttendances.memberAbsent.repetition,
		statistics.allAttendances.memberNoResponse.repetition,
	]

	const dVie = [
		statistics.allAttendances.memberPresent.vieAsso,
		statistics.allAttendances.memberIfNeeded.vieAsso,
		statistics.allAttendances.memberDontKnow.vieAsso,
		statistics.allAttendances.memberAbsent.vieAsso,
		statistics.allAttendances.memberNoResponse.vieAsso,
	]

	const dAutres = [
		statistics.allAttendances.memberPresent.other,
		statistics.allAttendances.memberIfNeeded.other,
		statistics.allAttendances.memberDontKnow.other,
		statistics.allAttendances.memberAbsent.other,
		statistics.allAttendances.memberNoResponse.other,
	]

	return (
		<Card>
			<CardHeader title="Toutes les présences" />
			
			<CardContent>
				<Grid container justifyContent={{ xs: 'center', md: 'space-between' }}>
					<Grid item xs={12} sm={6} lg={4} xl={2}>
						<Chart options={useChart(getChartOptions('Total'))} series={dTotal} type="donut" height={250} />
					</Grid>

					<Grid item xs={12} sm={6} lg={4} xl={2}>
						<Chart options={useChart(getChartOptions('Prestations'))} series={dPrestation} type="donut" height={250} />
					</Grid>

					<Grid item xs={12} sm={6} lg={4} xl={2}>
						<Chart options={useChart(getChartOptions('Répétitions'))} series={dRepetition} type="donut" height={250} />
					</Grid>

					<Grid item xs={12} sm={6} lg={4} xl={2}>
						<Chart options={useChart(getChartOptions('Vie Asso.'))} series={dVie} type="donut" height={250} />
					</Grid>

					<Grid item xs={12} md={6} lg={4} xl={2}>
						<Chart options={useChart(getChartOptions('Autres'))} series={dAutres} type="donut" height={250} />
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
}

function PassedChartsCard({ statistics }) {
	const chartOptions = useChart({
		labels: chartLabel,
		colors: chartColor,
		chart: { type: 'donut', height: 300, stacked: true },
		legend: { show: false },
	});

	const dTotal = [
		statistics.responses.passed.present,
		statistics.responses.passed.ifNeeded,
		statistics.responses.passed.dontKnow,
		statistics.responses.passed.absent,
		statistics.responses.passed.noResponse,
	]

	const dPrestation = [
		statistics.passedAttendances.memberPresent.prestation,
		statistics.passedAttendances.memberIfNeeded.prestation,
		statistics.passedAttendances.memberDontKnow.prestation,
		statistics.passedAttendances.memberAbsent.prestation,
		statistics.passedAttendances.memberNoResponse.prestation,
	]

	const dRepetition = [
		statistics.passedAttendances.memberPresent.repetition,
		statistics.passedAttendances.memberIfNeeded.repetition,
		statistics.passedAttendances.memberDontKnow.repetition,
		statistics.passedAttendances.memberAbsent.repetition,
		statistics.passedAttendances.memberNoResponse.repetition,
	]

	const dVie = [
		statistics.passedAttendances.memberPresent.vieAsso,
		statistics.passedAttendances.memberIfNeeded.vieAsso,
		statistics.passedAttendances.memberDontKnow.vieAsso,
		statistics.passedAttendances.memberAbsent.vieAsso,
		statistics.passedAttendances.memberNoResponse.vieAsso,
	]

	const dAutres = [
		statistics.passedAttendances.memberPresent.other,
		statistics.passedAttendances.memberIfNeeded.other,
		statistics.passedAttendances.memberDontKnow.other,
		statistics.passedAttendances.memberAbsent.other,
		statistics.passedAttendances.memberNoResponse.other,
	]

	return (
		<Card>
			<CardHeader title="Présences passées" />
			
			<CardContent>
				<Grid container justifyContent={{ xs: 'center', md: 'space-between' }}>
					<Grid item xs={12} sm={6} lg={4} xl={2}>
						<Chart options={useChart(getChartOptions('Total'))} series={dTotal} type="donut" height={250} />
					</Grid>

					<Grid item xs={12} sm={6} lg={4} xl={2}>
						<Chart options={useChart(getChartOptions('Prestations'))} series={dPrestation} type="donut" height={250} />
					</Grid>

					<Grid item xs={12} sm={6} lg={4} xl={2}>
						<Chart options={useChart(getChartOptions('Répétitions'))} series={dRepetition} type="donut" height={250} />
					</Grid>

					<Grid item xs={12} sm={6} lg={4} xl={2}>
						<Chart options={useChart(getChartOptions('Vie Asso.'))} series={dVie} type="donut" height={250} />
					</Grid>

					<Grid item xs={12} md={6} lg={4} xl={2}>
						<Chart options={useChart(getChartOptions('Autres'))} series={dAutres} type="donut" height={250} />
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
}

function MemberDetails() {
	const { uuid } = useParams<{ uuid: string }>();
	const { data, error, isLoading } = useSWR(`/members/${uuid}`, fetcher);

	const [btnUnavLoading, setBtnUnavLoading] = useState(false);
	const [btnCotLoading, setBtnCotLoading] = useState(false);

	if (error) return <div>Error</div>;

	return (
		<>
			<Helmet>
				<title>{isLoading ? 'Chargement...' : `${data.infos.last_name} ${data.infos.first_name}`} { TITLE }</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
						<Box>
							<Typography variant="h4" gutterBottom>
								{isLoading ? (<Skeleton variant="text" width={400} height={36} />) : `${data.infos.last_name} ${data.infos.first_name}`}
							</Typography>
							<Breadcrumbs separator="›" aria-label="breadcrumb">
								<Link underline="hover" color="inherit" href="/">
									Accueil
								</Link>
								<Link underline="hover" color="inherit" href="/members">
									Membres
								</Link>
								<Typography color="text.primary">{isLoading ? (<Skeleton variant="text" width={150} height={24} />) : `${data.infos.last_name} ${data.infos.first_name}`}</Typography>
							</Breadcrumbs>
						</Box>
						<Box sx={{
							flexGrow: 1,
						}} />
						<IconButton
							onClick={() => window.print()}
						>
							<Iconify icon="solar:printer-minimalistic-bold" />
						</IconButton>
					</Stack>
				</HeaderTitle>


				{!isLoading && data.infos.unavailable ? (
					<Alert severity="warning" sx={{ marginTop: '40px', marginBottom: 2 }}>
						Ce membre est indisponible pour le moment.
					</Alert>
				) : null}

				<Grid container spacing={2}>
					<Grid item xl={3} md={6}  xs={12}>
						<Card sx={{ p: 2, flexGrow: 1, background: 'linear-gradient(135deg, rgba(255, 214, 102, 0.2), rgba(255, 171, 0, 0.2)) rgb(255, 255, 255)' }}>
							<Stack direction="column" gap={0} alignItems="center" justifyContent="space-between" sx={{ paddingTop: '40px', paddingBottom: '40px' }}>
								{/* <img src="/assets/icons/glass/ic_glass_instrument.png" width={64} height={64} /> */}
								<Typography variant="h3" sx={{ fontSize: '1.625rem' }}>
									{isLoading ? (<Skeleton variant="text" width={400} height={36} />) : `${data.instrument.name}`}
								</Typography>
								<Typography variant="subtitle2">
									Pupitre
								</Typography>
							</Stack>
						</Card>
					</Grid>
					<Grid item xl={3} md={6}  xs={12}>
						<Card sx={{ p: 2, flexGrow: 1, background: 'linear-gradient(135deg, rgba(185, 133, 244, 0.2), rgba(118, 53, 220, 0.2)) rgb(255, 255, 255)' }}>
							<Stack direction="column" gap={0} alignItems="center" justifyContent="space-between" sx={{ paddingTop: '40px', paddingBottom: '40px' }}>
								{/* <img src="/assets/icons/glass/ic_glass_instrument.png" width={64} height={64} /> */}
								<Typography variant="h3" sx={{ fontSize: '1.625rem' }}>
									{isLoading ? (<Skeleton variant="text" width={400} height={36} />) : `${Math.abs(new Date(Date.now() - new Date(data.infos.birthday).getTime()).getUTCFullYear() - 1970)} ans`}
								</Typography>
								<Typography variant="subtitle2">
									Âge
								</Typography>
							</Stack>
						</Card>
					</Grid>
					<Grid item xl={3} md={6}  xs={12}>
						<Card sx={{ p: 2, flexGrow: 1, background: 'linear-gradient(135deg, rgba(104, 205, 249, 0.2), rgba(7, 141, 238, 0.2)) rgb(255, 255, 255)' }}>
							<Stack direction="column" gap={0} alignItems="center" justifyContent="space-between" sx={{ paddingTop: '40px', paddingBottom: '40px' }}>
								{/* <img src="/assets/icons/glass/ic_glass_instrument.png" width={64} height={64} /> */}
								<Typography variant="h3" sx={{ fontSize: '1.625rem' }}>
									{isLoading ? (<Skeleton variant="text" width={400} height={36} />) : `${new Date(data.infos.member_since).toLocaleDateString('fr-FR', { year: 'numeric', month: 'short', day: 'numeric' })}`}
								</Typography>
								<Typography variant="subtitle2">
									Membre depuis
								</Typography>
							</Stack>
						</Card>
					</Grid>
					<Grid item xl={3} md={6}  xs={12}>
						<Card sx={{ p: 2, flexGrow: 1, background: !isLoading ? data.infos.cotisation_paid ? 'linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)) rgb(255, 255, 255)' : 'linear-gradient(135deg, rgba(255, 193, 172, 0.2), rgba(255, 48, 48, 0.2)) rgb(255, 255, 255)' : '#fff' }}>
							<Stack direction="column" gap={0} alignItems="center" justifyContent="space-between" sx={{ paddingTop: '40px', paddingBottom: '40px' }}>
								{/* <img src="/assets/icons/glass/ic_glass_instrument.png" width={64} height={64} /> */}
								<Typography variant="h3" sx={{ fontSize: '1.625rem' }}>
									{isLoading ? (<Skeleton variant="text" width={400} height={36} />) : `${data.infos.cotisation_paid ? 'Payée' : 'Non payée'}`}
								</Typography>
								<Typography variant="subtitle2">
									Cotisation
								</Typography>
							</Stack>
						</Card>
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ marginTop: '40px' }}>
				
					<Grid item xl={9} md={12} xs={12}>
						
						<Card>
							<CardHeader title="Informations personnelles" />

							<CardContent>
								<Stack direction="column" gap={2}>
									<Box>
										<Typography variant="subtitle2">
											Nom
										</Typography>
										<Typography variant="body1">
											{isLoading ? (<Skeleton variant="text" width={400} height={36} />) : `${data.infos.last_name} ${data.infos.first_name}`}
										</Typography>
									</Box>

									<Box>
										<Typography variant="subtitle2">
											Genre
										</Typography>
										<Typography variant="body1">
											{isLoading ? (<Skeleton variant="text" width={400} height={36} />) : `${data.infos.gender === 'male' ? 'Homme' : 'Femme'}`}
										</Typography>
									</Box>

									<Box>
										<Typography variant="subtitle2">
											Adresse
										</Typography>
										<Typography variant="body1">
											{isLoading ? (<Skeleton variant="text" width={400} height={36} />) : `${data.infos.address}, ${data.infos.zip} ${data.infos.city}`}
										</Typography>
									</Box>

									<Box>
										<Typography variant="subtitle2">
											Date de naissance
										</Typography>
										<Typography variant="body1">
											{isLoading ? (<Skeleton variant="text" width={400} height={36} />) : `${new Date(data.infos.birthday).toLocaleDateString('fr-FR', { year: 'numeric', month: 'short', day: 'numeric' })}`}
										</Typography>
									</Box>

									<Box>
										<Typography variant="subtitle2">
											Email
										</Typography>
										<Typography variant="body1">
											{isLoading ? (<Skeleton variant="text" width={400} height={36} />) : `${data.infos.email}`}
										</Typography>
									</Box>

									<Box>
										<Typography variant="subtitle2">
											Téléphone
										</Typography>
										<Typography variant="body1">
											{isLoading ? (<Skeleton variant="text" width={400} height={36} />) : `${data.infos.phone}`}
										</Typography>
									</Box>

									<Box>
										<Typography variant="subtitle2">
											Taille de haut
										</Typography>
										<Typography variant="body1">
											{isLoading ? (<Skeleton variant="text" width={400} height={36} />) : `${data.infos.shirt_size}`}
										</Typography>
									</Box>

									<Box>
										<Typography variant="subtitle2">
											Référence instrument
										</Typography>
										<Typography variant="body1">
											{isLoading ? (<Skeleton variant="text" width={400} height={36} />) : `${data.infos.instrument_reference}`}
										</Typography>
									</Box>
								</Stack>
							</CardContent>
						</Card>

					</Grid>

					<Grid item xl={3} md={12} xs={12}>						
						<Stack direction="column" gap={2}>

							<LoadingButton
								color={!data?.infos.unavailable ? 'warning' : 'success'}
								loading={btnUnavLoading}
								loadingPosition="start"
								variant="contained"
								size="large"
								disabled={isLoading}
								startIcon={
									!data?.infos.unavailable ? <Iconify icon="solar:pause-bold" /> : <Iconify icon="solar:play-bold" />
								}
								onClick={() => {
									setBtnUnavLoading(true);
									
									instance.put(`/members/${uuid}/unavailable`, {
										unavailable: !data?.infos.unavailable
									}).then(() => {
										enqueueSnackbar(`Le membre a bien été ${!data?.infos.unavailable ? 'marqué comme absent' : 'marqué comme présent'}`, { variant: 'success' });
										setBtnUnavLoading(false);
									}).catch((error) => {
										enqueueSnackbar(retrieveErrorMessages(error.response?.data?.code, error.response?.data?.message), { variant: 'error' });
										setBtnUnavLoading(false);
									});
								}}
							>
								{!data?.infos.unavailable ? 'Marquer comme absent' : 'Marquer comme présent' }
							</LoadingButton>

							<LoadingButton
								color={!data?.infos.cotisation_paid ? 'success' : 'error'}
								loading={btnCotLoading}
								loadingPosition="start"
								variant="contained"
								size="large"
								disabled={isLoading}
								startIcon={
									<Iconify icon="solar:euro-bold" />
								}
								onClick={() => {
									setBtnCotLoading(true);

									instance.put(`/members/${uuid}/cotisation`, {
										cotisation_paid: !data?.infos.cotisation_paid
									}).then(() => {
										enqueueSnackbar(`La cotisation a bien été ${!data?.infos.cotisation_paid ? 'marquée comme payée' : 'marquée comme non payée'}`, { variant: 'success' });
										setBtnCotLoading(false);
									}).catch((error) => {
										enqueueSnackbar(retrieveErrorMessages(error.response?.data?.code, error.response?.data?.message), { variant: 'error' });
										setBtnCotLoading(false);
									});
								}}
							>
								{!data?.infos.cotisation_paid ? 'Marquer comme payée' : 'Marquer comme non payée' }
							</LoadingButton>
							
							<Card sx={{ background: theme => theme.palette.error.main, color: 'white' }}>
								<CardHeader title="Contact d'urgence" />

								<CardContent>
									<Typography variant="body1">
										{isLoading ? (<Skeleton variant="text" width={400} height={36} />) : `${data.infos.emergency_contact || 'Aucun'}`}
									</Typography>
								</CardContent>
							</Card>
						</Stack>
					</Grid>

					<Grid item xl={12} md={12} xs={12}>
						<Stack direction="column" gap={2} sx={{ mb: 4 }} >
							<Typography variant="h5" gutterBottom>
								Statistiques détaillées 
							</Typography>

							{isLoading && !error ? (<Skeleton variant="rounded" width={'auto'} height={250} />) : (
								<AllTimeChartsCard statistics={data.statistics} />
							)}
							
							{isLoading && !error ? (<Skeleton variant="rounded" width={'auto'} height={250} />) : (
								<PassedChartsCard statistics={data.statistics} />
							)}
						</Stack>

						<Stack direction="column" gap={2}>
							<Typography variant="h5" gutterBottom>
								Rapport des présences <Label color="info" sx={{ ml: 1 }}>{isLoading ? (<Skeleton variant="text" width={150} height={24} />) : `Total : ${data.statistics.allAttendances.totalAttendances}`}</Label>
							</Typography>

							{!isLoading && !error && data.responses.map((response, index) => {
								return (
									<Link key={index} underline="none" color="inherit" href={'/attendance-management/' + response.attendance.uuid}>
										<Card sx={{
												borderLeft: '4px solid',
												borderLeftColor: RESPONSE_VALUES[response.response][1]
											}}
										>
											<CardContent
												sx={{
													width: '100%',
													flexGrow: 1,
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
												}}
											>
												<Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
													<Stack>
														<Typography variant="h6" component="h2">
															{response.attendance.name}
														</Typography>
														<Typography variant="body2" sx={{ display: 'flex' }}>
															{new Date(response.attendance.date).toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
														</Typography>
													</Stack>
													<Stack direction="row" gap={0}>
														<Iconify sx={{ position: 'relative', top: 'calc(50% - 15px)' }} icon={RESPONSE_VALUES[response.response][2]} width="30px" color={RESPONSE_VALUES[response.response][1]} />
													</Stack>
												</Stack>
											</CardContent>
										</Card>
									</Link>
								)
							})}
						</Stack>
					</Grid>
					
				</Grid>

			</Container>

		</>
	)
}

export default MemberDetails;