import { Box, FormControl, Link, TextField, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { instance } from "../../api";
import { Navigate } from "react-router";
import { enqueueSnackbar } from "notistack";
import { TITLE } from "../../constant";
import { retrieveErrorMessages } from "../../utils/errorsDisplay";

function ForgotPassword() {
    const [loading, setLoading] = useState(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);

    let email = '';
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        email = event.target.value;
    }

    if (requestSuccessful) return (<Navigate to="/auth/login"/>);

    const handleForgotPassword = async () => {
        setLoading(true);

        instance.post('auth/password-reset', {
            email: email,
        }).then((response) => response.data)
        .then((data) => {
            if (data.status == 'success') {
                setLoading(false);
                enqueueSnackbar('Un lien pour réinitialiser votre mot de passe vous a été envoyé par e-mail.', {
                    variant: 'success',
                });
                setRequestSuccessful(true);
            }
        }).catch((error) => {
            setLoading(false);
            enqueueSnackbar(retrieveErrorMessages(error.response.data.error, error.response.data.message, "Votre mot de passe n\'a pas pu être réinitialisé. Veuillez réessayer."), {
                variant: 'error',
            });
        });
    }

    return (
        <>
            <Helmet>
                <title>Mot de passe oublié { TITLE }</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '32px',
                }}
            >
                <Box
                    sx={{
                        marginBottom: '32px',
                    }}
                >
                    <Link underline="hover" variant="inherit" href="/auth/login"
                        sx={{
                            display: 'inline-flex',
                            margin: 0,
                            alignItems: 'center',
                            color: 'common.black',
                        }}
                    >
                        <ArrowBackIcon sx={{ marginRight: '8px' }} />
                        <Typography>
                            Retour
                        </Typography>
                    </Link>
                </Box>

                <Typography
                    sx={{
                        fontWeight: 700,
                        lineHeight: 1.2,
                        fontSize: '1.4823rem !important',
                    }}
                >
                    Mot de passe oublié
                </Typography>
                <Typography
                    sx={{
                        margin: '8px, 0 0'
                    }}
                >
                    Il semblerait que vous ayez oublié votre mot de passe. Pas de panique, nous allons vous envoyer un lien pour le réinitialiser.
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <FormControl >
                    <TextField 
                        required
                        id="email" 
                        label="Adresse e-mail" 
                        variant="outlined"
                        autoFocus
                        disabled={loading}
                        onChange={handleEmailChange}
                    />

                    <LoadingButton
                        loading={loading}
                        size="large"
                        type="submit"
                        fullWidth
                        variant="contained"
                        onClick={handleForgotPassword}
                        sx={{
                            marginTop: '24px',
                        }}
                    >
                        Envoyer un lien
                    </LoadingButton>    
                </FormControl>
            </Box>
        </>
    )
}

export default ForgotPassword;